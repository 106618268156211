import { ApplicationService, InternalApplicationStart } from "./application";
import { RenderingService } from "./rendering";
import { CoreId } from "../utils/context";
import { EnvironmentMode, PackageInfo } from "../utils/package";
import { ContextService } from "./context/context";
import { ChromeService } from "./chrome";
import { Dispatch } from "redux";
import { CoreSetup, CoreStart } from ".";
import { InternalApplicationSetup } from "./application/types";
import { PluginsService } from "./plugins";
import { DiscoveredPlugin, PluginName } from "../types/plugins";
// import { ApiService } from "./api";
import { PluginsServiceSetup, PluginsServiceStart } from "./plugins/plugins_service";
import { Store } from 'redux';
import ApiClient from "./ApiClient";
import { CoreStore } from "../store";
interface Params {
    rootDomElement?: HTMLElement | null;
    dispatch?: Dispatch;
    plugins?: { id: PluginName, plugin: DiscoveredPlugin }[];
    store: Store;
    api?: any;
    i18n?: any;
}
// Array<{ id: PluginName, plugin: DiscoveredPlugin }>

/** @internal */
export interface CoreContext {
    coreId: CoreId;
    env: {
        mode: Readonly<EnvironmentMode>;
        packageInfo: Readonly<PackageInfo>;
    };
}

/** @internal */
export interface InternalCoreSetup extends Omit<CoreSetup, 'application'> {
    application: InternalApplicationSetup;
    getServiceStart: () => Promise<CoreStart>;
    // injectedMetadata: InjectedMetadataSetup;
}


/** @internal */
export interface InternalCoreStart extends Omit<CoreStart, 'application'> {
    application: InternalApplicationStart;
    // injectedMetadata: InjectedMetadataStart;
}


export class CoreSystem {
    private readonly application: ApplicationService;
    private readonly chrome: ChromeService;
    private readonly rendering: RenderingService;
    private readonly context: ContextService;
    private readonly plugins: PluginsService;
    private pluginsStart?: PluginsServiceStart;
    private pluginsSetup?: PluginsServiceSetup;

    private readonly rootDomElement: HTMLElement;
    private readonly coreContext: CoreContext;
    private coreStart!: CoreStart;
    private api?: any;
    private i18n?: any;
    public coreStore: any;
    // private readonly api: ApiService;

    constructor(params: Params) {
        const {
            rootDomElement,
            dispatch,
            plugins,
            store,
            api,
            i18n
        } = params;
        this.coreStore = CoreStore
        this.rootDomElement = rootDomElement!;
        // const store: any = null;//configureStore();
        this.application = new ApplicationService(store);
        this.chrome = new ChromeService();
        this.rendering = new RenderingService();
        // this.api = new ApiService();

        this.coreContext = {
            coreId: Symbol('core'), env: {
                mode: ({} as EnvironmentMode),
                packageInfo: ({} as PackageInfo),
            }
        };

        this.context = new ContextService(this.coreContext);

        this.plugins = new PluginsService(
            this.coreContext,
            plugins!
        );
        this.api = api
        this.i18n = i18n
        // console.log(this.plugins.getPlugin("security"))
    }


    public async setup() {

        try {
            const context = this.context.setup({
                pluginDependencies: new Map(),
            })
            const application = this.application.setup({ context })
            // const api = await this.api.setup()
            const fetch = async (method: string, url: string, opts?: any, postBody?: any) => {
                opts = opts || {}

                let pathParams = {
                    // 'parent': parent
                };
                let queryParams = {
                    // 'page_size': opts['pageSize'],
                    // 'page_token': opts['pageToken'],
                    // 'query': opts['query'],
                    // 'order_by': opts['orderBy']
                };
                let headerParams = opts.headerParams || {};
                let formParams = opts.formParams || {};
                let contentTypes = opts.contentTypes || ['application/json'];
                let accepts = opts.accepts || ['application/json'];
                let returnType = opts.returnType || {};
                let authNames: string[] = [];
                const client = new ApiClient()
                client.basePath = "http://localhost:82"
                return await client.callApi(
                    url, method,
                    pathParams, queryParams, headerParams, formParams, postBody,
                    authNames, contentTypes, accepts, returnType
                );
            }
            const core: InternalCoreSetup = {
                application,
                context,
                getServiceStart: async () => this.coreStart,
                http: {
                    redirect: (path: string) => {

                        this.application.history.push(path)
                    },
                    get: async (url: string, opts?: any, postBody?: any) => {
                        return fetch("GET", url, opts, postBody)
                    },
                    post: async (url: string, opts?: any, postBody?: any) => {
                        return fetch("POST", url, opts, postBody)
                    },
                },
                api: this.api,
                i18n: this.i18n,
                coreStore: this.coreStore,

            }
            this.pluginsSetup = await this.plugins.setup(core);
            this.rendering.setup({
                application,
                plugins: this.pluginsSetup,
                // api,
            })
            return { fatalErrors: null };
        } catch (error) {
            console.error(error)
        }
        // const context = null;

    }


    public async start() {
        try {
            const coreUiTargetDomElement = document.createElement('div');
            coreUiTargetDomElement.id = 'dr2am-body';
            coreUiTargetDomElement.classList.add('height-full');
            coreUiTargetDomElement.classList.add('d-flex');

            // ensure the rootDomElement is empty
            this.rootDomElement.textContent = '';
            this.rootDomElement.classList.add('coreSystemRootDomElement');
            this.rootDomElement.classList.add('height-full');
            // this.rootDomElement.appendChild(coreUiTargetDomElement);
            const ct = this.rootDomElement as HTMLDivElement
            const application = await this.application.start()

            const chrome = await this.chrome.start({
                dispatch: application.getStore().dispatch,
                store: application.getStore(),
                history: application.getHistory(),
            });

            const core: InternalCoreStart = {
                application,
                chrome,
            };
            this.pluginsStart = await this.plugins.start(core);
            this.coreStart = core
            this.rendering.start({
                targetDomElement: ct,// coreUiTargetDomElement,
                application: application,
                chrome: chrome,
                // api: this.api,
            })

        } catch (err) {
            console.error(err);
        }


        // return this.rootDomElement
    }

    public stop() {
    }
}