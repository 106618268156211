/*
 * types/services/dr2am.tasks.orders.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * Enum class ResponseStateState.
 * @enum {String}
 * @readonly
 */
const ResponseStateState = {
  /**
   * value: "unknow"
   * @const
   */
  unknow: "unknow",

  /**
   * value: "success"
   * @const
   */
  success: "success",

  /**
   * value: "fail"
   * @const
   */
  fail: "fail",

  /**
   * Returns a <code>ResponseStateState</code> enum value from a JavaScript object name.
   * @param {Object} data The plain JavaScript object containing the name of the enum value.
   * @return {module:model/ResponseStateState} The enum <code>ResponseStateState</code> value.
   */
  constructFromObject: function(object) {
    return object;
  }
};

export {ResponseStateState};
