/*
 * types/services/dr2am.tasks.preferences.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbPreference} from './PbPreference';
import {PbResponseState} from './PbResponseState';

/**
 * The ServiceListPreferencesResponse model module.
 * @module model/ServiceListPreferencesResponse
 * @version version not set
 */
export class ServiceListPreferencesResponse {
  /**
   * Constructs a new <code>ServiceListPreferencesResponse</code>.
   * @alias module:model/ServiceListPreferencesResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceListPreferencesResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceListPreferencesResponse} obj Optional instance to populate.
   * @return {module:model/ServiceListPreferencesResponse} The populated <code>ServiceListPreferencesResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceListPreferencesResponse();
      if (data.hasOwnProperty('preferences'))
        obj.preferences = ApiClient.convertToType(data['preferences'], [PbPreference]);
      if (data.hasOwnProperty('next_page_token'))
        obj.nextPageToken = ApiClient.convertToType(data['next_page_token'], 'String');
      if (data.hasOwnProperty('total'))
        obj.total = ApiClient.convertToType(data['total'], 'String');
      if (data.hasOwnProperty('state'))
        obj.state = PbResponseState.constructFromObject(data['state']);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/PbPreference>} preferences
 */
ServiceListPreferencesResponse.prototype.preferences = undefined;

/**
 * @member {String} nextPageToken
 */
ServiceListPreferencesResponse.prototype.nextPageToken = undefined;

/**
 * @member {String} total
 */
ServiceListPreferencesResponse.prototype.total = undefined;

/**
 * @member {module:model/PbResponseState} state
 */
ServiceListPreferencesResponse.prototype.state = undefined;


