/*
 * types/services/dr2am.locales.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbResponseState} from './PbResponseState';
import {ServiceLocaleNameSpace} from './ServiceLocaleNameSpace';

/**
 * The ServiceListLocaleNameSpacesResponse model module.
 * @module model/ServiceListLocaleNameSpacesResponse
 * @version version not set
 */
export class ServiceListLocaleNameSpacesResponse {
  /**
   * Constructs a new <code>ServiceListLocaleNameSpacesResponse</code>.
   * @alias module:model/ServiceListLocaleNameSpacesResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceListLocaleNameSpacesResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceListLocaleNameSpacesResponse} obj Optional instance to populate.
   * @return {module:model/ServiceListLocaleNameSpacesResponse} The populated <code>ServiceListLocaleNameSpacesResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceListLocaleNameSpacesResponse();
      if (data.hasOwnProperty('data'))
        obj.data = ApiClient.convertToType(data['data'], [ServiceLocaleNameSpace]);
      if (data.hasOwnProperty('state'))
        obj.state = PbResponseState.constructFromObject(data['state']);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/ServiceLocaleNameSpace>} data
 */
ServiceListLocaleNameSpacesResponse.prototype.data = undefined;

/**
 * @member {module:model/PbResponseState} state
 */
ServiceListLocaleNameSpacesResponse.prototype.state = undefined;


