import { KnowledgeStore } from './store';

class KnowledgePlugin {
    constructor() {
    }

    setup = ({
        ...core
    }, {
        ...other
    }) => {
        const { security } = other
        core.application.register({
            id: 'knowledge',
            title: '知识',
            mount: async (context: any, params: any) => {
                const { renderApp } = await import('./application');
                KnowledgeStore.i18n = core.i18n
                const wfConfig = core.coreStore.get("wfConfig")
                if (wfConfig) {
                    KnowledgeStore.update("wfConfig", wfConfig)
                }
                return renderApp(context, {
                    ...params,
                    i18n: core.i18n,
                    authc: security.authc,
                    knowledgeStore: KnowledgeStore,
                    wfConfig: wfConfig,
                    coreStore: core.coreStore,
                });
            }
        });
        return this
    }
    start = (core: any) => {
        const { chrome } = core
        chrome.addNavs("knowledge", {
            display: "知识",
            href: "/app/knowledge",
            icon: (<img src="/img/sidebar/knowledge.png"></img>)
        })
    }
    stop = () => {

    }
}

const registerPlugin = (window: any) => {
    const Plant = () => new KnowledgePlugin()
    window.registerPlugin("knowledge", {
        id: "knowledge",
        plugin: {
            id: "knowledge",
            instance: Plant,
            requiredPlugins: ["security"]
        }
    })
}

const knowledge = {
    registerPlugin: registerPlugin
}
// export const searchAPI = `http://localhost:82`
// export const searchAPI = `http://${window.location.host}`
export const searchAPI = localStorage.getItem("wfhk_analysis_api") || `${window.location.origin}/test/api/v1`
// export const searchAPI = `http://127.0.1:82/test/api/v1`
// export const searchAPI = `http://videos.wanfangdata.com.hk:82/test/api/v1`

export default knowledge