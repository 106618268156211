import { useState } from "react";
import styled from "styled-components";
import { Portal } from "./portal/portal";


const DetailDialog = styled.div<any>`
position: fixed;
margin: 10vh auto;
top: 0;
left: 50%;
transform: translateX(-50%);
z-index: 999;
max-height: 80vh;
max-width: 90vw;
width: 448px;
overflow: auto;
${({ fullscreen }) => fullscreen && `
    width:100%;
    height:100%;
    max-height: unset;
    max-width: unset;
    margin: auto;
`}
`


const OverlayBackdroCenter = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    background-color: var(--color-neutral-muted);
    align-items: center;
    justify-content: center;
`
export function Details({ fullscreen, trigger, children, open, onClose, onOpen, ...props }: any) {
    const [isOpen, setIsOpen] = useState(open);
    // if (!trigger) {
    //     return <>not defined trigger</>;
    // }
    function close() {
        setIsOpen(false);
        onClose && onClose()
    }
    function toggle(e: any) {
        e.preventDefault();
        if (isOpen) {
            close()
        } else {
            setIsOpen(!isOpen);
            onOpen && onOpen()
        }
    }
    return <Portal>
        <OverlayBackdroCenter>
            <DetailDialog fullscreen={fullscreen} className="Box Box--overlay d-flex flex-column anim-fade-in fast">
                {children({ close })}
            </DetailDialog>
        </OverlayBackdroCenter>
    </Portal>
}
