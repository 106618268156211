import { SideBarSection } from '../../components/sidebar/section';


class Plugin {
    constructor() {
    }
    private actions: any
    setup = ({
        ...core
    }, {
        action_creaetCollection,
        ...other
    }: any) => {
        this.actions = { action_creaetCollection }
        const { api } = core
        core.application.register({
            id: 'locales',
            title: 'locales',
            mount: async (context: any, params: any) => {
                const {
                    renderApp
                } = await import('./application');
                return renderApp(context, {
                    ...params,
                    api,
                    actions: this.actions,
                    authc: other.security.authc,
                });
            }
        });
        return this
    }
    start = (core: any) => {
        const { chrome } = core
        const openCreateCollectionModal = () => {
            this.actions.action_creaetCollection.action()
            // chrome.setGlobalModal(<a>aaaa</a>)
        }
        const createCollection = new SideBarSection("collection_create", {
            display: "新建数据集",
            icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"></path></svg>,
            onClick: openCreateCollectionModal
        })
        const CollectionList = new SideBarSection("collection_list", {
            display: "列表",
            icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M2.003 2.5a.5.5 0 00-.723-.447l-1.003.5a.5.5 0 00.446.895l.28-.14V6H.5a.5.5 0 000 1h2.006a.5.5 0 100-1h-.503V2.5zM5 3.25a.75.75 0 01.75-.75h8.5a.75.75 0 010 1.5h-8.5A.75.75 0 015 3.25zm0 5a.75.75 0 01.75-.75h8.5a.75.75 0 010 1.5h-8.5A.75.75 0 015 8.25zm0 5a.75.75 0 01.75-.75h8.5a.75.75 0 010 1.5h-8.5a.75.75 0 01-.75-.75zM.924 10.32l.003-.004a.851.851 0 01.144-.153A.66.66 0 011.5 10c.195 0 .306.068.374.146a.57.57 0 01.128.376c0 .453-.269.682-.8 1.078l-.035.025C.692 11.98 0 12.495 0 13.5a.5.5 0 00.5.5h2.003a.5.5 0 000-1H1.146c.132-.197.351-.372.654-.597l.047-.035c.47-.35 1.156-.858 1.156-1.845 0-.365-.118-.744-.377-1.038-.268-.303-.658-.484-1.126-.484-.48 0-.84.202-1.068.392a1.858 1.858 0 00-.348.384l-.007.011-.002.004-.001.002-.001.001a.5.5 0 00.851.525zM.5 10.055l-.427-.26.427.26z"></path></svg>,
            href: "/app/collection"
        })
        // const collection = new SideBarSection()
        chrome.addNavs("collection", {
            display: "数据集",
            icon: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M8.156 1.835a.25.25 0 00-.312 0l-5.25 4.2a.25.25 0 00-.094.196v7.019c0 .138.112.25.25.25H5.5V8.25a.75.75 0 01.75-.75h3.5a.75.75 0 01.75.75v5.25h2.75a.25.25 0 00.25-.25V6.23a.25.25 0 00-.094-.195l-5.25-4.2zM6.906.664a1.75 1.75 0 012.187 0l5.25 4.2c.415.332.657.835.657 1.367v7.019A1.75 1.75 0 0113.25 15h-3.5a.75.75 0 01-.75-.75V9H7v5.25a.75.75 0 01-.75.75h-3.5A1.75 1.75 0 011 13.25V6.23c0-.531.242-1.034.657-1.366l5.25-4.2h-.001z"></path></svg>),
            items: [CollectionList, createCollection]
        })

    }
    stop = () => {

    }

}



const registerPlugin = (window: any) => {
    const collection = () => new Plugin();
    window.registerPlugin("locales", {
        id: "locales",
        plugin: {
            id: "locales",
            configPath: "",
            instance: collection,
            requiredPlugins: ["security", "action_creaetCollection"]
        },
    })
}

// export const Home = () => new Plugin();
const collection = {
    registerPlugin: registerPlugin
}
export default collection