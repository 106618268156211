
export class EventEmitter {
    [x: string]: any;


    constructor() {
    }
    on(name: string | number, callback: any) {
        const onid = generateId()
        callback = { id: onid, callback }
        var callbacks = this[name];
        if (!callbacks) {
            this[name] = [callback];
        }
        else {
            callbacks.push(callback);
        }
        return onid
    }
    kill(name: string | number, id: string) {
        const callbacks = this[name]
        this[name] = []
        if (!callbacks) {
            return
        }
        for (let i = 0; i < callbacks.length; i++) {
            const element = callbacks[i];
            if (element.id == id) {
                continue
            }
            this[name].push(element)
        }
    }
    dispatch(name: string | number, event: any) {
        var callbacks = this[name];
        if (callbacks)
            callbacks.forEach(({ callback }: any) => callback(event));
    }
}

function generateId(): string {
    // implementation taken from http://stackoverflow.com/a/2117523
    let id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    id = id.replace(/[xy]/g, (c) => {
        const r = Math.floor(Math.random() * 16);
        let v;

        if (c === 'x') {
            v = r;
        } else {
            // eslint-disable-next-line no-mixed-operators
            v = r & 0x3 | 0x8;
        }

        return v.toString(16);
    });
    return id;
}