/*
 * types/services/dr2am.tasks.permissions.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbPermissionRole} from './PbPermissionRole';

/**
 * The ServiceCreatePermissionsRequest model module.
 * @module model/ServiceCreatePermissionsRequest
 * @version version not set
 */
export class ServiceCreatePermissionsRequest {
  /**
   * Constructs a new <code>ServiceCreatePermissionsRequest</code>.
   * @alias module:model/ServiceCreatePermissionsRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceCreatePermissionsRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceCreatePermissionsRequest} obj Optional instance to populate.
   * @return {module:model/ServiceCreatePermissionsRequest} The populated <code>ServiceCreatePermissionsRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceCreatePermissionsRequest();
      if (data.hasOwnProperty('permission'))
        obj.permission = PbPermissionRole.constructFromObject(data['permission']);
    }
    return obj;
  }
}

/**
 * @member {module:model/PbPermissionRole} permission
 */
ServiceCreatePermissionsRequest.prototype.permission = undefined;


