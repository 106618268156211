import ClientOAuth2 from 'client-oauth2';
import { generateId as uuid } from './utils';
// import uuid from 'uuid';
export const AUTHORIZED_USER = 'authorized_user'
export const AUTHORIZE_STATE = 'authorize_state'
export const AUTHORIZE_REDIRECT = 'authorize_redirect'
export const AUTHORIZE_NONCE = 'authorize_nonce'
export const AUTHORIZE_SESSION_ID = 'authorize_session_id'
type Configuration = {
  clientId: string,
  clientSecret: string,
  accessTokenUri: string,
  authorizationUri: string,
  redirectUri: string,
  scopes?: string[]
}

export class Authentication {
  store!: Storage;
  refreshProcess?: any;
  tokenClient: ClientOAuth2
  isAuthorized = () => Boolean(this.store.getItem(AUTHORIZED_USER) || false)
  authorizeClient: ClientOAuth2

  constructor(
    options?: Configuration,
    store?: Storage
  ) {
    if (!options) {
      options = {
        clientId: '',
        clientSecret: '',
        accessTokenUri: '',
        authorizationUri: '',
        redirectUri: '',
        scopes: []
      }
    }
    if (store) {
      this.store = store
    } else if (typeof localStorage !== 'undefined') {
      this.store = localStorage
    }
    const mergedOptions = {
      clientId: options.clientId,
      clientSecret: options.clientSecret,//'is-public', // workaround for https://github.com/ory/fosite/issues/217
      accessTokenUri: options.accessTokenUri,
      authorizationUri: options.authorizationUri,
      redirectUri: options.redirectUri,
      scopes: options.scopes || ['offline', 'openid', 'force-consent'],
      type: ["token", "id_token"]
    }

    this.authorizeClient = new ClientOAuth2(mergedOptions)
    this.tokenClient = new ClientOAuth2({
      ...mergedOptions,
      clientId: encodeURIComponent(mergedOptions.clientId)
    })
  }

  revoke = async () => {
    this.store.removeItem(AUTHORIZE_NONCE)
    const state = this.store.getItem(AUTHORIZE_STATE)
    if (state) {
      this.store.removeItem(state)
    }
    this.store.removeItem(AUTHORIZE_STATE)
    this.store.removeItem(AUTHORIZED_USER)
    return Promise.resolve()
  }

  authorizeCode = () => {
    const state = uuid()
    const nonce = uuid()
    this.store.setItem(AUTHORIZE_STATE, state)
    this.store.setItem(AUTHORIZE_NONCE, nonce)
    const url = this.authorizeClient.code.getUri({
      state,
      // nonce
    })

    window.location.href = url
  }

  authorizeToken = () => {
    const state = uuid()
    const nonce = uuid()
    this.store.setItem(AUTHORIZE_STATE, state)
    this.store.setItem(AUTHORIZE_NONCE, nonce)
    const url = this.authorizeClient.token.getUri({
      state,
    })
    window.location.href = url
  }

  authorizeWithTenant = () => {
    const state = uuid()
    const nonce = uuid()
    this.store.setItem(AUTHORIZE_STATE, state)
    this.store.setItem(AUTHORIZE_NONCE, nonce)
    // this.authorizeClient.jwt.getToken
    // const url = this.authorizeClient.code.getUri({
    //   state,
    // })
    return this.tokenClient.credentials.getToken().then(
      token => {
        this.store.removeItem(AUTHORIZE_NONCE)
        this.store.removeItem(AUTHORIZE_STATE)
        return this.storeToken(token)
      }
    )
    // window.location.href = url
  }
  /**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

  post = (path: string, params: any, method = 'post') => {

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  }
  authorizePlugin = async (username: string, password: string, opts: any = { redirect: window.location.href }) => {
    await this.revoke()
    return this.post("/auth/login?returnurl=" + opts.redirect, {
      "user[login]": username,
      "user[password]": password,
      "user[read_me]": "1",
      "utf8": "%E2%9C%93",
      "recaptcha_token": opts.recaptcha,
      "authenticity_token": uuid(),

    })

    // return fetch("/auth/login", {
    //   "headers": {
    //     "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
    //     "accept-language": "zh-CN,zh;q=0.9,en;q=0.8",
    //     "cache-control": "max-age=0",
    //     "content-type": "application/x-www-form-urlencoded",
    //     "upgrade-insecure-requests": "1"
    //   },
    //   "referrerPolicy": "origin-when-cross-origin",
    //   "body": `utf8=%E2%9C%93&authenticity_token=qnvrHX2UPpLsLPbC9pr1irvAPfXxjTs8UwVA9EPOnrgqWv98CziIc7CVlWlJUYLqOaKwSIHX4BwIMT%2FPUp0L1Q%3D%3D&user%5Blogin%5D=${username}&user%5Bpassword%5D=${password}&user%5Bread_me%5D=1&commit=%E4%BA%86%E8%A7%A3%E5%B9%B6%E7%99%BB%E5%BD%95&returnrul=${redirect}`,
    //   "method": "POST",
    //   "mode": "cors",
    //   "credentials": "include",
    //   "redirect": 'manual'
    // })
  }
  authorizePassword = (username: string, password: string) => {

    const state = uuid()
    const nonce = uuid()
    this.store.setItem(AUTHORIZE_STATE, state)
    this.store.setItem(AUTHORIZE_NONCE, nonce)
    return this.authorizeClient.owner.getToken(username, password).then(
      token => {
        this.store.removeItem(AUTHORIZE_NONCE)
        this.store.removeItem(AUTHORIZE_STATE)
        return this.storeToken(token)
      }
    )
    //     ////log.info('Initializing OAuth2 authorize code flow at', url)
    // // 
    //     // window.location.href = url
  }

  storeToken = (token: ClientOAuth2.Token): Promise<ClientOAuth2.Token> => {
    const payload = {
      ...token,
      data: {
        ...token.data,
        expires: token.expired()
      },
      expiresIn: undefined,
      client: null
    }
    this.store.setItem(AUTHORIZED_USER, JSON.stringify(payload))

    this.refreshProcess = null
    return Promise.resolve(token)
  }

  authorizationCallback = (url: string = window.location.href) => {
    let state = this.store.getItem(AUTHORIZE_STATE) || ""
    return this.tokenClient.token
      .getToken(url, { state: state })
      .then(token => {
        this.store.removeItem(AUTHORIZE_NONCE)
        this.store.removeItem(AUTHORIZE_STATE)
        return this.storeToken(token)
      })
  }
  authorizationCallback2 = (url: string = window.location.href) => {
    const state = this.store.getItem(AUTHORIZE_STATE)

    return this.tokenClient.code
      .getToken(url, { state: state || undefined })
      .then(token => {
        this.store.removeItem(AUTHORIZE_NONCE)
        this.store.removeItem(AUTHORIZE_STATE)
        return this.storeToken(token)
      })
  }
  authorizationCallbackImplict = (url: string = window.location.href) => {
    const state = this.store.getItem(AUTHORIZE_STATE)
    // this.tokenClient.token.getToken()
    return this.tokenClient.token
      .getToken(url, { state: state || undefined })
      .then(token => {
        this.store.removeItem(AUTHORIZE_NONCE)
        this.store.removeItem(AUTHORIZE_STATE)
        return this.storeToken(token)
      })
  }

  sessionId = () => {
    const id = this.store.getItem(AUTHORIZE_SESSION_ID) || uuid()
    this.store.setItem(AUTHORIZE_SESSION_ID, id)
    return id
  }

  //   authorizedUserId = () => {
  //     let user

  //     if (!this.store.getItem(AUTHORIZED_USER)) {
  //       const id = this.sessionId()
  //       //log.info('Detected unauthorized user, assigning session id', id)
  //       return id
  //     }

  //     try {
  //       user = JSON.parse(this.store.getItem(AUTHORIZED_USER) || '')
  //     } catch (e) {
  //       //log.info(`Unable to parse user information because: ${e.message}`)
  //       return this.sessionId()
  //     }

  //     if (user.data.forceUser) {
  //       return user.data.forceUser
  //     }

  //     if (!user.data.id_token) {
  //       //log.error('Unable to look up user because id_token is missing.')
  //       return this.sessionId()
  //     }

  //     const parts = user.data.id_token.split('.')
  //     if (parts.length !== 3) {
  //       //log.error('Unable to look up user id because id_token is invalid.')
  //       return this.sessionId()
  //     }

  //     try {
  //       return JSON.parse(Buffer.from(parts[1], 'base64').toString()).sub
  //     } catch (e) {
  //       //log.exception(new Error(`Unable to parse id_token because ${e.message}`))
  //       return this.sessionId()
  //     }
  //   }
  removeAuthorizedUser = () => {
    this.store.removeItem(AUTHORIZED_USER)
  }

  authorizedUser = () => {
    let user
    if (!this.store.getItem(AUTHORIZED_USER)) {
      const id = this.sessionId()
      //log.info('Detected unauthorized user, assigning session id', id)
      return id
    }

    try {

      user = JSON.parse(this.store.getItem(AUTHORIZED_USER) || 'null')
    } catch (e) {
      //log.info(`Unable to parse user information because: ${e.message}`)
      return this.sessionId()
    }

    if (user.data.forceUser) {
      return user.data.forceUser
    }

    if (!user.data.id_token) {
      //log.error('Unable to look up user because id_token is missing.')
      return this.sessionId()
    }

    const parts = user.data.id_token.split('.')
    if (parts.length !== 3) {
      //log.error('Unable to look up user id because id_token is invalid.')
      return this.sessionId()
    }

    try {
      return JSON.parse(Buffer.from(parts[1], 'base64').toString())
    } catch (e) {
      //log.exception(new Error(`Unable to parse id_token because ${e.message}`))
      return this.sessionId()
    }
  }

  token = (): Promise<ClientOAuth2.Token> => {
    let user
    try {
      // user = {"client":null,"data":{"access_token":"ZBFS042LNNWVH4JIQHEKMW","refresh_token":"D7MDWQ3MUIM5DIOIP0ULQA","expires_in":"7200","id_token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm9maWxlIjp7IkNsaWVudElEIjoiNDgyOTlhNjEtNDcyYy00MzZmLWI2YTItMGJlODI2YjUwZDJlIiwiSG9zdE5hbWUiOiIiLCJJc0F1dGhlZCI6dHJ1ZSwiSXNWYWxpZCI6dHJ1ZSwiTGlicmFyeUdyb3VwIjoiIiwiTmFtZSI6InlvcmhlX2NoYW4iLCJSb2xlcyI6InN5c3RlbV9hZG1pbiIsIlRlbmFudElkcyI6IiIsIlVzZXJOYW1lIjoieW9yaGVfY2hhbkBob3RtYWlsLmNvbSJ9fQ.rrea3-IsbfKyduzWot1o1k6LlHVhpWOWBR2kqNaC3SU","scope":"offline openid","token_type":"Bearer","expires":"2019-11-19T13:46:14.198Z"},"tokenType":"bearer","accessToken":"ZBFS042LNNWVH4JIQHEKMW","refreshToken":"D7MDWQ3MUIM5DIOIP0ULQA","expires":"2019-11-19T13:46:14.198Z"}
      user = JSON.parse(this.store.getItem(AUTHORIZED_USER) || 'null')
    } catch (e) {
      console.error(e)
      //log.exception(
      // `Unable to fetch and parse user information from localStore because ${
      //   e.message
      // }`
      // )
      return this.revoke().then(() => Promise.reject(e))
    }
    // if (!user || !user.accessToken || !user.refreshToken) {
    if (!user || !user.accessToken) {

      const err = new Error(
        'Unable to retrieve authentication information from localStore.'
      )
      //log.exception(err)
      return this.revoke().then(() => Promise.reject(err))
      // return Promise.resolve(undefined)
    }

    const token = this.tokenClient.createToken(
      user.accessToken,
      user.refreshToken,
      user.tokenType,
      user.data
    )
    token.expiresIn(new Date(user.expires))

    if (token.expired()) {
      if (!this.refreshProcess) {
        //log.info('Token is already refreshing, returning existing promise.')
        this.refreshProcess = token
          .refresh()
          .then(this.storeToken)
          .catch(err => {
            return this.revoke().then(() => Promise.reject(err))
          })
      }

      return this.refreshProcess
    }

    return Promise.resolve(token)
  }
}
