import _ from "lodash";

var rules = [];
const check = (rules, role, action, data) => {
    const permissions = rules[role];
    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true;
    }

    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
            // dynamic rule not provided for action
            return false;
        }

        return permissionCondition(data);
    }
    return false;
};

var polics = [{
    resource: /\/console\/user\/.*$/g,
    action: /.*$/g,
    effect: "allow"
}];

export const DefaultAdminPolicy = {
    allow: [{
        actions: [".*"],
        description: "所有",
        effect: "allow",
        group: "system_admin",
        resources: [".*"],

    }],
    // deny: [{
    //     actions: ["view"],
    //     description: "测试所有",
    //     effect: "deny",
    //     group: "system_admin",
    //     resources: [
    //         "/app/dr2am/tenant/[^/]+/analysis/resource.*",
    //         "/app/dr2am/tenant/[^/]+/setting.*",
    //         "/app/dr2am/tenant/[^/]+/permission.*",
    //         "/app/dr2am/tenant/[^/]+/groups.*",
    //         "/app/dr2am/tenant/[^/]+/dashborad.*",
    //     ],
    // }]
};

export const Policy = (polics) => {
    return {
        ...polics,
        match: (resource, action) => {

            if (!polics) {
                return false
            }
            if (polics.deny) {
                for (let i = 0; i < polics.deny.length; i++) {
                    const policy = polics.deny[i];
                    // console.log(policy, resource, action)

                    if (match(policy.resources, resource) &&
                        match(policy.actions, action)) {
                        console.log(`${policy.group} -> deny -> ${policy.description} `);
                        return false
                    }

                }
            }
            if (polics.allow) {
                for (let i = 0; i < polics.allow.length; i++) {
                    const policy = polics.allow[i];
                    if (match(policy.resources, resource) &&
                        match(policy.actions, action)) {
                        // console.log("allow -> " + policy.group);
                        return true
                    }
                }
            }
            console.log(`deny -> not match any policy ${resource} ${action}`);
            return false
        }
    }
}

function match(polics, pattran) {
    if (!polics) {
        return true
    }
    if (_.isString(polics)) {
        polics = _.split(polics, ";")
    }
    let result = false
    for (let i = 0; i < polics.length; i++) {
        let policy = polics[i];
        policy = policy.replace("**", ".*")
        if ((new RegExp("^" + policy + "$")).test(pattran)) {
            return true
        }
    }
    return false
}

const hydraCheck = (rules, role, resource, action, data) => {
    return match(polics, resource, action)
    const permissions = rules[role];
    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true;
    }

    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
            return false;
        }

        return permissionCondition(data);
    }
    return false;
};

// Can({group,resource,action})=>void
const Can = props => {
    const allow = Policy(props.group, props.data).match(props.resource, props.action)
    if (allow) {
        return props.yes && props.yes() || allow
    } else {
        return props.no && props.no() || allow
    }
    return allow
}

Can.defaultProps = {
    yes: () => true,
    no: () => false
};

export default Can;