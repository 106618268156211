/*
 * types/services/dr2am.locales.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The ServiceLocale model module.
 * @module model/ServiceLocale
 * @version version not set
 */
export class ServiceLocale {
  /**
   * Constructs a new <code>ServiceLocale</code>.
   * @alias module:model/ServiceLocale
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceLocale</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceLocale} obj Optional instance to populate.
   * @return {module:model/ServiceLocale} The populated <code>ServiceLocale</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceLocale();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('key'))
        obj.key = ApiClient.convertToType(data['key'], 'String');
      if (data.hasOwnProperty('value'))
        obj.value = ApiClient.convertToType(data['value'], 'String');
      if (data.hasOwnProperty('lang'))
        obj.lang = ApiClient.convertToType(data['lang'], 'String');
      if (data.hasOwnProperty('name_spaces'))
        obj.nameSpaces = ApiClient.convertToType(data['name_spaces'], 'String');
      if (data.hasOwnProperty('languages'))
        obj.languages = ApiClient.convertToType(data['languages'], {'String': 'String'});
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ServiceLocale.prototype.id = undefined;

/**
 * @member {String} key
 */
ServiceLocale.prototype.key = undefined;

/**
 * @member {String} value
 */
ServiceLocale.prototype.value = undefined;

/**
 * @member {String} lang
 */
ServiceLocale.prototype.lang = undefined;

/**
 * @member {String} nameSpaces
 */
ServiceLocale.prototype.nameSpaces = undefined;

/**
 * @member {Object.<String, String>} languages
 */
ServiceLocale.prototype.languages = undefined;


