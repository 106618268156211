/*
 * types/services/dr2am.tasks.orders.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The PbOrder model module.
 * @module model/PbOrder
 * @version version not set
 */
export class PbOrder {
  /**
   * Constructs a new <code>PbOrder</code>.
   * @alias module:model/PbOrder
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PbOrder</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PbOrder} obj Optional instance to populate.
   * @return {module:model/PbOrder} The populated <code>PbOrder</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PbOrder();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('gid'))
        obj.gid = ApiClient.convertToType(data['gid'], 'String');
      if (data.hasOwnProperty('user_id'))
        obj.userId = ApiClient.convertToType(data['user_id'], 'String');
      if (data.hasOwnProperty('user_gid'))
        obj.userGid = ApiClient.convertToType(data['user_gid'], 'String');
      if (data.hasOwnProperty('order_type'))
        obj.orderType = ApiClient.convertToType(data['order_type'], 'String');
      if (data.hasOwnProperty('order_value'))
        obj.orderValue = ApiClient.convertToType(data['order_value'], 'String');
      if (data.hasOwnProperty('limit'))
        obj.limit = ApiClient.convertToType(data['limit'], {'String': Object});
      if (data.hasOwnProperty('create_at'))
        obj.createAt = ApiClient.convertToType(data['create_at'], 'Date');
      if (data.hasOwnProperty('modify_at'))
        obj.modifyAt = ApiClient.convertToType(data['modify_at'], 'Date');
      if (data.hasOwnProperty('expire_at'))
        obj.expireAt = ApiClient.convertToType(data['expire_at'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
PbOrder.prototype.id = undefined;

/**
 * @member {String} gid
 */
PbOrder.prototype.gid = undefined;

/**
 * @member {String} userId
 */
PbOrder.prototype.userId = undefined;

/**
 * @member {String} userGid
 */
PbOrder.prototype.userGid = undefined;

/**
 * @member {String} orderType
 */
PbOrder.prototype.orderType = undefined;

/**
 * @member {String} orderValue
 */
PbOrder.prototype.orderValue = undefined;

/**
 * @member {Object.<String, Object>} limit
 */
PbOrder.prototype.limit = undefined;

/**
 * @member {Date} createAt
 */
PbOrder.prototype.createAt = undefined;

/**
 * @member {Date} modifyAt
 */
PbOrder.prototype.modifyAt = undefined;

/**
 * @member {Date} expireAt
 */
PbOrder.prototype.expireAt = undefined;


