import { data } from "browserslist";
import { head } from "lodash";
import { stringify } from "query-string";
import { useRef } from "react";
import { ApiClient } from "./client/ApiClient";
import { OrdersServiceApi } from "./client/dr2am.tasks.orders-js/src";
import { PreferencesServiceApi } from "./client/dr2am.tasks.preferences-js/src";
import { AUTHORIZED_USER } from "./oauth";


var token: any
function getHeaders() {
    const headers = new Headers({ 'content-type': 'application/json' });
    token = token || localStorage.getItem("wf_oversea_token")
    if (token && token != "") {
        headers.set("Authorization", "token " + token);
    }
    return headers;
}
export const wfConfig = {
    // apiURL: new URL("https://oversea.wanfangdata.com.cn/api/v1"),
    apiURL: new URL("https://globe.wanfangdata.com.cn/api/v1"),
}

export class Endpoint {
    private apiURL: string
    private defaultPath: string
    constructor({ apiURL, defaultPath }: any) {
        // this.apiURL = apiURL || "https://accounts.dr2am.cn/api/v1"
        // this.apiURL = apiURL || "https://globe.wanfangdata.com.cn/api/v1"
        this.apiURL = apiURL || wfConfig.apiURL.href
        this.defaultPath = defaultPath || ""
    }

    public getEndpoint(path: string = "") {
        return this.getEndpointWithId("", path || "")
    }
    public getEndpointWithId(id: string = "", path: string = "") {
        const apiUrl = this.apiURL;
        let endpoint = (apiUrl + this.defaultPath)
        if (id != "") {
            endpoint = endpoint + `/${id}`
        }
        if (path != "") {
            endpoint = endpoint + `/${path}`
        }

        return endpoint
    }
}
function handleError(res: any) {
    if (res && res.status_code) {
        return { error: res, data: null }
    }
    return { data: res }
}

export function useOAuth(auth: any, APIURL?: string) {
    const endpoint = new Endpoint({ apiURL: APIURL, defaultPath: "/v1/oauth/apps" })

    async function tokenFetch(url: string, request: any) {
        return auth.token().then(async (tt: any) => {
            const { accessToken, tokenType } = tt || {}
            // headers = new Headers()
            request.headers = request.headers || new Headers();
            if (accessToken) {
                const token = accessToken;//|| localStorage.getItem("wf_oversea_token")
                if (token && token != "") {
                    request.headers.set("Authorization", `${tokenType} ${token}`);
                }
            }

            return fetch(url, request)
        })
    }
    async function createOAuthApp(app: any) {
        const url = endpoint.getEndpoint("")
        try {
            const res: any = await tokenFetch(url, {
                // mode: 'cors',
                method: 'POST',
                body: JSON.stringify(app),
                credentials: 'include',
                // headers: getHeaders(),
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    async function updateOAuthApp(app: any) {
        const url = endpoint.getEndpointWithId(app.id, "update")
        try {
            const res: any = await tokenFetch(url, {
                // mode: 'cors',
                method: 'POST',
                body: JSON.stringify(app),
                credentials: 'include',
                // headers: getHeaders(),
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    async function deleteOAuthApp(id: string) {
        const url = endpoint.getEndpointWithId(id, "delete")
        try {
            const res: any = await tokenFetch(url, {
                method: 'GET',
                credentials: 'include',

            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    async function getOAuthApps() {
        const url = endpoint.getEndpoint("")
        try {
            const res: any = await tokenFetch(url, {
                // mode: 'cors',
                method: 'GET',
                credentials: 'include',
                headers: getHeaders(),
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    return { createOAuthApp, getOAuthApps, deleteOAuthApp, updateOAuthApp }
}

export function useUser(apiUrl?: string) {
    const token = useRef()
    // const [results, setResults] = useState<any>()
    const endpoint = new Endpoint({ defaultPath: "/users" })

    async function createUser(user: any, token: any, inviteId: any, redirectTo: any) {
        const endpoint = apiUrl + "/users"
        try {
            const res: any = await fetch(endpoint + "?" + `r=${redirectTo}`, {
                method: 'POST',
                body: JSON.stringify(user),
                credentials: 'include',
            })
            const data = await res.json()
            return { data }

        } catch (error) {
            return { error }

        }
    }
    async function loginById(id: any, password: any, redirectTo: any) {
        const endpoint = apiUrl + "/users/login"
        try {
            const res: any = await fetch(endpoint + "?" + "format=old", {
                method: 'POST',
                body: JSON.stringify({
                    id: id,
                    password: password
                }),
                credentials: 'include'
            })
            if (res.headers && res.headers.get("Token")) {
                token.current = res.headers.get("Token")
                if (token.current) {
                    localStorage.setItem("nada_token", token.current)
                }
            }
            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }
    }
    async function loginForToken(_token: string) {

        // token = _token
        localStorage.setItem("nada_token", _token)
        const { data, error } = await getMe()

        return { data, error }
    }
    async function login(login_id: any, password: any, redirectTo: any) {
        const url = endpoint.getEndpoint("login")
        try {
            const res: any = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    login_id: login_id,
                    password: password
                }),
                credentials: 'include'
            })
            if (res.headers && res.headers.get("Token")) {
                token.current = res.headers.get("Token")
                if (token.current) {
                    localStorage.setItem("wf_oversea_token", token.current)
                }
            }
            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }
    }
    async function getMe() {
        // const endpoint = apiUrl + "/users/me"
        const url = endpoint.getEndpoint("me")
        try {
            const res: any = await fetch(url, {
                // mode: 'cors',
                method: 'GET',
                credentials: 'include',
                headers: getHeaders(),
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    async function getUsers() {
        // const endpoint = apiUrl + "/users/me"
        const url = endpoint.getEndpoint("")
        try {
            const res: any = await fetch(url, {
                // mode: 'cors',
                method: 'GET',
                credentials: 'include',
                headers: getHeaders(),
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    async function deleteUser(id: string) {
        // const endpoint = apiUrl + "/users/me"
        const url = endpoint.getEndpointWithId(id, "delete")
        try {
            const res: any = await fetch(url + "?permanent=true", {
                // mode: 'cors',
                method: 'POST',
                credentials: 'include',
                headers: getHeaders(),
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    async function logout() {
        // token = ""
        localStorage.removeItem("nada_token")
        window.location.reload()
    }

    return { createUser, loginById, getMe, login, logout, loginForToken, getUsers, deleteUser }

}


export function useUserWithOAuth(auth: any, apiUrl?: string) {
    const client = new ApiClient();
    client.basePath = apiUrl || `${wfConfig.apiURL.protocol}//${wfConfig.apiURL.hostname}/api`;
    client.authc = auth;
    const preferencesApi = new PreferencesServiceApi(client);
    const ordersApi = new OrdersServiceApi(client);
    // const [results, setResults] = useState<any>()
    const endpoint = new Endpoint({ apiURL: apiUrl, defaultPath: "/v1/users" })

    async function createUser(user: any, token: any, inviteId: any, redirectTo: any) {
        // const endpoint = apiUrl + "/users"
        const url = endpoint.getEndpoint()
        try {
            const res: any = await fetch(url + "?" + `r=${redirectTo}`, {
                method: 'POST',
                body: JSON.stringify(user),
                credentials: 'include',
            })
            const data = await res.json()
            return { data }

        } catch (error) {
            return { error }

        }
    }
    async function loginById(id: any, password: any, redirectTo: any) {
        const endpoint = apiUrl + "/v1/users/login"
        try {
            const res: any = await fetch(endpoint + "?" + "format=old", {
                method: 'POST',
                body: JSON.stringify({
                    id: id,
                    password: password
                }),
                credentials: 'include'
            })
            if (res.headers && res.headers.get("Token")) {
                token = res.headers.get("Token")
                localStorage.setItem("nada_token", token as string)
            }
            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }
    }
    async function loginForToken(_token: string) {
        token = _token
        localStorage.setItem("nada_token", _token)
        const { data, error } = await getMe()

        return { data, error }
    }
    async function login(login_id: any, password: any, redirectTo: any) {
        const url = endpoint.getEndpoint("login")
        try {
            const res: any = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    login_id: login_id,
                    password: password
                }),
                credentials: 'include'
            })
            if (res.headers && res.headers.get("Token")) {
                token = res.headers.get("Token")
                localStorage.setItem("wf_oversea_token", token as string)
                await auth.setToken(token)
            }
            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }
    }
    async function tokenFetch(url: string, request: any) {
        return auth.token().then(async (tt: any) => {
            const { accessToken, tokenType } = tt || {}

            // headers = new Headers()
            request.headers = request.headers || new Headers();
            if (accessToken) {

                const token = accessToken;//|| localStorage.getItem("wf_oversea_token")
                if (token && token != "") {
                    request.headers.set("Authorization", `${tokenType} ${token}`);
                }
            }

            return fetch(url, request)
        })
    }
    async function getMe() {
        // const endpoint = apiUrl + "/users/me"
        return auth.token().then(async (t: any) => {
            // headers = new Headers()
            const { accessToken, tokenType } = t || {}
            const headers = new Headers({ 'content-type': 'application/json' });
            const token = accessToken;//|| localStorage.getItem("wf_oversea_token")
            if (token && token != "") {

                headers.set("Authorization", `${tokenType} ${token}`);
            }
            const url = endpoint.getEndpoint("me")
            try {
                const res: any = await auth.tokenFetch(url, {
                    // mode: 'cors',
                    method: 'GET',
                    // credentials: 'include',
                    // headers: headers,
                })
                const data = await res.json()
                if (data && data.status_code) {
                    return { error: data, data: {} }
                }
                return { data: data || {} }

            } catch (error) {
                return { error }

            }
        })
    }

    async function loadMe() {
        // const data: any = {}
        // async function dispatch(name: string, fn: any) {
        //     const { data, error } = await fn()
        //     data[name] = { data, error }
        // }
        // const promises = [
        //     dispatch("user", getMe),
        // ];

        const userRes = await getMe()
        if (userRes.error) {
            return userRes
        }
        const preferenceRes = await getUserPreference()
        return {
            user: userRes,
            preference: preferenceRes
        }
    }
    async function getUsers(name?: string) {
        // const endpoint = apiUrl + "/users/me"
        const url = endpoint.getEndpoint("")
        try {

            const res: any = await auth.tokenFetch(`${url}${name ? "?name=" + name : ""}`, {
                // mode: 'cors',
                method: 'GET',
                credentials: 'include',
                // headers: getHeaders(),
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    async function getUserById(id: string) {
        // const endpoint = apiUrl + "/users/me"
        const url = endpoint.getEndpoint(id)
        try {

            const res: any = await tokenFetch(`${url}`, {
                // mode: 'cors',
                method: 'GET',
                credentials: 'include',
                // headers: getHeaders(),
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    async function deleteUser(id: string) {
        // const endpoint = apiUrl + "/users/me"
        const url = endpoint.getEndpointWithId(id, "delete")
        try {
            const res: any = await tokenFetch(url + "?permanent=true", {
                method: 'POST',
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }

    async function updateUserRoles(id: string, roles: any) {
        // const endpoint = apiUrl + "/users/me"
        const url = endpoint.getEndpointWithId(id, "roles")
        try {
            const res: any = await tokenFetch(url + "?permanent=true", {
                method: 'POST',
                body: JSON.stringify({ roles }),
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    async function updateUser(id: string, user: any, paths: any) {
        // const endpoint = apiUrl + "/users/me"
        const url = endpoint.getEndpointWithId(id, "update")
        try {
            user.id = id
            const res: any = await tokenFetch(url + "?permanent=true", {
                method: 'POST',
                body: JSON.stringify({ fieldMask: { paths }, user }),
            })

            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    async function logout(reqdata?: any) {
        // token = ""
        // localStorage.removeItem("nada_token")
        // window.location.reload()
        debugger
        try {

            // "https://globe.wanfangdata.com.cn/api/v1"
            // const url = "https://accounts.dr2am.cn/oauth/deauthorize";// + stringify(reqdata)
            // const url = "https://globe.wanfangdata.com.cn/oauth/deauthorize";// + stringify(reqdata)
            let url = apiUrl || `${wfConfig.apiURL.protocol}//${wfConfig.apiURL.host}/oauth/deauthorize`;// + stringify(reqdata)
            // const url = endpoint1.getEndpoint("logout")
            if (apiUrl) {
                const a = new URL(apiUrl)
                url = `${a.protocol}//${a.host}/oauth/deauthorize`
            }
            const req = {
                method: 'POST',
                body: JSON.stringify({
                    ...reqdata,
                    client_id: auth.config.clientId
                })
            }
            const res: any = await auth.tokenFetch(url, {
                // mode: 'cors',
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    ...reqdata,
                    client_id: auth.config.clientId
                })
                // headers: headers,
            })
            // const res: any = await tokenFetch(url, req)
            // const data = await res.json()
            // if (data && data.status_code) {
            //     return { error: data, data: {} }
            // }
            // localStorage.removeItem(AUTHORIZED_USER)
            // return { data: data || {} }

        } catch (error) {

        } finally {
            return auth.revoke()
        }

    }
    async function updatePreference(preference: any) {
        try {
            // new LocalesApi(client);
            const res = preferencesApi.preferencesServiceUpdate({ preference, update_mask: { paths: ["value"] } });
            return handlePbError(res)
        } catch (error) {
            return { error }
        }


    }

    async function getUserPreference(userId?: string) {
        try {
            // new LocalesApi(client);
            const res = await preferencesApi.preferencesServiceGetWithUser(userId || "me");
            return handlePbError(res)
        } catch (error) {
            return { error }
        }
    }
    async function listPreference(query: any) {
        try {
            // new LocalesApi(client);
            const res = await preferencesApi.preferencesServiceList({
                query: query,
                paginateLimit: 100
            } as any);

            return handlePbError(res)
        } catch (error) {
            return { error }
        }
    }

    async function getUserOrders(userId?: string) {
        try {
            const res = await ordersApi.ordersServiceGetWithUser(userId || "me");
            return handlePbError(res)
        } catch (error) {
            return { error }
        }
    }

    async function CreateUserOrder(userId: string, order: any) {
        try {
            const res = await ordersApi.ordersServiceCreate({
                order: {
                    ...order,
                    userId: userId
                }
            });
            return handlePbError(res)
        } catch (error) {
            return { error }
        }
    }
    async function updateUserOrder(userId: string, order: any, paths: any) {
        try {
            const res = await ordersApi.ordersServiceUpdate({
                order: {
                    ...order,
                    userId: userId
                },
                update_mask: { paths }
            });
            return handlePbError(res)
        } catch (error) {
            return { error }
        }
    }
    async function removeOrder(id: string) {
        try {
            const res = await ordersApi.ordersServiceDelete(id);
            return handlePbError(res)
        } catch (error) {
            return { error }
        }
    }
    return {
        createUser, loginById, getMe, login, logout, loginForToken, getUsers, getUserById, deleteUser, updateUser, updateUserRoles, loadMe,
        //preferences
        updatePreference, getUserPreference, listPreference,
        //order
        getUserOrders, CreateUserOrder, updateUserOrder, removeOrder,
    }

}
export function handlePbError(res: any) {
    if (!res) {
        return { error: { status: "error", msg: "unkonw error" } }
    }
    if (res && res.status && res.status.code != 200) {
        return { ...res, error: res.status }
    }
    return { ...res }
}
export function useConditionExpression(auth: any, apiUrl?: string) {
    const endpoint = new Endpoint({ apiURL: apiUrl, defaultPath: "/v1/condition/expression" })
    async function check(expression: any) {
        const url = endpoint.getEndpoint("check")
        try {
            let formData = new FormData();
            formData.append('expression', JSON.stringify(expression));
            // formData.append('password', 'John123');

            const res: any = await auth.tokenFetch(url, {
                method: 'POST',
                body: formData
                // credentials: 'include',
                // mode: 'cors'
            })
            const data = await res.json()
            if (data && data.status_code) {
                return { error: data, data: {} }
            }
            return { data: data || {} }

        } catch (error) {
            return { error }

        }

    }
    return { check }
}


