import { CoreService } from "../../types";
import { InternalCoreSetup, InternalCoreStart, CoreContext } from "../core_system";
import { PluginName, DiscoveredPlugin, PluginOpaqueId } from "../../types/plugins";
import { PluginWrapper } from "./plugins";
import { createPluginInitializerContext, createPluginSetupContext, createPluginStartContext } from "./plugins_context";


/** @internal */
export type PluginsServiceSetupDeps = InternalCoreSetup;
/** @internal */
export type PluginsServiceStartDeps = InternalCoreStart;

/** @internal */
export interface PluginsServiceSetup {
    contracts: ReadonlyMap<string, unknown>;
}
/** @internal */
export interface PluginsServiceStart {
    contracts: ReadonlyMap<string, unknown>;
}

export class PluginsService implements CoreService<PluginsServiceSetup, PluginsServiceStart>  {
    /** Plugin wrappers in topological order. */
    private readonly plugins = new Map<PluginName, PluginWrapper<unknown, Record<string, unknown>>>();
    private readonly pluginDependencies = new Map<PluginName, PluginName[]>();
    private readonly satupPlugins: PluginName[] = [];


    constructor(
        private readonly coreContext: CoreContext,
        plugins: Array<{ id: PluginName, plugin: DiscoveredPlugin }>
    ) {
        // 生成opaque(不透明)的ids
        const opaqueIds = new Map<PluginName, PluginOpaqueId>(plugins.map(p => [p.id, Symbol(p.id)]));

        plugins.forEach(({ id, plugin }) => {
            const opaqueid = opaqueIds.get(id)!;
            if (plugin.requiredPlugins && plugin.requiredPlugins.length) {
                this.pluginDependencies.set(id, [...plugin.requiredPlugins])
            }
            this.plugins.set(
                id,
                new PluginWrapper(
                    plugin,
                    opaqueid,
                    createPluginInitializerContext(
                        this.coreContext,
                        opaqueid,
                        plugin
                    )
                ),
            );
        });
    }
    public async setup(
        deps: PluginsServiceSetupDeps
    ): Promise<PluginsServiceSetup> {
        // // Load plugin bundles
        // await this.loadPluginBundles(deps.http.basePath.prepend);

        // setup每个插件
        const contracts = new Map<string, unknown>();
        //@ts-ignore
        for (const [pluginName, plugin] of this.plugins.entries()) {
            let pluginDepContracts: any = {};
            const deps2 = this.pluginDependencies.get(pluginName)
            if (deps2) {
                pluginDepContracts = [...deps2].reduce(
                    (depContracts, dependencyName) => {
                        // Only set if present. Could be absent if plugin does not have client-side code or is a
                        // missing optional plugin.
                        if (contracts.has(dependencyName)) {
                            depContracts[dependencyName] = contracts.get(dependencyName);
                        }

                        return depContracts;
                    },
                    {} as Record<PluginName, unknown>
                );
            }


            contracts.set(
                pluginName as string,
                await plugin.setup(
                    createPluginSetupContext(this.coreContext, deps, plugin),
                    pluginDepContracts
                )
            );

            //Set plugin status to startup
            this.satupPlugins.push(pluginName);
        }
        return {
            contracts,
        };
    }

    public async start(
        deps: PluginsServiceStartDeps
    ): Promise<PluginsServiceStart> {

        // setup每个插件
        const contracts = new Map<string, unknown>();
        //@ts-ignore
        for (const [pluginName, plugin] of this.plugins.entries()) {
            contracts.set(
                pluginName,
                await plugin.start(
                    createPluginStartContext(this.coreContext, deps, plugin),
                    //   pluginDepContracts
                )
            );
        }
        return {
            contracts
        };
    }

    public async stop() {
        // Stop plugins in reverse topological order.
        for (const pluginName of this.satupPlugins.reverse()) {
            this.plugins.get(pluginName)!.stop();
        }
    }

    /**
     * name
     */
    public getPlugin(name: string) {
        return this.plugins.get(name)
    }
}