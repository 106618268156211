
import ClientOAuth2 from 'client-oauth2';
import _, { uniqueId } from 'lodash';
import { parseUrl, stringify, stringifyUrl } from 'query-string';
import { EventEmitter } from './event_emitter';
import { generateId } from './utils';


export const AUTHORIZED_USER = 'authorized_user'
export const AUTHORIZE_STATE = 'authorize_state'
export const AUTHORIZE_REDIRECT = 'authorize_redirect'
export const AUTHORIZE_NONCE = 'authorize_nonce'
export const AUTHORIZE_SESSION_ID = 'authorize_session_id'

type Configuration = {
    clientId: string,
    clientSecret?: string,
    accessTokenUri: string,
    authorizationUri: string,
    revokeUri?: string,
    redirectUri: string,
    scopes?: string[]
}

export class Authentication extends EventEmitter {
    store!: Storage;
    refreshProcess?: any;
    tokenClient: ClientOAuth2
    // isAuthorized = () => Boolean(this.store.getItem(AUTHORIZED_USER) || false)
    authorizeClient: ClientOAuth2
    config: Configuration
    constructor(
        options?: Configuration,
        store?: Storage
    ) {
        super()
        if (!options) {
            options = {
                clientId: '',
                clientSecret: '',
                accessTokenUri: '',
                authorizationUri: '',
                revokeUri: '',
                redirectUri: '',
                scopes: []
            }
        }
        if (store) {
            this.store = store
        } else if (typeof localStorage !== 'undefined') {
            this.store = localStorage
        }
        const mergedOptions = {
            clientId: options.clientId,
            clientSecret: options.clientSecret,//'is-public', // workaround for https://github.com/ory/fosite/issues/217
            accessTokenUri: options.accessTokenUri,
            authorizationUri: options.authorizationUri,
            revokeUri: options.revokeUri,
            redirectUri: options.redirectUri,
            scopes: options.scopes || ['offline', 'openid', 'force-consent'],
            type: ["token", "id_token"]
        }
        this.config = mergedOptions
        this.authorizeClient = new ClientOAuth2(mergedOptions)
        this.tokenClient = new ClientOAuth2({
            ...mergedOptions,
            clientId: encodeURIComponent(mergedOptions.clientId)
        })
    }


    storeToken = (token: ClientOAuth2.Token): Promise<ClientOAuth2.Token> => {

        const payload = {
            ...token,
            data: {
                ...token.data,
                expires_in: (token as any).expires
                // expires: token.expired()
            },
            // expiresIn: token.data.expires,
            client: null
        }
        this.store.setItem(AUTHORIZED_USER, JSON.stringify(payload.data))
        this.refreshProcess = null
        return Promise.resolve(token)
    }

    authorizeCode = ({ return_url, query }: any) => {
        const state = generateId()
        const nonce = generateId()
        this.store.setItem(AUTHORIZE_STATE, state)
        this.store.setItem(AUTHORIZE_NONCE, nonce)
        this.store.setItem(AUTHORIZE_REDIRECT, return_url)
        const url = this.authorizeClient.code.getUri({
            state,
            query
        })
        window.location.href = url
    }

    authorizeToken = () => {
        const state = uniqueId()
        const nonce = uniqueId()
        //   this.store.setItem(AUTHORIZE_STATE, state)
        //   this.store.setItem(AUTHORIZE_NONCE, nonce)
        const url = this.authorizeClient.token.getUri({
            state,
        })
        window.location.href = url
    }
    authorizePw = (username: string, password: string) => {
        const state = uniqueId()
        const nonce = uniqueId()
        this.store.setItem(AUTHORIZE_STATE, state)
        this.store.setItem(AUTHORIZE_NONCE, nonce)
        return this.authorizeClient.owner.getToken(username, password, {
            state,
        })
    }
    authorizationCallback = (url: string = window.location.href) => {
        const return_url = this.store.getItem(AUTHORIZE_REDIRECT)

        let state = this.store.getItem(AUTHORIZE_STATE) || ""
        return this.tokenClient.token
            .getToken(url, { state: state })
            .then(async token => {
                this.store.removeItem(AUTHORIZE_NONCE)
                this.store.removeItem(AUTHORIZE_STATE)
                this.store.removeItem(AUTHORIZE_REDIRECT)
                token = await this.storeToken(token)
                if (return_url && return_url != "") {
                    window.location.href = return_url
                }
                return token
            })
    }

    authorizationCallback2 = (url: string = window.location.href) => {
        const return_url = this.store.getItem(AUTHORIZE_REDIRECT)
        const state = this.store.getItem(AUTHORIZE_STATE)
        return this.authorizeClient.code
            .getToken(url, { state: state || undefined })
            .then(async token => {
                this.store.removeItem(AUTHORIZE_NONCE)
                this.store.removeItem(AUTHORIZE_STATE)
                this.store.removeItem(AUTHORIZE_REDIRECT)
                token = await this.storeToken(token)
                if (return_url && return_url != "") {
                    window.location.href = return_url
                }
                return token
            })
    }
    revoke = async () => {
        this.store.removeItem(AUTHORIZE_NONCE)
        const state = this.store.getItem(AUTHORIZE_STATE)
        if (state) {
            this.store.removeItem(state)
        }
        this.store.removeItem(AUTHORIZE_STATE)
        this.store.removeItem(AUTHORIZED_USER)
        this.store.removeItem(AUTHORIZE_REDIRECT)
        // return Promise.resolve(null)
    }
    token = async (): Promise<ClientOAuth2.Token | void> => {
        let user
        try {
            // user = {"client":null,"data":{"access_token":"ZBFS042LNNWVH4JIQHEKMW","refresh_token":"D7MDWQ3MUIM5DIOIP0ULQA","expires_in":"7200","id_token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm9maWxlIjp7IkNsaWVudElEIjoiNDgyOTlhNjEtNDcyYy00MzZmLWI2YTItMGJlODI2YjUwZDJlIiwiSG9zdE5hbWUiOiIiLCJJc0F1dGhlZCI6dHJ1ZSwiSXNWYWxpZCI6dHJ1ZSwiTGlicmFyeUdyb3VwIjoiIiwiTmFtZSI6InlvcmhlX2NoYW4iLCJSb2xlcyI6InN5c3RlbV9hZG1pbiIsIlRlbmFudElkcyI6IiIsIlVzZXJOYW1lIjoieW9yaGVfY2hhbkBob3RtYWlsLmNvbSJ9fQ.rrea3-IsbfKyduzWot1o1k6LlHVhpWOWBR2kqNaC3SU","scope":"offline openid","token_type":"Bearer","expires":"2019-11-19T13:46:14.198Z"},"tokenType":"bearer","accessToken":"ZBFS042LNNWVH4JIQHEKMW","refreshToken":"D7MDWQ3MUIM5DIOIP0ULQA","expires":"2019-11-19T13:46:14.198Z"}
            user = JSON.parse(this.store.getItem(AUTHORIZED_USER) || 'null')
        } catch (e) {
            console.error(e)
            //log.exception(
            // `Unable to fetch and parse user information from localStore because ${
            //   e.message
            // }`
            // )
            return this.revoke().then(() => Promise.reject(e))
        }
        // if (!user || !user.accessToken || !user.refreshToken) {
        if (!user || !user.access_token) {

            const err = new Error(
                'Unable to retrieve authentication information from localStore.'
            )
            //log.exception(err)
            // this.authorizeCode()//.then(() => Promise.reject(err))
            // return Promise.resolve(undefined)
            // return this.authorizeClient.code.
            return
        }

        // console.log(expires)
        // console.log(now)
        // console.log(expires.getSeconds())
        // console.log(now.getSeconds())
        // console.log(Math.round((expires.getTime() - now.getTime()) / 1000))
        // user.expires_in = Math.round((expires.getTime() - now.getTime()) / 1000)
        const token = this.tokenClient.createToken(
            // user.accessToken,
            // // user.refreshToken,
            // user.tokenType,
            user
        )
        if (token && user && user.expires_in) {
            const now = new Date()
            const expires = new Date(user.expires_in)
            token.expiresIn(expires)

        }
        if (token && token.data && !token.expired()) {
            return token

        }

        // token.expiresIn(new Date(user.expires))

        // if (token.expired()) {
        if (!this.refreshProcess) {
            //log.info('Token is already refreshing, returning existing promise.')
            this.refreshProcess = token
                .refresh()
                .then(this.storeToken)
                .catch(err => {
                    return this.revoke().then(() => Promise.reject(err))
                })
        }

        return this.refreshProcess
        // }

        return token //Promise.resolve(token)
    }
    async tokenFetch(url: string, request: any) {
        return this.token().then(async (token: ClientOAuth2.Token | void) => {
            // if (!token) {
            //     return fetch(url, { ...request })
            // }
            let headers: any
            const req = token?.sign({ url, headers: request.headers })
            headers = req?.headers
            try {
                if (req) {
                    request = _.assign(request, req)
                    url = req.url
                }
                const res = await fetch(url, { ...request })
                if (res.status > 300) {
                    this.dispatch("error", res)
                }
                return res

            } catch (error) {
                this.dispatch("error", error)
                console.error(error);

            }
        })
    }
    async setToken(token: string) {
        const nToken = await this.tokenClient.createToken(token, "", "bearer", {})
        return this.storeToken(nToken)

    }
}