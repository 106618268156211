import { DiscoveredPlugin, PluginOpaqueId } from "../../types/plugins";
import { PluginInitializerContext } from "./plugins_context";
import { CoreSetup, CoreStart } from "..";



/**
 * The interface that should be returned by a `PluginInitializer`.
 *
 * @public
 */
export interface Plugin<
    TSetup = void,
    TStart = void,
    TPluginsSetup extends object = object,
    TPluginsStart extends object = object
    > {
    setup(core: CoreSetup, plugins: TPluginsSetup): TSetup | Promise<TSetup>;
    start(core: CoreStart, plugins: TPluginsStart): TStart | Promise<TStart>;
    stop?(): void;
}

/**
 * The `plugin` export at the root of a plugin's `public` directory should conform
 * to this interface.
 *
 * @public
 */
export type PluginInitializer<
    TSetup,
    TStart,
    TPluginsSetup extends object = object,
    TPluginsStart extends object = object
    > = (core: PluginInitializerContext) => Plugin<TSetup, TStart, TPluginsSetup, TPluginsStart>;


/**
 * The `plugin` export at the root of a plugin's `public` directory should conform
 * to this interface.
 *
 * @public
 */
// export type PluginInitializer<
//   TSetup,
//   TStart,
//   TPluginsSetup extends object = object,
//   TPluginsStart extends object = object
// > = (core: PluginInitializerContext) => Plugin<TSetup, TStart, TPluginsSetup, TPluginsStart>;


/**
 * Lightweight wrapper around discovered plugin that is responsible for instantiating
 * plugin and dispatching proper context and dependencies into plugin's lifecycle hooks.
 *
 * @internal
 */
export class PluginWrapper<
    TSetup = unknown,
    TStart = unknown,
    TPluginsSetup extends object = object,
    TPluginsStart extends object = object
    > {
    public readonly name: DiscoveredPlugin['id'];
    // private initializer?: PluginInitializer<TSetup, TStart, TPluginsSetup, TPluginsStart>;
    private initializer?: any;
    private instance?: Plugin<TSetup, TStart, TPluginsSetup, TPluginsStart>;
    constructor(
        readonly discoveredPlugin: DiscoveredPlugin,
        public readonly opaqueId: PluginOpaqueId,
        private readonly initializerContext: PluginInitializerContext
    ) {
        this.name = discoveredPlugin.id;
        this.initializer = discoveredPlugin.instance;
    }

    public async setup(setupContext: CoreSetup, plugins: TPluginsSetup) {
        this.instance = await this.createPluginInstance();
        return await this.instance.setup(setupContext, plugins);
    }

    public async start(startContext: CoreStart, plugins: TPluginsStart) {
        if (this.instance === undefined) {
            throw new Error(`Plugin "${this.name}" can't be started since it isn't set up.`);
        }

        return await this.instance.start(startContext, plugins);
    }

    public stop() {
        if (this.instance === undefined) {
            throw new Error(`Plugin "${this.name}" can't be stopped since it isn't set up.`);
        }

        if (typeof this.instance.stop === 'function') {
            this.instance.stop();
        }

        this.instance = undefined;
    }

    private async createPluginInstance() {
        //@ts-ignore

        // const instance = tenants_plugin()
        const instance = this.initializer()
        //@ts-ignore
        return Promise.resolve(instance as Plugin<TSetup, TStart, TPluginsSetup, TPluginsStart>)
        // if (this.initializer === undefined) {
        //     throw new Error(`Plugin "${this.name}" can't be setup since its bundle isn't loaded.`);
        // }

        // const instance = this.initializer(this.initializerContext);

        // if (typeof instance.setup !== 'function') {
        //     throw new Error(`Instance of plugin "${this.name}" does not define "setup" function.`);
        // } else if (typeof instance.start !== 'function') {
        //     throw new Error(`Instance of plugin "${this.name}" does not define "start" function.`);
        // }

        // return instance;
    }

}