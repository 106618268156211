/*
 * types/services/dr2am.locales.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The ServicePublishLocalesRequest model module.
 * @module model/ServicePublishLocalesRequest
 * @version version not set
 */
export class ServicePublishLocalesRequest {
  /**
   * Constructs a new <code>ServicePublishLocalesRequest</code>.
   * @alias module:model/ServicePublishLocalesRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServicePublishLocalesRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServicePublishLocalesRequest} obj Optional instance to populate.
   * @return {module:model/ServicePublishLocalesRequest} The populated <code>ServicePublishLocalesRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServicePublishLocalesRequest();
    }
    return obj;
  }
}

