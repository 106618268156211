import { ReactElement } from "react";
import { EventEmitter } from "../EventEmitter";

export interface ConsoleSectionParams {
    id?: string;
    display: string | ReactElement | undefined;
    href?: string;
    icon?: any;
    items?: SideBarSection[];
    onClick?: any;

}

// @internal
export class SideBarSection extends EventEmitter {
    public id!: string
    public display?: any
    public href?: string
    public icon?: any
    public items?: SideBarSection[]
    public onClick?: any

    constructor(id: string, options?: ConsoleSectionParams) {
        super()

        this.id = id;
        this.items = []
        if (options) {
            this.href = options.href;
            this.display = options.display || id;
            this.id = id;
            this.items = options.items || [];
            this.icon = options.icon;
            this.onClick = options.onClick


        }
    }

    /**
       * 注册一个子section
       *
       * @param {string} id
       * @param {object} options
       * @returns {ConsoleSection}
       */

    register(id: string, options?: ConsoleSectionParams) {
        const item = new SideBarSection(id, options)
        this.items && this.items.push(item)
        this.dispatch("update", this)
    }



    /**
     * 注销一个section
     *
     * @param {string} id
     */
    deregister(id: string) {
        if (!this.items) {
            return
        }

        const idx = this.items.findIndex(item => item.id === id)
        if (idx > -1) {
            this.items && (this.items.splice(idx, 1) || this.dispatch("update", this))
        }
    }

    close() {
        this.dispatch("onclose", this)
    }

    open() {
        this.dispatch("onopen", this)
    }

    dock(is?: boolean) {
        this.dispatch("ondock", is)
    }

    headerClose() {
        this.dispatch("onHeaderClose", this)
    }
    headerOpen() {
        this.dispatch("onHeaderOpen", this)
    }
    getSection(id?: string): SideBarSection {
        return this
    }

}


