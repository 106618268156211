import { Authentication } from './oauth';
import { getPermissionStore } from './permissions';
import { RolesStore } from './roles';



class Plugin {
    constructor() {
    }

    setup = ({
        ...core
    }, {
        ...other
    }) => {
        const { security } = other;
        core.application.register({
            id: 'security',
            title: 'security',
            mount: async (context: any, params: any) => {
                // const oauth = new Authentication({
                //     clientId: "gjk78q7wjfgcdcdcoe86ywgrpy",
                //     clientSecret: "m4omu4j9mfbn58md83rxakoewc",
                //     // accessTokenUri: "https://oversea.wanfangdata.com.cn/test/oauth/token",
                //     accessTokenUri: "https://globe.wanfangdata.com.cn/test/oauth/access_token",
                //     authorizationUri: "https://globe.wanfangdata.com.cn/test/oauth/authorize",
                //     redirectUri: "http://localhost:3000/app/security_settings/callback",
                // })
                const wfConfig = core.coreStore.get("wfConfig")
                const {
                    renderApp
                } = await import('./application');
                return renderApp(context, {
                    ...params,
                    oauth: security.authc,
                    permissionsStore: getPermissionStore(security.authc, wfConfig.apiURL.href),
                    rolesStore: RolesStore(security.authc, wfConfig.apiURL.href),
                    wfConfig: wfConfig,
                });
            }
        });
        return this
    }
    start = (core: any) => {
        const { chrome } = core
        chrome.addNavs("security", {
            display: "安全设置",
            href: "/app/security",
            icon: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M8.156 1.835a.25.25 0 00-.312 0l-5.25 4.2a.25.25 0 00-.094.196v7.019c0 .138.112.25.25.25H5.5V8.25a.75.75 0 01.75-.75h3.5a.75.75 0 01.75.75v5.25h2.75a.25.25 0 00.25-.25V6.23a.25.25 0 00-.094-.195l-5.25-4.2zM6.906.664a1.75 1.75 0 012.187 0l5.25 4.2c.415.332.657.835.657 1.367v7.019A1.75 1.75 0 0113.25 15h-3.5a.75.75 0 01-.75-.75V9H7v5.25a.75.75 0 01-.75.75h-3.5A1.75 1.75 0 011 13.25V6.23c0-.531.242-1.034.657-1.366l5.25-4.2h-.001z"></path></svg>)
        })

    }
    stop = () => {

    }

}

export class SecurityPlugin {
    private authc!: Authentication
    constructor() {

    }
    setup = async ({ application, getStartServices, http, config, ...core }: any) => {
        // core.application.register({
        //     id: 'demo',
        //     title: 'demo',
        //     mount: async (context: any, params: any) => {
        //         const {
        //             renderApp
        //         } = await import('./application');

        //         return renderApp(context, params);

        //     }
        // });
        // const api = await (new core.api(undefined, undefined)).setup()
        const api = {}
        this.authc = new Authentication({
            clientId: "h9d87pgfyty9jczqoh6t9z1r1r",
            clientSecret: "k7kap4mtyfdcicwgaufhj9mhmw",
            accessTokenUri: "https://accounts.dr2am.cn/oauth/access_token",
            authorizationUri: "https://accounts.dr2am.cn/oauth/authorize",
            // redirectUri: "https://globe.wanfangdata.com.cn/oauth/authorize",
            // authorizationUri: "https://globe.wanfangdata.com.cn/oauth/authorize",
            redirectUri: "http://localhost:3000/app/security/callback",
        })

        //nada
        // this.authc = new Authentication({
        //     clientId: "h9d87pgfyty9jczqoh6t9z1r1r",
        //     clientSecret: "k7kap4mtyfdcicwgaufhj9mhmw",
        //     // accessTokenUri: "https://oversea.wanfangdata.com.cn/test/oauth/token",
        //     accessTokenUri: "https://accounts.dr2am.cn/oauth/access_token",
        //     authorizationUri: "https://accounts.dr2am.cn/oauth/authorize",
        //     redirectUri: "http://localhost:3000/app/security/callback",
        // })

        this.authc = new Authentication({
            clientId: "sfqsexdn1in4dcmweruhu6m7xe",
            clientSecret: "99rpyb7shj85byjk5nntu1ojjh",
            // accessTokenUri: "https://oversea.wanfangdata.com.cn/test/oauth/token",
            accessTokenUri: "https://accounts.dr2am.cn/oauth/access_token",
            authorizationUri: "https://accounts.dr2am.cn/oauth/authorize",
            redirectUri: "http://localhost:3000/app/security/callback",
        })

        //oversea
        this.authc = new Authentication({
            clientId: "1f96jzkonfbhdyzd96ny19nmwa",
            clientSecret: "8oaawm1jppdx7bkkr44p4oaasw",
            // accessTokenUri: "https://oversea.wanfangdata.com.cn/test/oauth/token",
            accessTokenUri: "https://oversea.wanfangdata.com.cn/oauth/access_token",
            authorizationUri: "https://oversea.wanfangdata.com.cn/oauth/authorize",
            revokeUri: "https://oversea.wanfangdata.com.cn/oauth/deauthorize",
            redirectUri: "http://localhost:3000/app/security/callback",

        })

        // globe
        // this.authc = new Authentication({
        //     clientId: "ribiih6fqpgt8bkg5nf161wr5w",
        //     clientSecret: "p63q5ymp43gq5jm57rwzjcwr4o",
        //     accessTokenUri: "https://oversea.wanfangdata.com.cn/test/oauth/token",
        //     authorizationUri: "https://oversea.wanfangdata.com.cn/oauth/authorize",

        //     // accessTokenUri: "https://globe.wanfangdata.com.cn/oauth/access_token",
        //     // authorizationUri: "https://globe.wanfangdata.com.cn/oauth/authorize",
        //     // redirectUri: "https://globe.wanfangdata.com.cn/app/security/callback",
        //     // redirectUri: "https://globe.wanfangdata.com.cn/app/security/callback",
        //     redirectUri: "http://localhost:3000/app/security/callback",
        // })

        // globehk
        this.authc = new Authentication({
            clientId: "gqhq9ieyw7rduc61wc5367caqh",
            clientSecret: "zx8zru3yxf8nidgnd8mfwt14co",
            // accessTokenUri: "https://oversea.wanfangdata.com.cn/test/oauth/token",
            accessTokenUri: "https://globe.wanfangdata.com.hk/oauth/access_token",
            authorizationUri: "https://globe.wanfangdata.com.hk/oauth/authorize",
            // revokeUri: "https://oversea.wanfangdata.com.cn/oauth/deauthorize",
            // redirectUri: "https://globe.wanfangdata.com.cn/app/security/callback",
            // redirectUri: "http://localhost:3000/app/security/callback",
            redirectUri: `${window.location.origin}/app/security/callback`,

        })

        return {
            authc: this.authc
        }
    }
    start = (core: any) => { }
    stop() { }

}
// http://localhost:3000/app/security/callback
// https://globe.wanfangdata.com.cn/app/security/callback
// http://globe.wanfangdata.com.cn/app/security/callback
// https://globe.wanfangdata.com.hk/app/security/callback
// http://globe.wanfangdata.com.hk/app/security/callback

const registerPlugin = (window: any) => {
    const security_settings = () => new Plugin();
    const security = () => new SecurityPlugin();
    window.registerPlugin("security", {
        id: "security",
        plugin: {
            id: "security",
            configPath: "",
            instance: security,
            // requiredPlugins: ["security"]
        },
    })
    window.registerPlugin("security_settings", {
        id: "security_settings",
        plugin: {
            id: "security_settings",
            configPath: "",
            instance: security_settings,
            requiredPlugins: ["security"]
        },
    })

}

// export const Home = () => new Plugin();
const home = {
    registerPlugin: registerPlugin
}
export default home
