import styled from "styled-components"


export const TableHeader = styled.thead`

`
export const TableHeaderColumn = styled.th<any>`
&.dlabel {
border-top: 0;
border-left: 0;
left: 0;
z-index: 1;
background-clip: padding-box;
}
`
export const TableRow = styled.tr`
`

export const TableColumn = styled.td<any>`
&.dlabel {
border-top: 0;
border-left: 0;
left: 0;
z-index: 1;
background-clip: padding-box;
}
`

export const TableBody = styled.tbody`
`

export const DataTable = styled.table`
border: 0;
width: 100%;
padding: 0;
border-spacing: 0;
border-collapse: separate;
margin: 0;
& > ${TableHeader} {
// position: sticky;
top: 0;
z-index: 10;

}
& > ${TableHeader} > ${TableRow}  > ${TableHeaderColumn} {
    background: #eff0f1;
    border-radius: 0;
    color: #37474f;
    // text-transform: uppercase;
    font-size: 11px;
    line-height: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
    vertical-align: middle;
}
& > ${TableBody} > ${TableRow}  > ${TableColumn} {
    padding-top: 10px;
    padding-bottom: 9px;
    border-bottom: 1px solid #f2f2f2 !important;
    border-color: #f2f2f2 !important;
    color: #212121;
    background: #ffffff;
}

& tr ${TableColumn}:first-child {
    padding-left: 20px;
}

& tr ${TableColumn}:last-child {
    padding-right: 20px;
}
`
