// @flow
import { Authentication } from './authentication';
import uj from 'url-join';
import qs from 'query-string';
// import { any } from 'prop-types';


export class Configuration {
  storage: Storage
  window: any
  prefix = 'CONFIGURATOR_SERVICE_'

  constructor({ storage, w }: { storage?: Storage, w?: any } = {}) {
    if (storage) {
      this.storage = storage
    } else if (typeof localStorage !== 'undefined') {
      this.storage = localStorage
    } else {
      throw new Error('Unable to set a local storage')
    }

    if (w) {
      this.window = w
    } else if (typeof window !== 'undefined') {
      this.window = window
    } else {
      throw new Error('Unable to set window')
    }

    if (qs.parse(this.window.location.search).clear) {
      this.storage.clear()
    }

    // Preload everything
    // this.features()
    // this.oauth2()
    // this.services()
  }

  features = () =>
    this.get('FEATURES', 'features', process.env.REACT_APP_FEATURES || '')
      .split(',')
      .filter((i: any) => Boolean(i))

  hydraEnabled = () => this.featureEnabled('hydra')
  oathkeeperEnabled = () => this.featureEnabled('oathkeeper')

  featureEnabled = (feature: string) => {
    if (this.features().length === 0) {
      return true
    }

    return this.features().indexOf(feature) > -1
  }

  get = (key: string, queryKey?: string, fallback?: any) => {
    const query = queryKey && (qs.parse(this.window.location.search)[queryKey] as string)

    const item = this.storage.getItem(key)
    if (!item) {
      if (query) {
        this.storage.setItem(key, query)
        return query
      }

      return fallback
    }

    if (query) {
      this.storage.setItem(key, query)
      return query
    }

    return item
  };

  // configured = () => {
  //   const auth = this.oauth2()
  //   const service = this.services()

  //   if (
  //     this.featureEnabled('hydra') &&
  //     !(auth.clientId && auth.redirectUri && service.hydra)
  //   ) {
  //     return false
  //   }

  //   if (this.featureEnabled('oathkeeper') && !service.oathkeeper) {
  //     return false
  //   }

  //   return true
  // }

  oauth2 = () => {
    const options = {
      clientId: "8db68df5-3821-4613-a0ce-c69e70f635d7",
      accessTokenUri: "http://idp.dr2am.cn:8444/oauth2/token",
      authorizationUri: "http://idp.dr2am.cn:8444/oauth2/auth",
      redirectUri: window.location.protocol + "//" + window.location.host + "/app/login/callback",
      scopes: undefined,
    }
    // const mergedOptions = {
    //   clientId: get("OAUTH2_CLIENT_ID","clientid",options.clientId),
    //   clientSecret: 'is-public', // workaround for https://github.com/ory/fosite/issues/217
    //   accessTokenUri:uj(hydraUrl, '/oauth2/token'),
    //   authorizationUri: uj(hydraUrl, '/oauth2/auth'),
    //   redirectUri: options.redirectUri,
    //   scopes: options.scopes || ['offline', 'openid'],
    //   type:["token","id_token"]
    // }
    let servicesUrl = this.get(
      'SERVICES_URL',
      'serviceUrl',
      window.location.protocol + "//" + window.location.host + "/",
      // "http://idp.dr2am.cn:8444" || process.env.REACT_APP_HYDRA_URL || ''
    )
    // servicesUrl = "http://sc.zhsti.net:85"
    // servicesUrl = servicesUrl || "/"

    return {
      clientId: this.get(
        'OAUTH2_CLIENT_ID',
        'clientId',
        options.clientId || process.env.REACT_APP_OAUTH2_CLIENT_ID || ''
      ),
      clientSecret: 'is-public',
      // clientSecret: '999999',
      // clientSecret: 'RzfsnllJRYjcfsap',
      accessTokenUri: uj(servicesUrl, '/oauth2/token'),
      authorizationUri: uj(servicesUrl, '/oauth2/auth'),
      redirectUri: this.get(
        'OAUTH2_REDIRECT_URL',
        'redirectUrl',
        options.redirectUri || process.env.REACT_APP_OAUTH2_REDIRECT_URL || ''
      ),
      scopes: options.scopes || ['offline', 'openid'],
      type: ["token", "id_token"]
    }
  }

  // services = () => ({
  //   hydra: this.get(
  //     'SERVICES_HYDRA_URL',
  //     'hydraUrl',
  //     window.location.protocol+"//"+window.location.host+"/",
  //     // process.env.REACT_APP_HYDRA_URL || ''
  //   ),
  //   oathkeeper: this.get(
  //     'SERVICES_OATHKEEPER_URL',
  //     'oathkeeperUrl',
  //     process.env.REACT_APP_OATHKEEPER_URL || ''
  //   )
  // })
}
const config = new Configuration()
export default new Authentication(config.oauth2())