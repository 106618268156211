/*
 * types/services/dr2am.locales.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {
  ApiClient
} from "../ApiClient";
import {
  RuntimeError
} from '../model/RuntimeError';
import {
  ServiceAddLocaleLanguageRequest
} from '../model/ServiceAddLocaleLanguageRequest';
import {
  ServiceAddLocaleLanguageResponse
} from '../model/ServiceAddLocaleLanguageResponse';
import {
  ServiceAddLocaleNameSpaceRequest
} from '../model/ServiceAddLocaleNameSpaceRequest';
import {
  ServiceAddLocaleNameSpaceResponse
} from '../model/ServiceAddLocaleNameSpaceResponse';
import {
  ServiceAddLocaleResponse
} from '../model/ServiceAddLocaleResponse';
import {
  ServiceListLocaleLanguagesRequest
} from '../model/ServiceListLocaleLanguagesRequest';
import {
  ServiceListLocaleLanguagesResponse
} from '../model/ServiceListLocaleLanguagesResponse';
import {
  ServiceListLocaleNameSpacesRequest
} from '../model/ServiceListLocaleNameSpacesRequest';
import {
  ServiceListLocaleNameSpacesResponse
} from '../model/ServiceListLocaleNameSpacesResponse';
import {
  ServiceListLocalesRequest
} from '../model/ServiceListLocalesRequest';
import {
  ServiceListLocalesResponse
} from '../model/ServiceListLocalesResponse';
import {
  ServicePublishLocalesRequest
} from '../model/ServicePublishLocalesRequest';
import {
  ServicePublishLocalesResponse
} from '../model/ServicePublishLocalesResponse';
import {
  ServiceRemoveLocaleNameSpaceResponse
} from '../model/ServiceRemoveLocaleNameSpaceResponse';
import {
  ServiceRemoveLocaleResponse
} from '../model/ServiceRemoveLocaleResponse';
import {
  ServiceUpdateLocaleRequest
} from '../model/ServiceUpdateLocaleRequest';
import {
  ServiceUpdateLocaleResponse
} from '../model/ServiceUpdateLocaleResponse';

/**
 * Locales service.
 * @module api/LocalesApi
 * @version version not set
 */
export class LocalesApi {

  /**
   * Constructs a new LocalesApi. 
   * @alias module:api/LocalesApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }



  /**
   * AddLocale 创建字段
   * @param {String} localeLang 
   * @param {String} localeNameSpaces 
   * @param {Object} body 
   * @param {Object} opts Optional parameters
   * @param {String} opts.localeId 
   * @param {String} opts.localeKey 
   * @param {String} opts.localeValue 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceAddLocaleResponse} and HTTP response
   */
  localesAddLocaleWithHttpInfo(localeLang, localeNameSpaces, body, opts) {
    opts = opts || {};
    let postBody = body;

    // verify the required parameter 'localeLang' is set
    if (localeLang === undefined || localeLang === null) {
      throw new Error("Missing the required parameter 'localeLang' when calling localesAddLocale");
    }

    // verify the required parameter 'localeNameSpaces' is set
    if (localeNameSpaces === undefined || localeNameSpaces === null) {
      throw new Error("Missing the required parameter 'localeNameSpaces' when calling localesAddLocale");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling localesAddLocale");
    }


    let pathParams = {
      'locale.lang': localeLang,
      'locale.name_spaces': localeNameSpaces
    };
    let queryParams = {
      'locale.id': opts['localeId'],
      'locale.key': opts['localeKey'],
      'locale.value': opts['localeValue']
    };
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServiceAddLocaleResponse;

    return this.apiClient.callApi(
      '/v1/locales/add/{locale.lang}/{locale.name_spaces}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * AddLocale 创建字段
   * @param {String} localeLang 
   * @param {String} localeNameSpaces 
   * @param {Object} body 
   * @param {Object} opts Optional parameters
   * @param {String} opts.localeId 
   * @param {String} opts.localeKey 
   * @param {String} opts.localeValue 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceAddLocaleResponse}
   */
  localesAddLocale(localeLang, localeNameSpaces, body, opts) {
    return this.localesAddLocaleWithHttpInfo(localeLang, localeNameSpaces, body, opts)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * AddLocaleLanguage ...
   * @param {String} name 
   * @param {module:model/ServiceAddLocaleLanguageRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceAddLocaleLanguageResponse} and HTTP response
   */
  localesAddLocaleLanguageWithHttpInfo(name, body) {
    let postBody = body;

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling localesAddLocaleLanguage");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling localesAddLocaleLanguage");
    }


    let pathParams = {
      'name': name
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServiceAddLocaleLanguageResponse;

    return this.apiClient.callApi(
      '/v1/locales/add_language/{name}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * AddLocaleLanguage ...
   * @param {String} name 
   * @param {module:model/ServiceAddLocaleLanguageRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceAddLocaleLanguageResponse}
   */
  localesAddLocaleLanguage(name, body) {
    return this.localesAddLocaleLanguageWithHttpInfo(name, body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * AddLocaleNameSpace ...
   * @param {String} name 
   * @param {module:model/ServiceAddLocaleNameSpaceRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceAddLocaleNameSpaceResponse} and HTTP response
   */
  localesAddLocaleNameSpaceWithHttpInfo(name, body) {
    let postBody = body;

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling localesAddLocaleNameSpace");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling localesAddLocaleNameSpace");
    }


    let pathParams = {
      'name': name
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServiceAddLocaleNameSpaceResponse;

    return this.apiClient.callApi(
      '/v1/locales/add_name_space/{name}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * AddLocaleNameSpace ...
   * @param {String} name 
   * @param {module:model/ServiceAddLocaleNameSpaceRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceAddLocaleNameSpaceResponse}
   */
  localesAddLocaleNameSpace(name, body) {
    return this.localesAddLocaleNameSpaceWithHttpInfo(name, body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * ListLocaleLanguages ListLocalesLanguage
   * @param {module:model/ServiceListLocaleLanguagesRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceListLocaleLanguagesResponse} and HTTP response
   */
  localesListLocaleLanguagesWithHttpInfo(body) {
    let postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling localesListLocaleLanguages");
    }


    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServiceListLocaleLanguagesResponse;

    return this.apiClient.callApi(
      '/api/v1/locales/list_language', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * ListLocaleLanguages ListLocalesLanguage
   * @param {module:model/ServiceListLocaleLanguagesRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceListLocaleLanguagesResponse}
   */
  localesListLocaleLanguages(body) {
    return this.localesListLocaleLanguagesWithHttpInfo(body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * ListLocales 查找Locales
   * @param {module:model/ServiceListLocalesRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceListLocalesResponse} and HTTP response
   */
  localesListLocalesWithHttpInfo(body) {
    let postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling localesListLocales");
    }


    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServiceListLocalesResponse;

    return this.apiClient.callApi(
      '/v1/locales/list', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * ListLocales 查找Locales
   * @param {module:model/ServiceListLocalesRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceListLocalesResponse}
   */
  localesListLocales(body) {
    return this.localesListLocalesWithHttpInfo(body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * ListLocalesNameSpaces ListLocalesNameSpace
   * @param {module:model/ServiceListLocaleNameSpacesRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceListLocaleNameSpacesResponse} and HTTP response
   */
  localesListLocalesNameSpacesWithHttpInfo(body) {
    let postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling localesListLocalesNameSpaces");
    }


    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServiceListLocaleNameSpacesResponse;

    return this.apiClient.callApi(
      '/v1/locales/list_name_space', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * ListLocalesNameSpaces ListLocalesNameSpace
   * @param {module:model/ServiceListLocaleNameSpacesRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceListLocaleNameSpacesResponse}
   */
  localesListLocalesNameSpaces(body) {
    return this.localesListLocalesNameSpacesWithHttpInfo(body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * PublishLocales PublishLocales
   * @param {module:model/ServicePublishLocalesRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServicePublishLocalesResponse} and HTTP response
   */
  localesPublishLocalesWithHttpInfo(body) {
    let postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling localesPublishLocales");
    }


    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServicePublishLocalesResponse;

    return this.apiClient.callApi(
      '/v1/locales/publish', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * PublishLocales PublishLocales
   * @param {module:model/ServicePublishLocalesRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServicePublishLocalesResponse}
   */
  localesPublishLocales(body) {
    return this.localesPublishLocalesWithHttpInfo(body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * RemoveLocale 删除字段
   * @param {String} id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceRemoveLocaleResponse} and HTTP response
   */
  localesRemoveLocaleWithHttpInfo(id) {
    let postBody = null;

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling localesRemoveLocale");
    }


    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServiceRemoveLocaleResponse;

    return this.apiClient.callApi(
      '/v1/locales/remove/{id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * RemoveLocale 删除字段
   * @param {String} id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceRemoveLocaleResponse}
   */
  localesRemoveLocale(id) {
    return this.localesRemoveLocaleWithHttpInfo(id)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * RemoveLocaleLanguage RemoveLocaleLanguage
   * @param {String} id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceRemoveLocaleNameSpaceResponse} and HTTP response
   */
  localesRemoveLocaleLanguageWithHttpInfo(id) {
    let postBody = null;

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling localesRemoveLocaleLanguage");
    }


    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServiceRemoveLocaleNameSpaceResponse;

    return this.apiClient.callApi(
      '/v1/locales/remove_language/{id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * RemoveLocaleLanguage RemoveLocaleLanguage
   * @param {String} id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceRemoveLocaleNameSpaceResponse}
   */
  localesRemoveLocaleLanguage(id) {
    return this.localesRemoveLocaleLanguageWithHttpInfo(id)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * RemoveLocaleNameSpace RemoveLocaleNameSpace
   * @param {String} id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceRemoveLocaleNameSpaceResponse} and HTTP response
   */
  localesRemoveLocaleNameSpaceWithHttpInfo(id) {
    let postBody = null;

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling localesRemoveLocaleNameSpace");
    }


    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServiceRemoveLocaleNameSpaceResponse;

    return this.apiClient.callApi(
      '/v1/locales/remove_name_space/{id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * RemoveLocaleNameSpace RemoveLocaleNameSpace
   * @param {String} id 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceRemoveLocaleNameSpaceResponse}
   */
  localesRemoveLocaleNameSpace(id) {
    return this.localesRemoveLocaleNameSpaceWithHttpInfo(id)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


  /**
   * UpdateLocale 删除字段
   * @param {String} id 
   * @param {module:model/ServiceUpdateLocaleRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceUpdateLocaleResponse} and HTTP response
   */
  localesUpdateLocaleWithHttpInfo(id, body) {
    let postBody = body;

    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling localesUpdateLocale");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling localesUpdateLocale");
    }


    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ServiceUpdateLocaleResponse;

    return this.apiClient.callApi(
      '/v1/locales/update/{id}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }

  /**
   * UpdateLocale 删除字段
   * @param {String} id 
   * @param {module:model/ServiceUpdateLocaleRequest} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceUpdateLocaleResponse}
   */
  localesUpdateLocale(id, body) {
    return this.localesUpdateLocaleWithHttpInfo(id, body)
      .then(function (response_and_data) {
        return response_and_data.data;
      });
  }


}