/*
 * types/services/dr2am.tasks.roles.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbRole} from './PbRole';
import {ProtobufFieldMask} from './ProtobufFieldMask';

/**
 * The ServiceUpdateRoleRequest model module.
 * @module model/ServiceUpdateRoleRequest
 * @version version not set
 */
export class ServiceUpdateRoleRequest {
  /**
   * Constructs a new <code>ServiceUpdateRoleRequest</code>.
   * @alias module:model/ServiceUpdateRoleRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceUpdateRoleRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceUpdateRoleRequest} obj Optional instance to populate.
   * @return {module:model/ServiceUpdateRoleRequest} The populated <code>ServiceUpdateRoleRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceUpdateRoleRequest();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('role'))
        obj.role = PbRole.constructFromObject(data['role']);
      if (data.hasOwnProperty('update_mask'))
        obj.updateMask = ProtobufFieldMask.constructFromObject(data['update_mask']);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ServiceUpdateRoleRequest.prototype.id = undefined;

/**
 * @member {module:model/PbRole} role
 */
ServiceUpdateRoleRequest.prototype.role = undefined;

/**
 * @member {module:model/ProtobufFieldMask} updateMask
 */
ServiceUpdateRoleRequest.prototype.updateMask = undefined;


