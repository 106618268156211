/*
 * types/services/collection.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {CollectionCollectionBase} from './CollectionCollectionBase';
import {CollectionResponseResponseState} from './CollectionResponseResponseState';

/**
 * The CollectionCreateCollectionResponse model module.
 * @module model/CollectionCreateCollectionResponse
 * @version version not set
 */
export class CollectionCreateCollectionResponse {
  /**
   * Constructs a new <code>CollectionCreateCollectionResponse</code>.
   * @alias module:model/CollectionCreateCollectionResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CollectionCreateCollectionResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CollectionCreateCollectionResponse} obj Optional instance to populate.
   * @return {module:model/CollectionCreateCollectionResponse} The populated <code>CollectionCreateCollectionResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CollectionCreateCollectionResponse();
      if (data.hasOwnProperty('result'))
        obj.result = CollectionCollectionBase.constructFromObject(data['result']);
      if (data.hasOwnProperty('state'))
        obj.state = CollectionResponseResponseState.constructFromObject(data['state']);
    }
    return obj;
  }
}

/**
 * @member {module:model/CollectionCollectionBase} result
 */
CollectionCreateCollectionResponse.prototype.result = undefined;

/**
 * @member {module:model/CollectionResponseResponseState} state
 */
CollectionCreateCollectionResponse.prototype.state = undefined;


