import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import { InternalApplicationSetup, InternalApplicationStart } from '../application';
import { InternalChromeStart } from '../chrome';
import { Provider } from 'react-redux';
import "./default.css";
import { InternalCoreSetup } from '../core_system';
import Layout from '../../../components/layout';
// import { ApiSetp } from '../api';


interface StartDeps {
    application: InternalApplicationStart;
    chrome: InternalChromeStart;
    // injectedMetadata: InjectedMetadataStart;
    // overlays: OverlayStart;
    targetDomElement: HTMLDivElement;
    api?: any;
}

interface SetupDeps {
    application: InternalApplicationSetup;
    api?: any;
    plugins?: any;
}

// export const RenderingStart = null;
/**
 * Renders all Core UI in a single React tree.
 *
 * @internalRemarks Currently this only renders Chrome UI. Notifications and
 * Overlays UI should be moved here as well.
 *
 * @returns a DOM element for the legacy platform to render into.
 *
 * @internal
 */
export class RenderingService {
    // private security?: AuthenticationServiceSetup
    // private api?: ApiSetp
    setup(core: SetupDeps) {
        // console.log(core.plugins["security"])
        // this.security = core.plugins.contracts.get("security")
        // this.api = core.api

    }
    start({
        application,
        chrome,
        targetDomElement,
        ...props
    }: StartDeps): RenderingStart {
        const appUI = application.getComponent();
        const chromeUI = chrome.getHeaderComponent();
        const msgUI = chrome.getToastComponent();
        const modalUI = chrome.getModalComponent();
        const store = application.getStore();
        const history = application.getHistory();


        const _props = {
            history: history,
            ...props
        }
        ReactDOM.render(
            <Provider store={store}>
                <Layout chrome={chrome} {..._props}>
                    {appUI}
                </Layout>
            </Provider >,
            targetDomElement)

        return {

            // When in legacy mode, return legacy div, otherwise undefined.
            legacyTargetDomElement: undefined,
        };
    }

};




export interface RenderingStart {
    legacyTargetDomElement?: HTMLDivElement;
}

export const RenderingStart2: RenderingStart = {
    legacyTargetDomElement: undefined
}