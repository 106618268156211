/*
 * types/services/dr2am.tasks.orders.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {RuntimeError} from '../model/RuntimeError';
import {ServiceCreateOrdersRequest} from '../model/ServiceCreateOrdersRequest';
import {ServiceCreateOrdersResponse} from '../model/ServiceCreateOrdersResponse';
import {ServiceDeleteOrdersResponse} from '../model/ServiceDeleteOrdersResponse';
import {ServiceGetOrdersWithUserResponse} from '../model/ServiceGetOrdersWithUserResponse';
import {ServiceListOrderssResponse} from '../model/ServiceListOrderssResponse';
import {ServiceUpdateOrdersRequest} from '../model/ServiceUpdateOrdersRequest';
import {ServiceUpdateOrdersResponse} from '../model/ServiceUpdateOrdersResponse';

/**
* OrdersService service.
* @module api/OrdersServiceApi
* @version version not set
*/
export class OrdersServiceApi {

    /**
    * Constructs a new OrdersServiceApi. 
    * @alias module:api/OrdersServiceApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create  Orders
     * @param {module:model/ServiceCreateOrdersRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceCreateOrdersResponse} and HTTP response
     */
    ordersServiceCreateWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling ordersServiceCreate");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceCreateOrdersResponse;

      return this.apiClient.callApi(
        '/v1/orders:create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create  Orders
     * @param {module:model/ServiceCreateOrdersRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceCreateOrdersResponse}
     */
    ordersServiceCreate(body) {
      return this.ordersServiceCreateWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete  Orders
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceDeleteOrdersResponse} and HTTP response
     */
    ordersServiceDeleteWithHttpInfo(id) {
      let postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling ordersServiceDelete");
      }


      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceDeleteOrdersResponse;

      return this.apiClient.callApi(
        '/v1/orders/{id}:delete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete  Orders
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceDeleteOrdersResponse}
     */
    ordersServiceDelete(id) {
      return this.ordersServiceDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * GetWithUser Orders with UserId
     * @param {String} userId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceGetOrdersWithUserResponse} and HTTP response
     */
    ordersServiceGetWithUserWithHttpInfo(userId) {
      let postBody = null;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling ordersServiceGetWithUser");
      }


      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceGetOrdersWithUserResponse;

      return this.apiClient.callApi(
        '/v1/users/{user_id}/order', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * GetWithUser Orders with UserId
     * @param {String} userId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceGetOrdersWithUserResponse}
     */
    ordersServiceGetWithUser(userId) {
      return this.ordersServiceGetWithUserWithHttpInfo(userId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Orderss
     * @param {Object} opts Optional parameters
     * @param {String} opts.action 
     * @param {Number} opts.pageSize 
     * @param {String} opts.pageToken 
     * @param {String} opts.query 
     * @param {String} opts.orderBy 
     * @param {Number} opts.paginateOffset 
     * @param {Number} opts.paginateLimit 
     * @param {String} opts.paginateNextPageToken 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceListOrderssResponse} and HTTP response
     */
    ordersServiceListWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'action': opts['action'],
        'page_size': opts['pageSize'],
        'page_token': opts['pageToken'],
        'query': opts['query'],
        'order_by': opts['orderBy'],
        'paginate.offset': opts['paginateOffset'],
        'paginate.limit': opts['paginateLimit'],
        'paginate.next_page_token': opts['paginateNextPageToken']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceListOrderssResponse;

      return this.apiClient.callApi(
        '/v1/orders:list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * List Orderss
     * @param {Object} opts Optional parameters
     * @param {String} opts.action 
     * @param {Number} opts.pageSize 
     * @param {String} opts.pageToken 
     * @param {String} opts.query 
     * @param {String} opts.orderBy 
     * @param {Number} opts.paginateOffset 
     * @param {Number} opts.paginateLimit 
     * @param {String} opts.paginateNextPageToken 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceListOrderssResponse}
     */
    ordersServiceList(opts) {
      return this.ordersServiceListWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update  Orders
     * @param {module:model/ServiceUpdateOrdersRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceUpdateOrdersResponse} and HTTP response
     */
    ordersServiceUpdateWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling ordersServiceUpdate");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceUpdateOrdersResponse;

      return this.apiClient.callApi(
        '/v1/orders:update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update  Orders
     * @param {module:model/ServiceUpdateOrdersRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceUpdateOrdersResponse}
     */
    ordersServiceUpdate(body) {
      return this.ordersServiceUpdateWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
