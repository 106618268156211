/*
 * types/services/dr2am.tasks.preferences.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The PbPreference model module.
 * @module model/PbPreference
 * @version version not set
 */
export class PbPreference {
  /**
   * Constructs a new <code>PbPreference</code>.
   * @alias module:model/PbPreference
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PbPreference</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PbPreference} obj Optional instance to populate.
   * @return {module:model/PbPreference} The populated <code>PbPreference</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PbPreference();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('gid'))
        obj.gid = ApiClient.convertToType(data['gid'], 'String');
      if (data.hasOwnProperty('user_id'))
        obj.userId = ApiClient.convertToType(data['user_id'], 'String');
      if (data.hasOwnProperty('user_gid'))
        obj.userGid = ApiClient.convertToType(data['user_gid'], 'String');
      if (data.hasOwnProperty('category'))
        obj.category = ApiClient.convertToType(data['category'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('value'))
        obj.value = ApiClient.convertToType(data['value'], 'String');
      if (data.hasOwnProperty('create_at'))
        obj.createAt = ApiClient.convertToType(data['create_at'], 'Date');
      if (data.hasOwnProperty('modify_at'))
        obj.modifyAt = ApiClient.convertToType(data['modify_at'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
PbPreference.prototype.id = undefined;

/**
 * @member {String} gid
 */
PbPreference.prototype.gid = undefined;

/**
 * @member {String} userId
 */
PbPreference.prototype.userId = undefined;

/**
 * @member {String} userGid
 */
PbPreference.prototype.userGid = undefined;

/**
 * @member {String} category
 */
PbPreference.prototype.category = undefined;

/**
 * @member {String} name
 */
PbPreference.prototype.name = undefined;

/**
 * @member {String} value
 */
PbPreference.prototype.value = undefined;

/**
 * @member {Date} createAt
 */
PbPreference.prototype.createAt = undefined;

/**
 * @member {Date} modifyAt
 */
PbPreference.prototype.modifyAt = undefined;


