import {
    CollectionApi
} from "./collection";
import {
    ApiClient
} from "./ApiClient"
import {
    LocalesApi
} from "./locales";

const client = new ApiClient()
const CollectionSDK = (c) => {
    c = c || client
    return new CollectionApi(c)
}
const LocalesSDK = (c) => {
    c = c || client
    return new LocalesApi(c)
}
export {
    ApiClient,
    CollectionSDK,
    LocalesSDK
}