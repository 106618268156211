


class Plugin {
    constructor() {
    }

    setup = ({
        ...core
    }, {
        ...other
    }) => {
        const { security } = other
        core.application.register({
            id: 'analysis',
            title: 'analysis',
            mount: async (context: any, params: any) => {
                const {
                    renderApp
                } = await import('./application');
                return renderApp(context, {
                    ...params,
                    authc: security.authc,
                    coreStore: core.coreStore,
                });
            }
        });
        return this
    }
    start = (core: any) => {
        const { chrome } = core
        chrome.addNavs("analysis", {
            display: "主页",
            href: "/app/home",
            icon: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M8.156 1.835a.25.25 0 00-.312 0l-5.25 4.2a.25.25 0 00-.094.196v7.019c0 .138.112.25.25.25H5.5V8.25a.75.75 0 01.75-.75h3.5a.75.75 0 01.75.75v5.25h2.75a.25.25 0 00.25-.25V6.23a.25.25 0 00-.094-.195l-5.25-4.2zM6.906.664a1.75 1.75 0 012.187 0l5.25 4.2c.415.332.657.835.657 1.367v7.019A1.75 1.75 0 0113.25 15h-3.5a.75.75 0 01-.75-.75V9H7v5.25a.75.75 0 01-.75.75h-3.5A1.75 1.75 0 011 13.25V6.23c0-.531.242-1.034.657-1.366l5.25-4.2h-.001z"></path></svg>)
        })

    }
    stop = () => {

    }

}



const registerPlugin = (window: any) => {
    const Home = () => new Plugin();
    window.registerPlugin("analysis", {
        id: "analysis",
        plugin: {
            id: "analysis",
            configPath: "",
            instance: Home,
            requiredPlugins: ["security"]
        },
    })
}

// export const Home = () => new Plugin();
const home = {
    registerPlugin: registerPlugin
}
export default home