/*
 * types/services/dr2am.tasks.orders.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * Enum class ProtobufNullValue.
 * @enum {String}
 * @readonly
 */
const ProtobufNullValue = {
  /**
   * value: "NULL_VALUE"
   * @const
   */
  NULL_VALUE: "NULL_VALUE",

  /**
   * Returns a <code>ProtobufNullValue</code> enum value from a JavaScript object name.
   * @param {Object} data The plain JavaScript object containing the name of the enum value.
   * @return {module:model/ProtobufNullValue} The enum <code>ProtobufNullValue</code> value.
   */
  constructFromObject: function(object) {
    return object;
  }
};

export {ProtobufNullValue};
