import React, { Fragment } from "react"
import { Header } from "./components/header"

import {
    EuiBreadcrumb,
} from '@elastic/eui';
import { Dispatch, Store } from "redux";
// import { pushToBreadcrumbs } from "../../../actions/breadcrumbs";
import { Provider } from "react-redux";
import { SideBarSection } from "../../../components/sidebar/section";
import { EventEmitter } from "./EventEmitter";
// import { setSubNavs } from "../../../actions/navs";
// import MSG from "../../../components/toast";
// import GlobalModal, { addGlobalModal, removeGlobalModal } from "../../../components/global_modal";
/** @public */
export type ChromeBreadcrumb = EuiBreadcrumb;

/**
 * ChromeStart allows plugins to customize the global chrome header UI and
 * enrich the UX with additional information about the current location of the
 * browser.
 *
 * @remarks
 * While ChromeStart exposes many APIs, they should be used sparingly and the
 * developer should understand how they affect other plugins and applications.
 *
 * @example
 * How to add a recently accessed item to the sidebar:
 * ```ts
 * core.chrome.recentlyAccessed.add('/app/map/1234', 'Map 1234', '1234');
 * ```
 *
 * @example
 * How to set the help dropdown extension:
 * ```tsx
 * core.chrome.setHelpExtension(elem => {
 *   ReactDOM.render(<MyHelpComponent />, elem);
 *   return () => ReactDOM.unmountComponentAtNode(elem);
 * });
 * ```
 *
 * @public
 */
export interface ChromeStart {
    /**
   * Get an observable of the current list of breadcrumbs
   */
    getBreadcrumbs(): ChromeBreadcrumb[];

    /**
     * Override the current set of breadcrumbs
     */
    setBreadcrumbs(newBreadcrumbs: ChromeBreadcrumb[]): void;

    setSubNavs(navs: any): void;
    addNavs(id: string, options: any): void;
    getSideNavs(): SideBarSection;

    setGlobalModal(modal: any): any;
    // events(): EventEmitter;
    on(name: string | number, callback: any): void;
    dispatch(name: string | number, event: any): void;

}

/** @internal */
export interface InternalChromeStart extends ChromeStart {
    /**
   * Used only by MountingService to render the header UI
   * @internal
   */
    getHeaderComponent(): JSX.Element;
    getToastComponent(): JSX.Element;
    getModalComponent(): JSX.Element;
}



/** @internal */
export class ChromeService {
    // private readonly dispatch:Dispatch;
    // private readonly store:Store;
    private readonly SideBarSections: SideBarSection;
    constructor() {
        this.SideBarSections = new SideBarSection("root")
    }
    public async start({
        dispatch,
        store,
        history,
    }: { dispatch: Dispatch, store: Store, history: any }): Promise<InternalChromeStart> {
        const events = new EventEmitter()
        return {
            getBreadcrumbs: () => {
                return [{
                    text: (<b>Home</b>)
                } as ChromeBreadcrumb]
            },
            setBreadcrumbs: (newBreadcrumbs) => {
                // pushToBreadcrumbs(newBreadcrumbs)(dispatch)
            },
            setSubNavs: (navs: any) => {
                // setSubNavs(navs)(dispatch)
            },
            addNavs: (id: string, options: any) => {
                this.SideBarSections.register(id, options)
                return
            },
            getSideNavs: () => {
                return this.SideBarSections
            },

            getHeaderComponent: () => {
                return (
                    <Fragment>
                        <Provider store={store}>
                            {/* <Header history={history}
                            /> */}


                        </Provider>
                    </Fragment>
                )
            },
            getToastComponent: () => {
                return (
                    <Fragment>
                        <Provider store={store}>
                            {/* <MSG store={store}
                            // breadcrumbs={this.props.breadcrumbs}
                            /> */}


                        </Provider>
                    </Fragment>
                )
            },
            getModalComponent: () => {
                return (
                    <Fragment>
                        <Provider store={store}>
                            {/* <GlobalModal store={store} /> */}
                        </Provider>
                    </Fragment>
                )
            },
            setGlobalModal: (body: any): any => {
                const action = {
                    open: () => { },
                    close: () => {
                        // removeGlobalModal() 
                    },
                }
                if (body.props && !body.props.close) {
                    body.props.close = action.close
                }
                // addGlobalModal(body)

                return action
            },
            // on: (name: string | number, callback: any) => events.on,
            // dispatch: (name: string | number, event: any) => { },
            on: events.on.bind(events),
            dispatch: events.dispatch.bind(events),

        }
    }
    public stop() {
    }
}