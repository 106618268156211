/*
 * types/services/collection.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The CollectionResponseCount model module.
 * @module model/CollectionResponseCount
 * @version version not set
 */
export class CollectionResponseCount {
  /**
   * Constructs a new <code>CollectionResponseCount</code>.
   * @alias module:model/CollectionResponseCount
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CollectionResponseCount</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CollectionResponseCount} obj Optional instance to populate.
   * @return {module:model/CollectionResponseCount} The populated <code>CollectionResponseCount</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CollectionResponseCount();
      if (data.hasOwnProperty('Num'))
        obj.num = ApiClient.convertToType(data['Num'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} num
 */
CollectionResponseCount.prototype.num = undefined;


