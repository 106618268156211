import qs from 'query-string';
class Configure {
    private storage: Storage;
    private window: Window;
    private prefix = 'CONFIGURATOR_SERVICE_';

    constructor(storage?: Storage, w?: Window) {
        if (storage) {
            this.storage = storage
        } else if (typeof localStorage !== 'undefined') {
            this.storage = localStorage
        } else {
            throw new Error('Unable to set a local storage')
        }

        if (w) {
            this.window = w
        } else if (typeof window !== 'undefined') {
            this.window = window
        } else {
            throw new Error('Unable to set window')
        }
    }

    public get = (key: string, queryKey?: string, fallback?: any) => {
        queryKey = queryKey || key
        const query = qs.parse(this.window.location.search)[queryKey] as string

        const item = this.storage.getItem(key)
        if (!item) {
            if (query) {
                this.storage.setItem(key, query)
                return query
            }

            return fallback
        }

        if (query) {
            this.storage.setItem(key, query)
            return query
        }

        return item
    };

    public set = (key: string, value?: string) => {
        this.storage.setItem(key, value || '')
    }

    public remove = (key: string) => {
        return this.storage.removeItem(key)
    }

    /**
     * getApiURI
:string    */
    public getApiURI(key: string): string {
        if (key && key !== "") {
            const v = configure.get(key);
            if (v && v != "") {
                return v
            }
        }
        const v = configure.get("api_url")
        if (v && v != "") {
            return v
        }
        return window.location.origin
    }
}
const configure = new Configure()
export default configure