import './index.css';
import reportWebVitals from './reportWebVitals';
import _ from 'lodash';
import {
  CoreSystem
} from './core/public';

import i18n from './i18n';

import { createStore } from "redux"

import './register';
import security from './security';
// import home from './views/home';
import security_settings from './views/security';
import knowledge from './views/knowledge';
// import nada from './views/nada';
// import named from './views/named';
import analysis from './views/analysis';
import collection from './views/collection';
import locales from './views/locales';
import createCollection from './views/actions/createCollection';
import './sentry';
import './index.css'
import { ApiClient, LocalesSDK } from './sdk';
// import { ApiClient, CollectionSDK } from './sdk';

import './grid.scss'
const apiClient = new ApiClient()
// window.localStorage.getItem("searchAPI") || 
// apiClient.basePath = "http://localhost:82"
const api = {
  locales: LocalesSDK(apiClient)
}

const bootstrap = async () => {
  // security.registerPlugin(window)
  security_settings.registerPlugin(window)
  // createCollection.registerPlugin(window)
  // createCollection.registerPlugin(window)
  // home.registerPlugin(window)
  // collection.registerPlugin(window)
  knowledge.registerPlugin(window)
  locales.registerPlugin(window)
  analysis.registerPlugin(window)
  // nada.registerPlugin(window)
  // named.registerPlugin(window)
  const plugins = _.map(window.plugins, (plugin, _) => plugin)
  const coreSystem = new CoreSystem({
    rootDomElement: document.getElementById('root'),
    // dispatch: store.dispatch,
    i18n: i18n,
    plugins: plugins,
    store: createStore(() => { }),
    api: () => {
      return api
    }
  })
  coreSystem.coreStore.update("wfConfig", {
    // apiURL: new URL("https://oversea.wanfangdata.com.cn/api"),
    // apiURL: new URL("https://accounts.dr2am.cn/api"),
    apiURL: new URL("https://globe.wanfangdata.com.hk/api"),
    // apiURL: new URL("https://localhost/api"),
  })
  await coreSystem.setup()
  await coreSystem.start()
}
bootstrap()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); 