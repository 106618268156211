import _ from "lodash"
import { any } from "prop-types"
import React, { forwardRef, useRef, useState } from "react"
import { generateId } from "../utils"


const YearAndMonthInput = forwardRef((props: any, ref: any) => {
    const { id, type, displayText, helpText, maxLength, options } = props
    function handleYearChange(e: any) {
        inputRef.current.value = `${e.target.value}-${monthRef.current.value}`
    }
    function handleMonthChange(e: any) {
        inputRef.current.value = `${yearRef.current.value}-${e.target.value}`
    }
    const yearRef = useRef<any>()
    const monthRef = useRef<any>()
    const inputRef = useRef<any>()
    return <div id={id}>
        <input type={type} className="form-control d-none"
            placeholder=""
            maxLength={maxLength}
            spellCheck={false}
            autoCapitalize="off"
            autoComplete="off"
            // ref={mergeRefs(ref, inputRef)}
            ref={(node) => {
                const f = ref(node)
                inputRef.current = node;
            }}
        />

        <select className="form-select" aria-label="Preference" onChange={handleYearChange}
            ref={yearRef}
        >
            <option>未指定</option>
            <option>2022</option>
            <option>2021</option>
            <option>2020</option>
            <option>2019</option>
            <option>2018</option>
        </select >
        <span className="mx-1">年</span>
        <select className="form-select " aria-label="Preference" onChange={handleMonthChange}
            ref={monthRef}>
            <option>未指定</option>
            <option>01</option>
            <option>02</option>
            <option>03</option>
            <option>04</option>
            <option>05</option>
        </select >
        <span className="mx-1">月</span>
    </div>
})

const KeyValuesInput = forwardRef((props: any, ref: any) => {
    const { onChange, defaultValue } = props
    const thisRef = useRef<any>({ value: defaultValue })
    const f = ref(thisRef.current)
    const [keyValues, setKeyValues] = useState<any>(thisRef.current.value)

    function handleAdd(e: any) {

    }
    function handleOnChange(data: any, mode: string) {
        switch (mode) {
            case "create":
            case "update":
                thisRef.current.value = { ...thisRef.current.value, [data.key]: data.value }
                setKeyValues({ ...thisRef.current.value })
                break;
            case "delete":
                delete thisRef.current["value"][data.key]
                setKeyValues({ ...thisRef.current["value"] })
                break;
            default:
                break;
        }
        onChange && onChange()
    }
    return <div>
        {_.map(keyValues, (value: any, key: any) => {
            return <KeyValueInput className={"my-1"} key={generateId()} data={{ key, value }} onChange={handleOnChange}></KeyValueInput>
        })}
        <hr />
        <KeyValueInput className={"my-1"} key={"create"} onChange={handleOnChange}></KeyValueInput>
    </div>
})

const KeyValueInput = forwardRef((props: any, ref: any) => {
    const { data, onChange, className } = props
    const { key, value } = data || {}
    const [keyValues, setKeyValues] = useState({})

    function isCreate() {
        return !key && !value
    }

    function canSave() {
        if (!keyInputRef.current || !valueInputRef.current) {
            return false
        }
        return !keyInputRef.current.value && !valueInputRef.current.value
    }
    function handleAdd(e: any) {
        if (onChange) {
            onChange({ key: keyInputRef.current.value, value: valueInputRef.current.value }, "create")
            keyInputRef.current.value = ""
            valueInputRef.current.value = ""
        }
    }
    function handleDelete(e: any) {
        if (onChange) {
            onChange({ key: keyInputRef.current.value, value: valueInputRef.current.value }, "delete")
        }
    }
    const keyInputRef = useRef<any>()
    const valueInputRef = useRef<any>()
    return <div className={className}>
        <div className="d-flex ">
            <input ref={keyInputRef} className="form-control" type={"text"} placeholder="key" defaultValue={key} ></input>
            <input ref={valueInputRef} className="form-control" type={"text"} placeholder="value" defaultValue={value}></input>
            {
                isCreate()
                    ? <button className="btn btn-sm" type={"button"} placeholder="value" onClick={handleAdd}  >添加</button>
                    : <button className="btn btn-sm btn-danger" type={"button"} placeholder="value" onClick={handleDelete}  >删除</button>
            }
        </div>

    </div>
})

export const Input = forwardRef((props: any, ref: any) => {
    const { id, type, value, displayText, onChange, helpText, maxLength, options, placeholder, disabled } = props
    function handleOnChange(e: any) {
        if (onChange) {
            onChange(e)
        }
    }
    function handleSelectOnChange(options: any) {
        return function (e: any) {
            if (onChange) {
                onChange(e.target.selectedIndex)
            }
        }
    }

    function renderInput() {
        switch (type) {
            case "select":
                return <select onChange={handleSelectOnChange(options)} id={id} ref={ref} className="form-select" aria-label="Preference" aria-disabled={disabled} disabled={disabled} >
                    <option>未指定</option>
                    {_.map(options, option => {
                        return <option selected={option.value == value} value={option.value}>{option.label || option.value}</option>
                    })}

                </select >
                break;
            case "year_month":
                return <YearAndMonthInput id={id} ref={ref}></YearAndMonthInput>
            case "textarea":
                return <textarea id={id} className="form-control"
                    placeholder={placeholder}
                    maxLength={maxLength}
                    spellCheck={false}
                    autoCapitalize="off"
                    autoComplete="off"
                    defaultValue={value}
                    ref={ref}
                ></textarea>
            case "keyvalues":
                return <KeyValuesInput id={id} ref={ref} defaultValue={value} ></KeyValuesInput>
            default:
                return <input id={id} type={type} className="form-control"
                    placeholder={placeholder}
                    maxLength={maxLength}
                    spellCheck={false}
                    autoCapitalize="off"
                    autoComplete="off"
                    defaultValue={value}
                    ref={ref}
                    onChange={onChange}
                />
        }

    }
    return <><h5 id={`${id}_label`}>
        <strong><span>{displayText}</span></strong>
    </h5>
        <div className="form-group">
            {renderInput()}

            <span id={`valid_${id}`} className="help-block">
                <span>{helpText}</span>
            </span>
        </div></>
})
