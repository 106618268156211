import { PluginOpaqueId, DiscoveredPlugin } from "../../types/plugins";
import { EnvironmentMode, PackageInfo } from "../../utils/package";
import { CoreContext } from "../core_system";
import { PluginWrapper } from "./plugins";
import { CoreSetup, CoreStart } from "..";
import { PluginsServiceSetupDeps, PluginsServiceStartDeps } from "./plugins_service";
import { omit } from "lodash";

/**
 * The available core services passed to a `PluginInitializer`
 *
 * @public
 */
export interface PluginInitializerContext {
    /**
     * A symbol used to identify this plugin in the system. Needed when registering handlers or context providers.
     */
    readonly opaqueId: PluginOpaqueId;
    readonly env: {
        mode: Readonly<EnvironmentMode>;
        packageInfo: Readonly<PackageInfo>;
    };
}


/**
* Provides a plugin-specific context passed to the plugin's construtor. This is currently
* empty but should provide static services in the future, such as config and logging.
*
* @param coreContext
* @param pluginManinfest
* @internal
*/
export function createPluginInitializerContext(
    coreContext: CoreContext,
    opaqueId: PluginOpaqueId,
    pluginManifest: DiscoveredPlugin
): PluginInitializerContext {
    return {
        opaqueId,
        env: coreContext.env,
    };
}


/**
* Provides a plugin-specific context passed to the plugin's `setup` lifecycle event. Currently
* this returns a shallow copy the service setup contracts, but in the future could provide
* plugin-scoped versions of the service.
*
* @param coreContext
* @param deps
* @param plugin
* @internal
*/
export function createPluginSetupContext<
    TSetup,
    TStart,
    TPluginsSetup extends object,
    TPluginsStart extends object
>(
    coreContext: CoreContext,
    deps: PluginsServiceSetupDeps,
    plugin: PluginWrapper<TSetup, TStart, TPluginsSetup, TPluginsStart>
): CoreSetup {
    return {
        application: {
            register: app => deps.application.register(plugin.opaqueId, app),
            registerRoute: deps.application.registerRoute,
            registerApp: app => deps.application.registerRoute(app.id, app),
            registerMountContext: (contextName, provider) => deps.application.registerMountContext(plugin.opaqueId, contextName, provider),
        },
        context: deps.context,
        // fatalErrors: deps.fatalErrors,
        http: deps.http,
        // notifications: deps.notifications,
        // uiSettings: deps.uiSettings,
        // injectedMetadata: {
        //     getInjectedVar: deps.injectedMetadata.getInjectedVar,
        // },
        getServiceStart: deps.getServiceStart,
        api: deps.api,
        i18n: deps.i18n,
        coreStore: deps.coreStore,
    };
}



/**
 * Provides a plugin-specific context passed to the plugin's `start` lifecycle event. Currently
 * this returns a shallow copy the service start contracts, but in the future could provide
 * plugin-scoped versions of the service.
 *
 * @param coreContext
 * @param deps
 * @param plugin
 * @internal
 */
export function createPluginStartContext<
    TSetup,
    TStart,
    TPluginsSetup extends object,
    TPluginsStart extends object
>(
    coreContext: CoreContext,
    deps: PluginsServiceStartDeps,
    plugin: PluginWrapper<TSetup, TStart, TPluginsSetup, TPluginsStart>
): CoreStart {
    return {
        application: {
            capabilities: null,
            registerMountContext: (contextName, provider) =>
                deps.application.registerMountContext(plugin.opaqueId, contextName, provider),
            getRoute: deps.application.getRoute
        },
        chrome: omit(deps.chrome, 'getComponent'),

        // docLinks: deps.docLinks,
        // http: deps.http,
        // i18n: deps.i18n,
        // notifications: deps.notifications,
        // overlays: deps.overlays,
        // uiSettings: deps.uiSettings,
        // savedObjects: deps.savedObjects,
        // injectedMetadata: {
        //   getInjectedVar: deps.injectedMetadata.getInjectedVar,
        // },
    };
}