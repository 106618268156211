import React from "react";
import { matchPath, RouteComponentProps } from "react-router-dom";
import { AppMounter, AppUnmount } from '../types';
import { AppNotFound } from "./app_not_found";

interface Props extends RouteComponentProps<{ appId: string, subAppId: string }> {
    apps: ReadonlyMap<string, AppMounter>;
    // legacyApps: ReadonlyMap<string, LegacyApp>;
    // currentAppId$: Subject<string | undefined>;
    location: any;

    basePath?: string;
    // location?: any;
    history: any;
    store: any;
    matchId?: string;
    auth?: any;
    match: any;
}

interface State {
    appNotFound: boolean;
}
export class AppContainer extends React.Component<Props, State> {
    private readonly containerDiv = React.createRef<HTMLDivElement>();
    private unmountFunc?: AppUnmount;

    state: State = { appNotFound: false };

    componentDidMount() {
        this.mountApp();
    }

    componentWillUnmount() {
        this.unmountApp();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.match.params.appId !== this.props.match.params.appId) {
            this.unmountApp();
            this.mountApp();
        }
        if (
            prevProps.match.params.subAppId !== this.props.match.params.subAppId) {
            this.unmountApp();
            this.mountApp();
        }
    }


    async mountApp() {

        const { apps, match, basePath, store, auth } = this.props;
        const { appId, subAppId } = match.params;
        let mount = apps.get(appId);
        if (!mount) {
            mount = apps.get(subAppId);
            if (!mount) {
                this.setState({ appNotFound: true });
                return
            }
        }
        this.unmountFunc = await mount({
            //   appBasePath: basePath.prepend(`/app/${appId}`),
            appBasePath: `${match.url}`,
            element: this.containerDiv.current!,
            location: this.props.location,
            history: this.props.history,
            match: match,
            store: store,
            auth: auth,
        });
        // currentAppId$.next(appId);
        this.setState({ appNotFound: false });
        return;

    }

    async unmountApp() {
        if (this.unmountFunc) {
            this.unmountFunc();
            console.debug("unmountApp")
            this.unmountFunc = undefined;
        }
    }

    render() {
        // if(
        //     this.props.location.pathname !== "/app/login" &&
        //     (!this.props.store.getState().auth || 
        //     !this.props.store.getState().auth.name ||
        //     this.props.store.getState().auth.name !== "")) {
        //     console.log("needlogin",this.props)
        //     // this.props.history.push("/app/login")
        //     window.location.href = "/app/login"

        // }
        const subAppId = this.props.match.params.subAppId || "root"
        const id = `${this.props.match.params.appId}-${subAppId}`
        return (
            <React.Fragment>
                {this.state.appNotFound && <AppNotFound />}
                <div style={{ height: "inherit" }} id={id} key={this.props.match.params.appId} ref={this.containerDiv}>
                    {/* <div id={this.props.match.params.appId + this.props.match.params.subAppId} ></div> */}
                </div>
            </React.Fragment>
        );
    }
}