/*
 * types/services/dr2am.tasks.permissions.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {
  ApiClient
} from '../ApiClient';

/**
 * The PbPermissionNode model module.
 * @module model/PbPermissionNode
 * @version version not set
 */
export class PbPermissionNode {
  /**
   * Constructs a new <code>PbPermissionNode</code>.
   * @alias module:model/PbPermissionNode
   * @class
   */
  constructor() {}

  /**
   * Constructs a <code>PbPermissionNode</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PbPermissionNode} obj Optional instance to populate.
   * @return {module:model/PbPermissionNode} The populated <code>PbPermissionNode</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PbPermissionNode();
      if (data.hasOwnProperty('left'))
        obj.left = PbPermissionNode.constructFromObject(data['left']);
      if (data.hasOwnProperty('right'))
        obj.right = PbPermissionNode.constructFromObject(data['right']);
      if (data.hasOwnProperty('typeof'))
        obj.typeof = ApiClient.convertToType(data['typeof'], 'String');
      if (data.hasOwnProperty('parens'))
        obj.parens = ApiClient.convertToType(data['parens'], 'Boolean');
      if (data.hasOwnProperty('operator'))
        obj.operator = ApiClient.convertToType(data['operator'], 'Number');
      if (data.hasOwnProperty('operator_str'))
        obj.operatorStr = ApiClient.convertToType(data['operator_str'], 'String');
      if (data.hasOwnProperty('reference'))
        obj.reference = ApiClient.convertToType(data['reference'], 'String');
      if (data.hasOwnProperty('literal'))
        obj.literal = ApiClient.convertToType(data['literal'], 'String');
      if (data.hasOwnProperty('ident'))
        obj.ident = ApiClient.convertToType(data['ident'], 'String');
      if (data.hasOwnProperty('base'))
        obj.base = ApiClient.convertToType(data['base'], 'String');
      if (data.hasOwnProperty('int64'))
        obj.int64 = ApiClient.convertToType(data['int64'], 'String');
      if (data.hasOwnProperty('float'))
        obj.float = ApiClient.convertToType(data['float'], 'Number');
      if (data.hasOwnProperty('isint'))
        obj.isint = ApiClient.convertToType(data['isint'], 'Boolean');
      if (data.hasOwnProperty('isfloat'))
        obj.isfloat = ApiClient.convertToType(data['isfloat'], 'Boolean');
      if (data.hasOwnProperty('bool'))
        obj.bool = ApiClient.convertToType(data['bool'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {module:model/PbPermissionNode} left
 */
PbPermissionNode.prototype.left = undefined;

/**
 * @member {module:model/PbPermissionNode} right
 */
PbPermissionNode.prototype.right = undefined;

/**
 * @member {String} _typeof
 */
PbPermissionNode.prototype.typeof = undefined;

/**
 * @member {Boolean} parens
 */
PbPermissionNode.prototype.parens = undefined;

/**
 * @member {Number} operator
 */
PbPermissionNode.prototype.operator = undefined;

/**
 * @member {String} operatorStr
 */
PbPermissionNode.prototype.operatorStr = undefined;

/**
 * @member {String} reference
 */
PbPermissionNode.prototype.reference = undefined;

/**
 * @member {String} literal
 */
PbPermissionNode.prototype.literal = undefined;

/**
 * @member {String} ident
 */
PbPermissionNode.prototype.ident = undefined;

/**
 * @member {String} base
 */
PbPermissionNode.prototype.base = undefined;

/**
 * @member {String} int64
 */
PbPermissionNode.prototype.int64 = undefined;

/**
 * @member {Number} _float
 */
PbPermissionNode.prototype.float = undefined;

/**
 * @member {Boolean} isint
 */
PbPermissionNode.prototype.isint = undefined;

/**
 * @member {Boolean} isfloat
 */
PbPermissionNode.prototype.isfloat = undefined;

/**
 * @member {Boolean} bool
 */
PbPermissionNode.prototype.bool = undefined;