/*
 * types/services/dr2am.tasks.preferences.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbPreference} from './PbPreference';
import {ProtobufFieldMask} from './ProtobufFieldMask';

/**
 * The ServiceUpdatePreferenceRequest model module.
 * @module model/ServiceUpdatePreferenceRequest
 * @version version not set
 */
export class ServiceUpdatePreferenceRequest {
  /**
   * Constructs a new <code>ServiceUpdatePreferenceRequest</code>.
   * @alias module:model/ServiceUpdatePreferenceRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceUpdatePreferenceRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceUpdatePreferenceRequest} obj Optional instance to populate.
   * @return {module:model/ServiceUpdatePreferenceRequest} The populated <code>ServiceUpdatePreferenceRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceUpdatePreferenceRequest();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('preference'))
        obj.preference = PbPreference.constructFromObject(data['preference']);
      if (data.hasOwnProperty('update_mask'))
        obj.updateMask = ProtobufFieldMask.constructFromObject(data['update_mask']);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ServiceUpdatePreferenceRequest.prototype.id = undefined;

/**
 * @member {module:model/PbPreference} preference
 */
ServiceUpdatePreferenceRequest.prototype.preference = undefined;

/**
 * @member {module:model/ProtobufFieldMask} updateMask
 */
ServiceUpdatePreferenceRequest.prototype.updateMask = undefined;


