/*
 * types/services/dr2am.tasks.roles.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {RuntimeError} from '../model/RuntimeError';
import {ServiceCreateRoleRequest} from '../model/ServiceCreateRoleRequest';
import {ServiceCreateRoleResponse} from '../model/ServiceCreateRoleResponse';
import {ServiceDeleteRoleResponse} from '../model/ServiceDeleteRoleResponse';
import {ServiceGetRolesWithUserResponse} from '../model/ServiceGetRolesWithUserResponse';
import {ServiceListRolessResponse} from '../model/ServiceListRolessResponse';
import {ServiceUpdateRoleRequest} from '../model/ServiceUpdateRoleRequest';
import {ServiceUpdateRoleResponse} from '../model/ServiceUpdateRoleResponse';

/**
* RolesService service.
* @module api/RolesServiceApi
* @version version not set
*/
export class RolesServiceApi {

    /**
    * Constructs a new RolesServiceApi. 
    * @alias module:api/RolesServiceApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create  Roles
     * @param {module:model/ServiceCreateRoleRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceCreateRoleResponse} and HTTP response
     */
    rolesServiceCreateWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling rolesServiceCreate");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceCreateRoleResponse;

      return this.apiClient.callApi(
        '/v1/roles:create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create  Roles
     * @param {module:model/ServiceCreateRoleRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceCreateRoleResponse}
     */
    rolesServiceCreate(body) {
      return this.rolesServiceCreateWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete  Roles
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceDeleteRoleResponse} and HTTP response
     */
    rolesServiceDeleteWithHttpInfo(id) {
      let postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling rolesServiceDelete");
      }


      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceDeleteRoleResponse;

      return this.apiClient.callApi(
        '/v1/roles/{id}:delete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete  Roles
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceDeleteRoleResponse}
     */
    rolesServiceDelete(id) {
      return this.rolesServiceDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * GetWithUser Roles with UserId
     * @param {String} userId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceGetRolesWithUserResponse} and HTTP response
     */
    rolesServiceGetWithUserWithHttpInfo(userId) {
      let postBody = null;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling rolesServiceGetWithUser");
      }


      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceGetRolesWithUserResponse;

      return this.apiClient.callApi(
        '/v1/users/{user_id}/role', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * GetWithUser Roles with UserId
     * @param {String} userId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceGetRolesWithUserResponse}
     */
    rolesServiceGetWithUser(userId) {
      return this.rolesServiceGetWithUserWithHttpInfo(userId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Roless
     * @param {Object} opts Optional parameters
     * @param {String} opts.action 
     * @param {Number} opts.pageSize 
     * @param {String} opts.pageToken 
     * @param {String} opts.query 
     * @param {String} opts.roleBy 
     * @param {Number} opts.paginateOffset 
     * @param {Number} opts.paginateLimit 
     * @param {String} opts.paginateNextPageToken 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceListRolessResponse} and HTTP response
     */
    rolesServiceListWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'action': opts['action'],
        'page_size': opts['pageSize'],
        'page_token': opts['pageToken'],
        'query': opts['query'],
        'role_by': opts['roleBy'],
        'paginate.offset': opts['paginateOffset'],
        'paginate.limit': opts['paginateLimit'],
        'paginate.next_page_token': opts['paginateNextPageToken']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceListRolessResponse;

      return this.apiClient.callApi(
        '/v1/roles:list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * List Roless
     * @param {Object} opts Optional parameters
     * @param {String} opts.action 
     * @param {Number} opts.pageSize 
     * @param {String} opts.pageToken 
     * @param {String} opts.query 
     * @param {String} opts.roleBy 
     * @param {Number} opts.paginateOffset 
     * @param {Number} opts.paginateLimit 
     * @param {String} opts.paginateNextPageToken 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceListRolessResponse}
     */
    rolesServiceList(opts) {
      return this.rolesServiceListWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update  Roles
     * @param {String} id 
     * @param {module:model/ServiceUpdateRoleRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceUpdateRoleResponse} and HTTP response
     */
    rolesServiceUpdateWithHttpInfo(id, body) {
      let postBody = body;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling rolesServiceUpdate");
      }

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling rolesServiceUpdate");
      }


      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceUpdateRoleResponse;

      return this.apiClient.callApi(
        '/v1/roles/{id}:update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update  Roles
     * @param {String} id 
     * @param {module:model/ServiceUpdateRoleRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceUpdateRoleResponse}
     */
    rolesServiceUpdate(id, body) {
      return this.rolesServiceUpdateWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
