import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation } from 'react-i18next';


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        // lng: 'zh-CN',
        fallbackLng: 'zh-CN',
        // debug: true,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        detection: { order: ['cookie', 'localStorage', 'querystring', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'], },
        // react i18next special options (optional)
        // override if needed - omit if ok with defaults
        react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',

            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: false,
        },
        saveMissing: false,
        backend: {
            loadPath: 'https://globe.wanfangdata.com.cn/locales/{{lng}}/{{ns}}.json',
            // addPath: 'http://videos.wanfangdata.com.hk:82/v1/locales/add/{{lng}}/{{ns}}',

        }
    });

// const { t, i18n } = useTranslation
export default useTranslation;