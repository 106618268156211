import _, { cond } from "lodash";
import moment from "moment";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Route } from "react-router";
import styled from "styled-components";
import { handlePbError, useConditionExpression, wfConfig } from "./api";
import { ApiClient } from "./client/ApiClient";
import { RolesServiceApi } from "./client/dr2am.tasks.roles-js/src";
import { Details } from "./components/details";
import { Form } from "./components/form";
import { DataTable, TableBody, TableColumn, TableHeader, TableHeaderColumn, TableRow } from "./components/table";
import { ConditionExpression } from "./condition_expr";
import { EventEmitter } from "./event_emitter";
import ImportPermissionsForm from "./permissions/import";
import { generateId } from "./utils";

class rolesStore extends EventEmitter {
    prex = "wfhk-roles"
    storage = window.localStorage
    roles: any = []
    api: RolesServiceApi

    constructor(authc: any, apiURL: any) {
        super();
        const client = new ApiClient();
        client.basePath = apiURL || `${wfConfig.apiURL.protocol}//${wfConfig.apiURL.host}/api`;
        // client.basePath = "https://globe.wanfangdata.com.cn/api";
        client.authc = authc;
        this.api = new RolesServiceApi(client);
        // const policiesStr: any = this.storage.getItem(this.prex)
        // try {
        //     this.roles = JSON.parse(policiesStr) || []
        // } catch (error) { }
        this.getAll()
    }
    async getAll() {
        try {
            // new LocalesApi(client);
            const res = await this.api.rolesServiceList({} as any)
            const playload = handlePbError(res)
            this.roles = playload.roles || []
            return playload
        } catch (error) {
            return { error }
        }

    }
    async create(role: any) {

        try {
            const res = await this.api.rolesServiceCreate({ role })
            const playload = handlePbError(res)
            console.log(playload);
            if (playload.role) {
                this.roles.push(playload.role)
                this.storage.setItem(this.prex, JSON.stringify(this.roles))
                this.dispatch("change", role);
                this.dispatch(role.id, role);
            }
            return playload
        } catch (error) {
            return { error }
        }


        // const role: any = {
        //     "name": `${name}`,
        //     "namespace": namespace,
        // }
        role.id = generateId()
        role.createAt = new Date()
        role.modifyAt = role.createAt
        // _.set(this.roles, role.path, role)
        this.roles.push(role)
        this.storage.setItem(this.prex, JSON.stringify(this.roles))
        this.dispatch("change", role);
        this.dispatch(role.id, role);
        return role
    }
    async update(id: string, data: any, paths?: any) {
        const idx = _.findIndex(this.roles, function (policy: any) {
            return policy.id == id
        })
        if (idx < 0) {
            return
        }
        for (let i = 0; i < paths.length; i++) {
            const path = paths[i];
            _.set(this.roles[idx], path, _.get(data, path))
        }
        this.roles[idx].modifyAt = new Date()
        if (!this.roles[idx].createAt) {
            this.roles[idx].createAt = this.roles[idx].modifyAt
        }
        this.storage.setItem(this.prex, JSON.stringify(this.roles))
        this.dispatch("change", this.roles[idx]);
        this.dispatch(id, this.roles[idx]);
        return this.roles[idx]
    }
    async remove(path: string) {

        try {
            const role = await this.get(path)
            if (!role) {
                return
            }
            const res = await this.api.rolesServiceDelete(role.id)
            const playload = handlePbError(res)
            console.log(playload);
            if (!playload.error) {
                _.remove(this.roles, function ({ name }: any) { return name == path })
                this.storage.setItem(this.prex, JSON.stringify(this.roles))
                this.dispatch("change", null);
                this.dispatch(path, null);
            }
            return playload
        } catch (error) {
            return { error }
        }

        _.remove(this.roles, function ({ name }: any) { return name == path })
        this.storage.setItem(this.prex, JSON.stringify(this.roles))
        this.dispatch("change", null);
        this.dispatch(path, null);
        return false
    }

    async get(nameOrId: string) {
        return _.find(this.roles, function (role) {
            return role.id == nameOrId || role.name == nameOrId
        })
    }

    async addPermissions(id: string, permissions: any[]) {
        const policy: any = await this.get(id)
        if (!policy) {
            return
        }
        const policyPermissions = policy.permissions || []
        for (let i = 0; i < permissions.length; i++) {
            const permission = permissions[i];
            if (_.includes(policyPermissions, permission)) {
                continue
            }
            policyPermissions.push(permission)
        }

        return this.update(id, { permissions: policyPermissions }, ["permissions"])
    }
    async removePermissions(id: string, permissions: any[]) {
        const policy: any = await this.get(id)
        if (!policy) {
            return
        }
        const policyPermissions = policy.permissions
        const nPermissions = []
        for (let i = 0; i < policyPermissions.length; i++) {
            const permission = policyPermissions[i];
            if (_.includes(permissions, permission)) {
                continue
            }
            nPermissions.push(permission)
        }

        return this.update(id, { permissions: nPermissions }, ["permissions"])
    }

}
var _roleStore: rolesStore
export const RolesStore = function (authc: any, apiURL: any) {
    if (_roleStore) {
        return _roleStore
    }
    if (authc) {
        _roleStore = new rolesStore(authc, apiURL)
    }

    return _roleStore
}


function CreateRoleForm({ open, trigger, onChange, data, ...props }: any) {
    const [openModal, setOpenModal] = useState(open)
    const [policies, setPolicies] = useState<any>([])
    const store = props.rolesStore
    const nTrigger = React.cloneElement(trigger, {
        onClick: function (e) {
            setOpenModal(!!!openModal)
            if (trigger.onClick) {
                trigger.onClick(e)
            }
        }
    })

    async function handlerPolicySubmit(nData: any, paths: any) {
        if (data) {
            nData.id = data.id
            await store.update(data.id, nData, paths)
        } else {
            await store.create(nData)
        }
        if (onChange) {
            onChange()
        }
        setOpenModal(false)
    }
    return <> {nTrigger}{openModal && <Details   >
        {() => {

            const schemas: any = [
                { "key": "name", "label": "Role name", "type": "text" },
                { "key": "groups", "label": "Assigned groups(可选)", "type": "text" },
            ];
            if (data) {
                _.each(schemas, (schema, i) => {
                    const value = data[schema.key]
                    if (value) {
                        schemas[i].value = value
                    }
                })
            }

            return <div className="p-3 text-left">
                <div className="clearfix d-flex flex-items-center">
                    <h3 className="flex-1">Create New Role</h3>
                    <a className=" btn btn-invisible " onClick={() => setOpenModal(false)}>关闭</a>
                </div>
                <Form key={generateId()}
                    onSubmit={handlerPolicySubmit}
                    onCancel={() => {
                        setOpenModal(false)
                    }}
                    schemas={schemas}
                ></Form>
            </div>
        }}
    </Details>}
    </>
}

const Expr = styled.div<any>`
:hover {
    background: #9d9d9d;
}
`
const Tree = styled.div<any>`
--ActionList-tree-depth: ${({ level }) => (level ? level : 1)};
position: relative;
padding-left: calc(16px * (var(--ActionList-tree-depth)) + 7px);
::before {
    position: absolute;
    left: 0;
    width: 1px;
    height: 100%;
    content: "";
    background: #9e9e9e;
}
`
function formatValue(node: any) {
    let nodeTypeOf = node.typeof;

    let nodeTypeOfKey = ConditionExpression.typeOfKey[nodeTypeOf];

    if (nodeTypeOf == "number") {
        if (node.isint) {
            nodeTypeOfKey = "int64"
        } else if (node.isfloat) {
            nodeTypeOfKey = "float64"
        }
    }

    let nodeValue = node[nodeTypeOfKey];
    if (nodeTypeOf == "string") {
        nodeValue = "\"" + nodeValue + "\"";
    }
    if (nodeTypeOf == "bool") {
        nodeValue = nodeValue || false
    }
    return nodeValue;
}
function RowLevelSecurity({ expression, expression1, onChange, ...props }: any) {
    // let { left, right, operator, typeOf } = expression
    let { left, right, operatorStr } = expression1
    let operator = operatorStr
    let typeOf = expression1.typeof


    let leftValue = formatValue(left)
    let rightValue = formatValue(right)
    function isUserAttribute() {
        return rightValue && _.isString(rightValue) && rightValue.startsWith("user_attribute(")
    }
    function isColumn() {
        return _.isString(leftValue) && (leftValue.startsWith("column(") || leftValue.startsWith("column[") || leftValue.startsWith("column."))
    }
    function isTags() {
        return _.isString(leftValue) && (leftValue.startsWith("tags[") || leftValue.startsWith("tags."))
    }

    const [isColumnMode, setColumnMode] = useState(isColumn())
    const IsColmun = isColumnMode

    const IsUserAttribute = isUserAttribute()

    if (IsColmun) {
        leftValue = leftValue.slice(7, leftValue.length - 1)
    }
    const $expression = useRef<any>({
        left: leftValue,
        leftMode: IsColmun,
        right: rightValue,
        rightMode: IsUserAttribute,
        operator: operator
    }).current



    function renderOperator() {

        const options = [

            // { value: "==", label: "does not match user attribute" },
            // { value: "==", label: "is equal to user attribute" },
            // { value: "==", label: "is not equal to user attribute" },
            // { value: ":", label: "" }
            { value: "==", label: "is equal to" },
            { value: "!=", label: "is not equal to" },
            { value: ":", label: "contains" },
            { value: "!:", label: "does not contain" },
            {
                value: ":", label: "matches user attribute", selected: function (option: any) {
                    const { value, label } = option
                    return IsUserAttribute && value == operator
                }
            },
        ]
        return _.map(options, ({ value, label, selected }: any) => {
            if (!selected) {
                selected = function ({ value, label }: any) {
                    return !IsUserAttribute && value == operator
                }
            }
            return <option value={value} selected={selected({ value, label })}>{label}</option>
        })
    }


    const debounceonChange = _.debounce(onChange, 300)
    function handlerSelectChange(key: string) {
        return function (e: any) {

            let value = e.target.value;
            if ($expression.leftMode && key == "left") {
                value = `column(${value})`
            }
            $expression[key] = value
            if (onChange) {
                debounceonChange($expression, key)
            }
        }
    }
    function handleModeChange(e: any) {
        if (e.target.value == "Column") {
            $expression.leftMode = true
            setColumnMode(true)
            handlerSelectChange("left")({ target: { value: $expression["left"] } })
        } else {
            $expression.leftMode = false
            setColumnMode(false)
            handlerSelectChange("left")({ target: { value: $expression["left"] } })

        }
    }
    function unit() {
        return < >
            <div className="d-flex flex-items-center">
                <span className="">Only grant access to data WHERE...</span>
                <select className="ml-3 flex-1 form-select"
                    onChange={handleModeChange}>
                    <option>Choose an Operations</option>
                    <option selected={IsColmun}>Column</option>
                    <option selected={isTags()}>User Attribute</option>
                </select>
            </div>
            <div className="d-flex flex-items-center">
                <div className="form-group flex-1">
                    <div className="form-group-header h6">
                        <label htmlFor="example-select">Column</label>
                    </div>
                    <div className="form-group-body">
                        <input className="form-control input-block" defaultValue={leftValue}
                            onChange={handlerSelectChange("left")}
                        >
                        </input>
                    </div>
                </div>
                <div className="form-group  flex-1 ml-2">
                    <div className="form-group-header h6">
                        <label htmlFor="example-select">Operator</label>
                    </div>
                    <div className="form-group-body">
                        <select className="form-select input-block" id="example-select"
                            onChange={handlerSelectChange("operator")}
                        >
                            <option>Choose an option</option>
                            {renderOperator()}
                        </select>
                    </div>
                </div>
                <div className="form-group  flex-1 ml-2">
                    <div className="form-group-header h6">
                        <label htmlFor="example-select">User attribute key</label>
                    </div>
                    <div className="form-group-body">
                        <input className="form-control input-block" defaultValue={rightValue}
                            onChange={handlerSelectChange("right")}>
                        </input>
                        {/* <select className="form-select input-block" id="example-select">
                            <option>Choose an key</option>
                            <option>id</option>
                            <option>name</option>
                            <option>resource.order</option>
                            <option>resource.class</option>
                        </select> */}
                    </div>
                </div>
            </div>
        </>
    }
    return <div>
        {unit()}
    </div>
}
function ExpressionStr({ expression, ...props }: any) {
    const conditionExpressionApi = props.useConditionExpression();
    const [str, setStr] = useState("")
    useEffect(() => {
        if (expression) {
            JSON.stringify(expression)
            conditionExpressionApi.check(expression).then(({ data, error }: any): any => {
                if (error) {
                    setStr(JSON.stringify(error))
                    return
                }
                setStr(data.data)
                return
            })
        }
    }, [JSON.stringify(expression)])
    return <span className="wb-break-all text-bold">{str}</span>
}
function RolePermissionForm({ permission, role, open, trigger, onChange, data, ...props }: any) {
    // const conditionExpressionApi = props.useConditionExpression;
    const [openModal, setOpenModal] = useState(open)
    const currentPermissionRef = useRef(_.cloneDeep(permission))
    const isNew = !!!permission


    const [$permission, setPermission] = useState<any>()


    const store = props.permissionsStore
    useEffect(() => {
        let nCurrentPermission = _.cloneDeep(permission)// { ...permission }
        const isNew = !!!permission

        if (isNew) {
            nCurrentPermission = {}
            nCurrentPermission["roles"] = [role]

        } else {
            if (nCurrentPermission.conditions) {
                const conditions: any = []
                _.each(nCurrentPermission.conditions, (condition) => {
                    const nCondition = new ConditionExpression(condition)
                    conditions.push(nCondition)
                })
                nCurrentPermission.conditions = conditions
            }
        }
        currentPermissionRef.current = nCurrentPermission
        setPermission({
            ..._.cloneDeep(nCurrentPermission)
        })
        return function () {

        }
    }, [permission])

    function close() {
        const currentPermission = currentPermissionRef.current
        // $permission
        if (JSON.stringify($permission) != JSON.stringify(currentPermission)) {

            if (window.confirm("你有修改未保存，是否保存？")) {
                updatePermissionToRole()
            } else {
                debugger
                console.log({ ..._.cloneDeep(currentPermission) });

                setPermission({ ..._.cloneDeep(currentPermission) })
            }
        }
        setOpenModal(!!!openModal)
    }

    const nTrigger = React.cloneElement(trigger, {
        onClick: function (e) {
            setOpenModal(true)
            if (trigger.onClick) {
                trigger.onClick(e)
            }
        }
    })

    function handerPermissionChange(key: string) {
        return function (e: any) {
            let value = e.target.value

            switch (key) {
                case "roles":
                    if (!_.isArray(value)) {
                        value = [value]
                    }

            }
            setPermission({ ...$permission, [key]: value })

        }
    }

    async function addPermissionToRole() {
        await store.create({
            ...$permission,
            // conditions
        })
        setOpenModal(!!!openModal)
    }

    async function deletePermissionToRole() {
        const currentPermission = currentPermissionRef.current
        if (window.confirm("删除后不可恢复，确定要删除吗？")) {
            await store.remove(currentPermission.id)
            setOpenModal(!!!openModal)
        }

    }
    async function updatePermissionToRole() {
        const currentPermission = currentPermissionRef.current
        const keys = _.keys($permission)
        const updatePaths = []
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            if (!_.isEqual($permission[key], currentPermission[key])) {
                updatePaths.push(key)
            }
        }

        await store.update(currentPermission.id, $permission, updatePaths)
        currentPermissionRef.current = _.cloneDeep($permission)
        setPermission({ ..._.cloneDeep($permission) })
        setOpenModal(!!!openModal)

    }
    async function addAccessConditions() {
        const nCondition = new ConditionExpression({})
        $permission.conditions = $permission.conditions || []
        const nConditions = [...$permission.conditions, nCondition]
        setPermission({ ...$permission, "conditions": nConditions })
    }
    async function updateAccessConditions(id: string, field: string, value: any) {
        return function () {
        }
    }
    function handleConditionAccessTypeChange(id: string) {
        return function (e: any) {
            const condition = updateCondition(id, "accessType", e.target.value)
            if (condition && !condition.expressions) {
            }

        }
    }
    function handleRemoveCondition(id: string) {
        return function (e: any) {
            removeCondition(id)
        }
    }
    function removeCondition(id: string) {
        const conditions: any = $permission.conditions;
        _.remove(conditions, (condition: any) => {
            return condition.id == id
        })
        setPermission({ ...$permission, "conditions": conditions })
    }
    function updateCondition(id: string, field: string, value: any) {
        const conditions: any = $permission.conditions;
        for (let i = 0; i < conditions.length; i++) {
            const condition = conditions[i];
            if (condition.id == id) {
                conditions[i][field] = value
                setPermission({ ...$permission, "conditions": conditions })
                return conditions[i]
            }
        }
        return

    }
    function handlerExpressionChange(condition: any, path: any, oExpr?: any) {
        return async function (expr: any, key: string) {
            const { left, right, operator } = expr
            if (key != "right") {

            }
            condition.update(path, { left, right, operatorStr: operator })
            setPermission({ ...$permission })
        }
    }
    function handlerExpressionOperator(condition: any, path: any, operator?: any) {
        return async function (e: any) {
            condition.updateValue(path, e.target.value)
            setPermission({ ...$permission })
        }
    }
    function insertExpression(condition: any, path: any) {
        return function () {

            if (!path) {
                condition.insertRoot()
            } else {
                condition.insert(path, {})
            }
            setPermission({ ...$permission })
        }
    }
    function insertSubExpression(condition: any, path: any) {
        return function () {
            if (!path) {
                return
            } else {
                condition.insertSub(path, {})
            }
            setPermission({ ...$permission })
        }
    }
    function removeExpression(condition: any, path: any) {
        return function () {
            condition.remove(path, {})
            setPermission({ ...$permission })

        }
    }
    function renderExpressions(condition: any, list: any) {
        const nodes: any = []
        for (let i = 0; i < list.length; i++) {
            const expr = list[i];
            if (expr.typeof == "binary") {

                const { left, right, operatorStr } = expr
                const rTypeOfKey = ConditionExpression.typeOfKey[right.typeof]
                nodes.push(
                    <Tree key={expr.path.join(".")} level={1}>
                        <Expr>
                            <RowLevelSecurity expression1={expr} expression={{ left: left[left.typeof], right: right[rTypeOfKey], typeOf: rTypeOfKey, operator: operatorStr }} onChange={handlerExpressionChange(condition, expr.path, expr)}></RowLevelSecurity>
                            <button className="btn btn-invisible ml-n3"
                                onClick={insertSubExpression(condition, expr.path)}>
                                <svg className="octicon color-fg-accent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"></path></svg>
                                <span>添加子条件</span>
                            </button>

                            <button className="btn-octicon btn-octicon-danger float-right" type="button" aria-label="Trashcan icon"
                                onClick={removeExpression(condition, expr.path)}>
                                <svg className="octicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M6.5 1.75a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V3h-3V1.75zm4.5 0V3h2.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H5V1.75C5 .784 5.784 0 6.75 0h2.5C10.216 0 11 .784 11 1.75zM4.496 6.675a.75.75 0 10-1.492.15l.66 6.6A1.75 1.75 0 005.405 15h5.19c.9 0 1.652-.681 1.741-1.576l.66-6.6a.75.75 0 00-1.492-.149l-.66 6.6a.25.25 0 01-.249.225h-5.19a.25.25 0 01-.249-.225l-.66-6.6z"></path></svg>
                            </button>
                        </Expr>
                    </Tree>
                )
                continue

            }

            if (expr.operatorStr == "AND" || expr.operatorStr == "OR" || expr.operatorStr == "NOT" || expr.operatorStr == "Not") {
                nodes.push(<select key={expr.path.join(".")} className="form-select my-3"
                    onChange={handlerExpressionOperator(condition, expr.path)}
                >
                    <option selected={expr.operatorStr == "AND"} >AND</option>
                    <option selected={expr.operatorStr == "OR"}>OR</option>
                    <option selected={expr.operatorStr == "NOT"}>NOT</option>
                </select>)
                continue
            }

            if (_.isArray(expr)) {
                nodes.push(<Tree level={1}>
                    <h5>Condition Group</h5>
                    {renderExpressions(condition, expr)}
                    <button className="btn btn-invisible ml-n4"
                        onClick={insertExpression(condition, _.last(expr).path)}>
                        <svg className="octicon color-fg-accent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"></path></svg>
                        <span>添加另一个组条件</span>
                    </button>
                </Tree>)
            }



        }
        // nodes.push(<button className="btn btn-invisible ml-n4"
        //     onClick={addAccessConditions}>
        //     <svg className="octicon color-fg-accent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"></path></svg>
        //     <span>添加另一个条件</span>
        // </button>)
        return nodes
    }
    function renderCondition(condition: any) {
        if (!condition || !condition.walk) {
            return
        }
        const list = condition.parser() || []
        // console.log(list);
        // if (!list || !list.length) {
        //     return
        // }

        const nodes: any = renderExpressions(condition, list)

        return <div key={condition.id} className="m-2 p-3 Box border-0 color-bg-subtle">
            <div className="form-group mt-n1">
                <div className="form-group-header f6 clearfix">
                    <label htmlFor="example-select">Condition</label>
                    <button className="btn-octicon btn-octicon-danger float-right" type="button" aria-label="Trashcan icon"
                        onClick={handleRemoveCondition(condition.id)}>
                        <svg className="octicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M6.5 1.75a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V3h-3V1.75zm4.5 0V3h2.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H5V1.75C5 .784 5.784 0 6.75 0h2.5C10.216 0 11 .784 11 1.75zM4.496 6.675a.75.75 0 10-1.492.15l.66 6.6A1.75 1.75 0 005.405 15h5.19c.9 0 1.652-.681 1.741-1.576l.66-6.6a.75.75 0 00-1.492-.149l-.66 6.6a.25.25 0 01-.249.225h-5.19a.25.25 0 01-.249-.225l-.66-6.6z"></path></svg>
                    </button>
                </div>
                <div className="form-group-body">
                    <select className="form-select input-block" id="example-select" onChange={handleConditionAccessTypeChange(condition.id)}>
                        <option>Choose an option</option>
                        <option value={"cls"} selected={condition.accessType == "cls"}>基于标签限制访问（column level security）</option>
                        <option value={"rls"} selected={condition.accessType == "rls"}>通过行过滤限制访问（row level security）</option>
                        {/* <option>转换数据（Transform data）未支持</option> */}
                    </select>
                </div>
            </div>
            {nodes}
            <button className="btn btn-invisible ml-n4"
                onClick={insertExpression(condition, null)}>
                <svg className="octicon color-fg-accent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"></path></svg>
                <span>添加另一个条件</span>
            </button>


        </div>
    }
    function renderConditions() {
        const conditions = $permission.conditions
        return _.map(conditions, condition => {
            return renderCondition(condition)
        })
    }
    async function handlerPolicySubmit(nData: any, paths: any) {
        console.log(nData, paths);
        if (data) {
            nData.id = data.id
            await store.update(data.id, nData, paths)
        } else {
            await store.create(nData)
        }
        if (onChange) {
            onChange()
        }
        setOpenModal(false)
    }
    function renderScopeOptions() {
        const scopes = ["北美地区数据集", "港澳台数据集", "Guest数据集", "系统管理"]
        return <><option>Choose an option</option>
            {_.map(scopes, scope => {
                return <option value={scope} selected={$permission && $permission.scope == scope}>{scope}</option>
            })}
        </>
    }
    function renderDatabaseOptions() {

        if ($permission.scope == "系统管理") {
            return <><input className="form-control" type={"text"} defaultValue={$permission.database} onChange={handerPermissionChange("database")}></input></>
        }
        const databases = [
            { value: "OpenPeriodical", label: "期刊库" },
            { value: "OpenThesis", label: "学位库" },
            { value: "OpenConference", label: "会议库" },
            { value: "OpenPatent", label: "专利库" },
            { value: "OpenStandard", label: "标准库" },
            { value: "OpenMagazine", label: "刊名库" },
            { value: "OpenMeeting", label: "会议名录" },
            { value: "OpenClaw", label: "法律法规" },
        ]
        return <select className="form-select input-block" onChange={handerPermissionChange("database")}>
            <option>Choose an option</option>
            {_.map(databases, database => {
                return <option value={database.value} selected={$permission && $permission.database == database.value}>{database.label}</option>
            })}
        </select>

    }

    function renderAccessTypeOptions() {
        const accessTypes = ["SELECT", "DELIVERY", "FULLTEXT", "DETAIL", "DISCOVERY"]
        return <><option>Choose an option</option>
            {_.map(accessTypes, accessType => {
                return <option value={accessType} selected={$permission && $permission.accessType == accessType}>{accessType}</option>
            })}
        </>
    }
    return <> {nTrigger}{openModal && <Details fullscreen  >
        {() => {

            const schemas: any = [
                { "key": "name", "label": "Role name", "type": "text" },
                { "key": "groups", "label": "Assigned groups(可选)", "type": "text" },
            ];
            if (data) {
                _.each(schemas, (schema, i) => {
                    const value = data[schema.key]
                    if (value) {
                        schemas[i].value = value
                    }
                })
            }



            return <div className="p-3 text-left d-flex flex-column height-full">
                <div>
                    <div className="clearfix d-flex flex-items-center">
                        <h3 className="flex-1">Add permission to role</h3>
                        <a className=" btn btn-invisible " onClick={() => setOpenModal(false)}>关闭</a>
                    </div>
                    <hr />
                </div>
                <div className="d-flex flex-1   overflow-auto">
                    <div className="flex-1 overflow-auto">
                        {/* Basic information */}
                        <div className="position-relative px-6 border-right">
                            <div className="position-absolute left-0">1</div>
                            <div>
                                <div className="h3 text-light color-fg-muted my-2">Basic information</div>
                                <div className="py-2">
                                    <div className="form-group">
                                        <div className="form-group-header  h6">
                                            <label htmlFor="permission-name-input">Name</label>
                                        </div>
                                        <div className="form-group-body">
                                            <input id="permission-name-input" className="form-control"
                                                onChange={handerPermissionChange("name")}
                                                defaultValue={$permission.name}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-2">
                                    <div className="text-light">用户应该有权访问哪些对象？</div>
                                    <div className="mb-3 d-flex ">
                                        <div className="form-group">
                                            <div className="form-group-header  h6">
                                                <label htmlFor="example-select">Scope</label>
                                            </div>
                                            <div className="form-group-body">
                                                <select className="form-select" onChange={handerPermissionChange("scope")}
                                                >
                                                    {renderScopeOptions()}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="ml-3 form-group flex-1">
                                            <div className="form-group-header  h6">
                                                <label htmlFor="example-select">Database</label>
                                            </div>
                                            <div className="form-group-body">
                                                {renderDatabaseOptions()}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-2">
                                    <div className="text-light"> 应授予那种类型的访问权限？</div>
                                    <div className="mt-3 text-light">
                                        <div className="form-group">
                                            <div className="form-group-header h6">
                                                <label htmlFor="example-select">Access level</label>
                                            </div>
                                            <div className="form-group-body">
                                                <select className="form-select" onChange={handerPermissionChange("accessType")}>
                                                    {renderAccessTypeOptions()}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="py-2">
                                    <div className="text-light">此权限应适用于哪个角色？</div>
                                    <div className="my-3 text-bold"> <RoleSelection {...props} value={role}
                                        onChange={handerPermissionChange("roles")}
                                    ></RoleSelection></div>
                                </div>

                            </div>
                        </div>

                        {/* Access conditions */}

                        <div className="position-relative px-6 border-right">
                            <div className="position-absolute left-0">2</div>
                            <div className="">
                                <div className="h3 text-light color-fg-muted my-2"> Access conditions</div>



                                <div className="py-2">
                                    <div className="text-light">指定此角色的访问条件(可选)</div>
                                    {renderConditions()}

                                    <div className="text-light">
                                        <div>
                                            <button className="btn btn-invisible "
                                                onClick={addAccessConditions}>
                                                <svg className="octicon color-fg-accent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"></path></svg>
                                                <span>添加授权条件</span>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="position-relative p-3" style={{ maxWidth: "300px" }}>
                        {$permission && $permission.conditions &&
                            _.map($permission.conditions, (condition) => {
                                if (!condition || !condition.expressions) {
                                    return undefined
                                }
                                return <ExpressionStr key={condition.id} {...props} expression={condition.expressions}></ExpressionStr>
                            })
                        }

                    </div>
                </div>
                <div className="border-top color-border-muted text-right pt-3">
                    <button onClick={close} className="btn color-bg-default">Cancel</button>
                    {isNew
                        ? <button onClick={addPermissionToRole} className="ml-3 btn color-bg-done-emphasis color-fg-on-emphasis">Add permission</button>
                        : <>
                            <button onClick={deletePermissionToRole} className="ml-3 btn color-bg-danger-emphasis color-fg-on-emphasis float-left">Delete</button>
                            <button onClick={updatePermissionToRole} className="ml-3 btn color-bg-done-emphasis color-fg-on-emphasis">update permission</button>
                        </>
                    }

                </div>
                {/* <Form key={generateId()}
                    onSubmit={handlerPolicySubmit}
                    onCancel={() => {
                        setOpenModal(false)
                    }}
                    schemas={schemas}
                ></Form> */}
            </div>
        }}
    </Details>
    }
    </>
}
const writeFile = (fileName: string, content: any) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
        type: "application/json;charset=utf-8;"
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
};


function RoleDetail({ ...props }: any) {
    const { match, appBasePath, permissionsStore } = props
    const { role_name } = match.params
    const store = props.rolesStore

    // const permissionsStore = PermissionsStore
    const [role, setRole] = useState<any>()
    const [permissions, setPermissions] = useState<any>()
    useEffect(() => {
        if (role_name) {
            store.get(role_name).then(setRole)
            permissionsStore.getAllByRole(role_name).then((res: any) => {
                if (res.error) {
                    setPermissions([])
                } else {
                    setPermissions(res.permissions)
                }
            })

            const id = permissionsStore.on("change", function () {
                permissionsStore.getAllByRole(role_name).then((res: any) => {
                    if (res.error) {
                        setPermissions([])
                    } else {
                        setPermissions(res.permissions)
                    }
                })
            })
            return function () {
                permissionsStore.kill("change", id)
            }
        }

    }, [role_name])

    function handleClonePermission(permission: any) {
        return async function () {
            await permissionsStore.create({
                ...permission,
                id: undefined,
                // conditions
            })
        }
    }
    function remove(path: string) {
        return async function () {
            store.remove(path)
        }
    }
    function handleExportPermission() {
        writeFile("permission.json", JSON.stringify({ permissions }))
    }

    if (!role) {
        return <></>
    }
    return <div className="f4 text-light color-fg-muted">
        <button onClick={remove(role.name)} className="float-right btn btn-invisible color-fg-danger">
            <svg className="octicon color-fg-danger" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M6.5 1.75a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V3h-3V1.75zm4.5 0V3h2.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H5V1.75C5 .784 5.784 0 6.75 0h2.5C10.216 0 11 .784 11 1.75zM4.496 6.675a.75.75 0 10-1.492.15l.66 6.6A1.75 1.75 0 005.405 15h5.19c.9 0 1.652-.681 1.741-1.576l.66-6.6a.75.75 0 00-1.492-.149l-.66 6.6a.25.25 0 01-.249.225h-5.19a.25.25 0 01-.249-.225l-.66-6.6z"></path></svg>

            <span>Delete</span>
        </button>
        <div>
            <div className="f1 text-light color-fg-muted">{role.name}</div>
        </div>
        {/* <div className="mt-3">
            <div className="f4 text-light color-fg-muted">Groups(1)</div>
            <div className="">abc</div>
        </div> */}
        <div className="mt-3">
            <div className="d-flex flex-items-end flex-justify-between">
                <span>Permissions({permissions && permissions.length || "-"})</span>
                <div>

                    <ImportPermissionsForm {...props} trigger={<button className="btn btm-small color-bg-done-emphasis color-fg-on-emphasis mr-1"
                    >
                        <svg className="octicon color-fg-on-emphasis" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M8.53 1.22a.75.75 0 00-1.06 0L3.72 4.97a.75.75 0 001.06 1.06l2.47-2.47v6.69a.75.75 0 001.5 0V3.56l2.47 2.47a.75.75 0 101.06-1.06L8.53 1.22zM3.75 13a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5z"></path></svg>
                        <span>导入</span>
                    </button>}></ImportPermissionsForm>
                    <button className="btn btm-small color-bg-done-emphasis color-fg-on-emphasis  mr-1"
                        onClick={handleExportPermission}>
                        <svg className="octicon color-fg-on-emphasis" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fillRule="evenodd" d="M7.47 10.78a.75.75 0 001.06 0l3.75-3.75a.75.75 0 00-1.06-1.06L8.75 8.44V1.75a.75.75 0 00-1.5 0v6.69L4.78 5.97a.75.75 0 00-1.06 1.06l3.75 3.75zM3.75 13a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5z"></path></svg>
                        <span>导出</span>
                    </button>
                    <RolePermissionForm {...props} role={role.name} trigger={(<button className="btn btm-small color-bg-done-emphasis color-fg-on-emphasis">
                        <svg className="octicon color-fg-on-emphasis" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"></path></svg>
                        <span>Add Permission</span>
                    </button>)}></RolePermissionForm>
                </div>

            </div>
            <DataTable className="position-relative py-2">
                <TableHeader>
                    <TableRow>
                        <TableHeaderColumn className={"dlabel text-left pl-3"} width="100px">Enabled</TableHeaderColumn>
                        <TableHeaderColumn className="text-left">Scope</TableHeaderColumn>
                        <TableHeaderColumn className="text-left" >Object</TableHeaderColumn>
                        <TableHeaderColumn className="text-left" >Conditions</TableHeaderColumn>
                        <TableHeaderColumn className="text-left" >上次修改时间</TableHeaderColumn>
                        <TableHeaderColumn className="text-right  pr-3" width="100px">状态</TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        _.map(permissions, (permission) => {
                            return <TableRow key={permission.id}>
                                <TableColumn>{permission.name}</TableColumn>
                                <TableColumn>{permission.scope}</TableColumn>
                                <TableColumn>{permission.database}</TableColumn>
                                <TableColumn>{permission.conditions && permission.conditions.length || "-"}</TableColumn>
                                <TableColumn>{moment(permission.modifyAt).format("lll")}</TableColumn>
                                <TableColumn className="text-right  pr-3">
                                    <RolePermissionForm  {...props} key={permission.id} permission={permission} role={role.name} trigger={(<a className="btn btn-link">edit</a>)}
                                    ></RolePermissionForm>
                                    <a className="ml-2 btn btn-link" onClick={handleClonePermission(permission)}>clone</a>
                                </TableColumn>

                            </TableRow>
                        })
                    }
                </TableBody>
            </DataTable>
        </div>
    </div>
}
function RoleDatabases({ role, ...props }: any) {
    const permissionsStore = props.permissionsStore
    const [permissions, setPermissions] = useState<any>()
    useEffect(() => {
        if (role.name) {
            permissionsStore.getAllByRole(role.name).then((res: any) => {
                if (res.error) {
                    setPermissions([])
                } else {
                    setPermissions(res.permissions)
                }
            })

            const id = permissionsStore.on("change", function () {
                permissionsStore.getAllByRole(role.name).then((res: any) => {
                    if (res.error) {
                        setPermissions([])
                    } else {
                        setPermissions(res.permissions)
                    }
                })
            })
            return function () {
                permissionsStore.kill("change", id)
            }
        }

    }, [role.name])
    const databases: any = {}
    _.each(permissions, ({ database }) => {
        if (databases[database]) {
            ++databases[database]
        } else {
            databases[database] = 1
        }
    })
    return <>{
        _.map(databases, (value, key) => {
            return <span>
                <svg className="octicon mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M2.5 3.5c0-.133.058-.318.282-.55.227-.237.592-.484 1.1-.708C4.899 1.795 6.354 1.5 8 1.5c1.647 0 3.102.295 4.117.742.51.224.874.47 1.101.707.224.233.282.418.282.551 0 .133-.058.318-.282.55-.227.237-.592.484-1.1.708C11.101 5.205 9.646 5.5 8 5.5c-1.647 0-3.102-.295-4.117-.742-.51-.224-.874-.47-1.101-.707-.224-.233-.282-.418-.282-.551zM1 3.5c0-.626.292-1.165.7-1.59.406-.422.956-.767 1.579-1.041C4.525.32 6.195 0 8 0c1.805 0 3.475.32 4.722.869.622.274 1.172.62 1.578 1.04.408.426.7.965.7 1.591v9c0 .626-.292 1.165-.7 1.59-.406.422-.956.767-1.579 1.041C11.476 15.68 9.806 16 8 16c-1.805 0-3.475-.32-4.721-.869-.623-.274-1.173-.62-1.579-1.04-.408-.426-.7-.965-.7-1.591v-9zM2.5 8V5.724c.241.15.503.286.779.407C4.525 6.68 6.195 7 8 7c1.805 0 3.475-.32 4.722-.869.275-.121.537-.257.778-.407V8c0 .133-.058.318-.282.55-.227.237-.592.484-1.1.708C11.101 9.705 9.646 10 8 10c-1.647 0-3.102-.295-4.117-.742-.51-.224-.874-.47-1.101-.707C2.558 8.318 2.5 8.133 2.5 8zm0 2.225V12.5c0 .133.058.318.282.55.227.237.592.484 1.1.708 1.016.447 2.471.742 4.118.742 1.647 0 3.102-.295 4.117-.742.51-.224.874-.47 1.101-.707.224-.233.282-.418.282-.551v-2.275c-.241.15-.503.285-.778.406-1.247.549-2.917.869-4.722.869-1.805 0-3.475-.32-4.721-.869a6.236 6.236 0 01-.779-.406z"></path></svg>
                <span className="m-1">{key}</span>
            </span>
        })
    }</>
}
function RoleSelection({ value, onChange, ...props }: any) {
    const store = props.rolesStore
    const [roles, setRoles] = useState([])
    useEffect(() => {
        store.getAll().then(({ roles }: any) => setRoles(roles))
    }, [])

    function handleOnChange(e: any) {
        if (onChange) {
            onChange(e)
        }
    }
    if (!roles) {
        return <></>
    }
    return <>
        <select className="form-select" aria-label="Preference" onChange={handleOnChange} >
            {!value &&
                <option>Choose an option</option>
            }
            {_.map(roles, ({ name, id }) => {
                return <option key={id} selected={value == name}>{name}</option>
            })}

        </select>
    </>
}
export default function ({ ...props }: any) {

    const { match, appBasePath } = props
    const { role: roleName } = match.params
    const store = props.rolesStore
    const [roles, setRoles] = useState<any>()
    const [expanded, setExpanded] = useState<any>({})

    useEffect(() => {
        store.getAll().then(({ roles }: any) => {
            setRoles(roles)
        })
        const id = store.on("change", function () {
            store.getAll().then(({ roles }: any) => setRoles(roles))
        })
        return function () {
            store.kill("change", id)
        }
    }, [])

    function detail(path: string) {
        return function () {
            props.history.push(`${appBasePath}/tags/${path}`)
        }
    }
    function handleRoleClick(key: string) {
        return function () {
            props.history.push(`${appBasePath}/roles/${key}`)
        }
    }
    function randerRolesTrees() {
        return <ul className="ActionList ActionList--divided" role="list" >
            {_.map(roles, (role, key) => {
                const { name } = role
                return <><li key={role.id}
                    aria-level={1}
                    aria-selected={roleName == name}
                    className="ActionList-item ActionList-content--sizeLarge">
                    <button

                        className="ActionList-content  
                    ActionList-content--visual16 ActionList-item-descriptionWrap "

                        onClick={handleRoleClick(name)}
                    >

                        <span className="ActionList-item-label h3 width-full">
                            {name}
                        </span>
                        <span className="ActionList-item-description">
                            1 groups: researcher</span>
                        <div className="ActionList-item-descriptionx">

                            <RoleDatabases key={`role_database_${role.id}`} {...props} role={role} />
                        </div>
                    </button>

                </li>
                </>
            })}

        </ul>
    }
    return <div className="flex-1">
        <div className="px-4 d-flex flex-items-center flex-justify-between">
            <div className="">
                <span className="h2">Roles</span>
                <div className="text-light">{roles && roles.length || "-"} <span className="text-small">roles</span></div>
            </div>
            <div>
                <ImportPermissionsForm {...props} trigger={<button className="btn btm-small color-bg-done-emphasis color-fg-on-emphasis mr-1"
                >
                    <svg className="octicon color-fg-on-emphasis" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M8.53 1.22a.75.75 0 00-1.06 0L3.72 4.97a.75.75 0 001.06 1.06l2.47-2.47v6.69a.75.75 0 001.5 0V3.56l2.47 2.47a.75.75 0 101.06-1.06L8.53 1.22zM3.75 13a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5z"></path></svg>
                    <span>导入</span>
                </button>}></ImportPermissionsForm>
                <CreateRoleForm  {...props} trigger={(<button className="btn color-bg-done-emphasis color-fg-on-emphasis">
                    <svg className="octicon color-fg-on-emphasis" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"></path></svg>
                    <span>Create New Role</span>
                </button>)} ></CreateRoleForm>
            </div>

        </div>
        <hr />
        <div className="d-grid grid-columns-3 grid-gap-3 px-4">
            <input type={"text"} className="form-control" placeholder="Search for a role"></input>
            <input type={"text"} className="form-control" placeholder="All groups"></input>
            <input type={"text"} className="form-control" placeholder="All users"></input>
        </div>
        <hr />

        <div className="m-4 d-flex Box color-border-muted">
            <div className="flex-1 border-right">


                <div className="">
                    {randerRolesTrees()}
                </div>
            </div>
            <Route path={`${appBasePath}/roles/:role_name`}
                component={(subProps: any) => {
                    return <div className="flex-auto p-2">
                        <RoleDetail  {...props} {...subProps}></RoleDetail>
                    </div>
                }}>

            </Route>
        </div>
    </div>
}

