/*
 * types/services/dr2am.tasks.preferences.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {RuntimeError} from '../model/RuntimeError';
import {ServiceCreatePreferenceRequest} from '../model/ServiceCreatePreferenceRequest';
import {ServiceCreatePreferenceResponse} from '../model/ServiceCreatePreferenceResponse';
import {ServiceDeletePreferenceResponse} from '../model/ServiceDeletePreferenceResponse';
import {ServiceGetPreferenceWithUserResponse} from '../model/ServiceGetPreferenceWithUserResponse';
import {ServiceListPreferencesResponse} from '../model/ServiceListPreferencesResponse';
import {ServiceUpdatePreferenceRequest} from '../model/ServiceUpdatePreferenceRequest';
import {ServiceUpdatePreferenceResponse} from '../model/ServiceUpdatePreferenceResponse';

/**
* PreferencesService service.
* @module api/PreferencesServiceApi
* @version version not set
*/
export class PreferencesServiceApi {

    /**
    * Constructs a new PreferencesServiceApi. 
    * @alias module:api/PreferencesServiceApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create  Preference
     * @param {module:model/ServiceCreatePreferenceRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceCreatePreferenceResponse} and HTTP response
     */
    preferencesServiceCreateWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling preferencesServiceCreate");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceCreatePreferenceResponse;

      return this.apiClient.callApi(
        '/v1/preferences:create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create  Preference
     * @param {module:model/ServiceCreatePreferenceRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceCreatePreferenceResponse}
     */
    preferencesServiceCreate(body) {
      return this.preferencesServiceCreateWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete  Preference
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceDeletePreferenceResponse} and HTTP response
     */
    preferencesServiceDeleteWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceDeletePreferenceResponse;

      return this.apiClient.callApi(
        '/v1/preferences:delete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete  Preference
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceDeletePreferenceResponse}
     */
    preferencesServiceDelete() {
      return this.preferencesServiceDeleteWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * GetWithUser Preference with UserId
     * @param {String} userId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceGetPreferenceWithUserResponse} and HTTP response
     */
    preferencesServiceGetWithUserWithHttpInfo(userId) {
      let postBody = null;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling preferencesServiceGetWithUser");
      }


      let pathParams = {
        'user_id': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceGetPreferenceWithUserResponse;

      return this.apiClient.callApi(
        '/v1/users/{user_id}/preferences', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * GetWithUser Preference with UserId
     * @param {String} userId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceGetPreferenceWithUserResponse}
     */
    preferencesServiceGetWithUser(userId) {
      return this.preferencesServiceGetWithUserWithHttpInfo(userId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Preferences
     * @param {Object} opts Optional parameters
     * @param {String} opts.action 
     * @param {Number} opts.pageSize 
     * @param {String} opts.pageToken 
     * @param {String} opts.query 
     * @param {String} opts.orderBy 
     * @param {Number} opts.paginateOffset 
     * @param {Number} opts.paginateLimit 
     * @param {String} opts.paginateNextPageToken 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceListPreferencesResponse} and HTTP response
     */
    preferencesServiceListWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'action': opts['action'],
        'page_size': opts['pageSize'],
        'page_token': opts['pageToken'],
        'query': opts['query'],
        'order_by': opts['orderBy'],
        'paginate.offset': opts['paginateOffset'],
        'paginate.limit': opts['paginateLimit'],
        'paginate.next_page_token': opts['paginateNextPageToken']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceListPreferencesResponse;

      return this.apiClient.callApi(
        '/v1/preferences:list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * List Preferences
     * @param {Object} opts Optional parameters
     * @param {String} opts.action 
     * @param {Number} opts.pageSize 
     * @param {String} opts.pageToken 
     * @param {String} opts.query 
     * @param {String} opts.orderBy 
     * @param {Number} opts.paginateOffset 
     * @param {Number} opts.paginateLimit 
     * @param {String} opts.paginateNextPageToken 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceListPreferencesResponse}
     */
    preferencesServiceList(opts) {
      return this.preferencesServiceListWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update  Preference
     * @param {module:model/ServiceUpdatePreferenceRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceUpdatePreferenceResponse} and HTTP response
     */
    preferencesServiceUpdateWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling preferencesServiceUpdate");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceUpdatePreferenceResponse;

      return this.apiClient.callApi(
        '/v1/preferences:update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update  Preference
     * @param {module:model/ServiceUpdatePreferenceRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceUpdatePreferenceResponse}
     */
    preferencesServiceUpdate(body) {
      return this.preferencesServiceUpdateWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
