/*
 * types/services/dr2am.tasks.orders.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbOrder} from './PbOrder';

/**
 * The ServiceCreateOrdersRequest model module.
 * @module model/ServiceCreateOrdersRequest
 * @version version not set
 */
export class ServiceCreateOrdersRequest {
  /**
   * Constructs a new <code>ServiceCreateOrdersRequest</code>.
   * @alias module:model/ServiceCreateOrdersRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceCreateOrdersRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceCreateOrdersRequest} obj Optional instance to populate.
   * @return {module:model/ServiceCreateOrdersRequest} The populated <code>ServiceCreateOrdersRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceCreateOrdersRequest();
      if (data.hasOwnProperty('order'))
        obj.order = PbOrder.constructFromObject(data['order']);
    }
    return obj;
  }
}

/**
 * @member {module:model/PbOrder} order
 */
ServiceCreateOrdersRequest.prototype.order = undefined;


