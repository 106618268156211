import _ from "lodash";
import { wfConfig } from "./api";
import { usePermissions } from "./api_permissions";
import { ApiClient } from "./client/ApiClient";
import { PermissionsServiceApi } from "./client/dr2am.tasks.permissions-js/src";
import { EventEmitter } from "./event_emitter";
import { generateId } from "./utils";






class permissionsStore extends EventEmitter {
    prex = "wfhk-permissions"
    storage = window.localStorage
    permissions: any = []

    constructor() {
        super();
        const permissionsStr: any = this.storage.getItem(this.prex)
        try {
            this.permissions = JSON.parse(permissionsStr) || []
        } catch (error) { }
    }
    async getAll() {
        return this.permissions
    }
    async getAllByRole(role: string) {
        const permissions = _.filter(this.permissions, (permission) => {
            return _.includes(permission.roles, role)
        })

        return permissions
    }

    async create({ scope, database, dataset, roles, conditions }: any) {
        const permission: any = {
            scope, database, dataset, roles, conditions
            // "path": `${namespace}.${name}`,
            // "name": `${name}`,
            // "namespace": namespace,
        }
        permission.id = generateId()
        permission.createAt = new Date()
        permission.modifyAt = permission.createAt
        this.permissions.push(permission)
        this.storage.setItem(this.prex, JSON.stringify(this.permissions))
        this.dispatch("change", permission);
        this.dispatch(permission.id, permission);
        return permission
    }
    async update(id: string, data: any, paths?: any) {
        const idx = _.findIndex(this.permissions, function (permission: any) {
            return permission.id == id
        })
        if (idx < 0) {
            return
        }
        for (let i = 0; i < paths.length; i++) {
            const path = paths[i];
            _.set(this.permissions[idx], path, _.get(data, path))
        }
        this.permissions[idx].modifyAt = new Date()
        if (!this.permissions[idx].createAt) {
            this.permissions[idx].createAt = this.permissions[idx].modifyAt
        }
        this.storage.setItem(this.prex, JSON.stringify(this.permissions))
        this.dispatch("change", this.permissions[idx]);
        this.dispatch(id, this.permissions[idx]);
        return this.permissions[idx]
    }
    async remove(path: string) {
        _.set(this.permissions, path, undefined)
        this.storage.setItem(this.prex, JSON.stringify(this.permissions))
        this.dispatch("change", null);
        this.dispatch(path, null);
        return false
    }

    async get(path: string) {
        return _.get(this.permissions, path)
    }

    async addPermissions(id: string, permissions: any[]) {
        const policy: any = await this.get(id)
        if (!policy) {
            return
        }
        const policyPermissions = policy.permissions || []
        for (let i = 0; i < permissions.length; i++) {
            const permission = permissions[i];
            if (_.includes(policyPermissions, permission)) {
                continue
            }
            policyPermissions.push(permission)
        }

        return this.update(id, { permissions: policyPermissions }, ["permissions"])
    }
    async removePermissions(id: string, permissions: any[]) {
        const policy: any = await this.get(id)
        if (!policy) {
            return
        }
        const policyPermissions = policy.permissions
        const nPermissions = []
        for (let i = 0; i < policyPermissions.length; i++) {
            const permission = policyPermissions[i];
            if (_.includes(permissions, permission)) {
                continue
            }
            nPermissions.push(permission)
        }

        return this.update(id, { permissions: nPermissions }, ["permissions"])
    }

}
function handlePbError(res: any) {
    if (!res) {
        return { error: { status: "error", msg: "unkonw error" } }
    }
    if (res && res.status && res.status.code != 200) {
        return { ...res, error: res.status }
    }
    return { ...res }
}
class permissionsStoreRemote extends EventEmitter {
    private api: PermissionsServiceApi
    permissions: any = []

    constructor(authc: any, apiUrl: any) {
        super();
        const client = new ApiClient();
        client.basePath = apiUrl || `${wfConfig.apiURL.protocol}//${wfConfig.apiURL.host}/api`;
        // client.basePath = "https://globe.wanfangdata.com.cn/api";
        client.authc = authc;
        this.api = new PermissionsServiceApi(client);
    }

    async create({ scope, database, accessType, dataset, roles, conditions }: any) {
        const permission: any = {
            scope, database, accessType, dataset, roles, conditions
            // "path": `${namespace}.${name}`,
            // "name": `${name}`,
            // "namespace": namespace,
        }
        try {
            // new LocalesApi(client);
            const res = await this.api.permissionsServiceCreate({ permission });
            this.dispatch("change", permission);
            this.dispatch(permission.id, permission);
            return handlePbError(res)
        } catch (error) {
            return { error }
        }



        return permission
    }

    async remove(id: string) {
        try {
            // new LocalesApi(client);
            const res = await this.api.permissionsServiceDelete(id);
            this.dispatch("change", null);
            this.dispatch(id, null);
            return handlePbError(res)
        } catch (error) {
            return { error }
        }

        this.storage.setItem(this.prex, JSON.stringify(this.permissions))

        return false
    }


    async update(id: string, data: any, paths?: any) {
        console.log(id, data, paths);
        try {
            // new LocalesApi(client);
            const res = await this.api.permissionsServiceUpdate(id, {
                permission: _.pick(data, paths),
                update_mask: { paths: paths }
            });
            this.dispatch("change", res.permission);
            this.dispatch(id, res.permission);
            return handlePbError(res)
        } catch (error) {
            return { error }
        }
        // this.storage.setItem(this.prex, JSON.stringify(this.permissions))
        // this.dispatch("change", this.permissions[idx]);
        // this.dispatch(id, this.permissions[idx]);
        // return this.permissions[idx]
    }
    async getAll() {
        return this.permissions
    }
    async getAllByRole(role: string) {

        try {
            // new LocalesApi(client);
            let query = `+Scope:北美地区数据集 +Database:Periodical +Roles:researcher +AccessType:SELECT`
            query = `Scope:北美地区数据集 Database:peridocal AccessType:SELECT (Roles:researcher or Roles:1pk)`
            query = `Roles:${role}`
            const res = await this.api.permissionsServiceList({ query: query, paginateLimit: 100 } as any);
            return handlePbError(res)

        } catch (error) {
            return { error }
        }
        // const permissions = _.filter(this.permissions, (permission) => {
        //     return _.includes(permission.roles, role)
        // })

        // return permissions
    }
    async importPermissions(permissions: any) {
        let pathParams = {};
        let queryParams = {};
        let headerParams = {};
        let formParams = {
            file: permissions
        };
        let postBody = {};
        let authNames: any = [];
        let contentTypes = ['multipart/form-data'];
        let accepts = ['application/json'];
        let returnType = {};

        return this.api.apiClient.callApi(
            '/v1/permissions/import', 'POST',
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType
        );
        // client.callApi("/v1/locales/import", "POST", {}, {}, {}, {
        //     "plugin": files[0]
        // }, {}, [], ['multipart/form-data'], [], null)
    }
}



export const PermissionsStore = new permissionsStore()
var localPermissionsStore: any
export const getPermissionStore = (authc: any, apiUrl: any) => {
    if (localPermissionsStore) {
        return localPermissionsStore
    }
    if (!authc) {
        return
    }
    localPermissionsStore = new permissionsStoreRemote(authc, apiUrl)
    return localPermissionsStore
}

