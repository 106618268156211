import { ReactElement } from "react";

export class EventEmitter {
    [x: string]: any;
    constructor() {
    }
    on(name: string | number, callback: any) {
        var callbacks = this[name];
        if (!callbacks) {
            this[name] = [callback];
        }
        else {
            callbacks.push(callback);
        }
    }

    dispatch(name: string | number, event: any) {
        var callbacks = this[name];
        if (callbacks) {
            callbacks.forEach((callback: any) => callback(event));
        }
    }
}
