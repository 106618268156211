// plugins records all active web app plugins by id.
window.plugins = window.plugins || {};

// registerPlugin, on the global window object, should be invoked by a plugin's web app bundle as
// it is loaded.
//
// During the beta, plugins manipulated the global window.plugins data structure directly. This
// remains possible, but is officially deprecated and may be removed in a future release.
function registerPlugin(id, plugin) {
    window.plugins[id] = plugin;
}

window.registerPlugin = window.registerPlugin || registerPlugin;