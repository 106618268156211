import _ from "lodash";
import { EventEmitter } from "./event_emitter";



/*\
|*|
|*|  :: cookies.js ::
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  https://developer.mozilla.org/en-US/docs/DOM/document.cookie
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * docCookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * docCookies.getItem(name)
|*|  * docCookies.removeItem(name[, path], domain)
|*|  * docCookies.hasItem(name)
|*|  * docCookies.keys()
|*|
\*/

export const docCookies = {
    getItem: function (sKey: string) {
        return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
    },
    setItem: function (sKey: string, sValue: any, option?: any) {
        // setItem: function (sKey: string, sValue: any, vEnd?: any, sPath?: any, sDomain?: any, bSecure?: any) {
        const { vEnd, sPath, sDomain, bSecure }: any = option
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
        var sExpires = "";
        if (vEnd) {
            switch (vEnd.constructor) {
                case Number:
                    sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                    break;
                case String:
                    sExpires = "; expires=" + vEnd;
                    break;
                case Date:
                    sExpires = "; expires=" + vEnd.toUTCString();
                    break;
            }
        }
        document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
        return true;
    },
    removeItem: function (sKey: string, option?: any) {
        const { sPath, sDomain } = option
        if (!sKey || !this.hasItem(sKey)) { return false; }
        document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
        return true;
    },
    hasItem: function (sKey: string) {
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    },
    keys: /* optional method: you can safely remove it! */ function () {
        var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
        for (var nIdx = 0; nIdx < aKeys.length; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
        return aKeys;
    }
};



class knowledgeStore extends EventEmitter {
    private store: any = {}
    private _i18n: any
    private storage = window.localStorage
    constructor() {
        super()

    }
    /**
     * update
    key:string,value:any     */
    public update(key: string, value: any) {
        if (key == "searchReq") {
            this.storage.setItem("last_search_req", JSON.stringify(value))
        }
        this.store[key] = value
        this.dispatch(key, value)
    }
    /**
     * get
     */
    public get(key: string) {
        try {
            let value = _.get(this.store, key)
            if (!value && key == "searchReq") {
                const str: any = this.storage.getItem("last_search_req")
                value = JSON.parse(str)
                this.store[key] = value
            }
            return value
        } catch (error) {
            console.warn(error);

        }


    }
    /**
     * currentUser
     */
    public currentUser() {
        return this.store["user"]
    }
    /**
     * currentCollections
     */
    public currentCollections() {
        return _.get(this.store, "searchReq.collections")
    }

    /**
     * documentSelections
     */
    public documentSelections() {
        let selections = this.get("documentSelections")
        if (!selections) {
            try {
                const str: any = this.storage.getItem("wfhk_document_selections")
                selections = JSON.parse(str) || {}
                // if (selections) {
                this.store["documentSelections"] = selections
                // }
            } catch (error) {
                selections = {}
            }
        }
        var self = this;
        return {
            has: function (id: string) {
                let selections = self.get("documentSelections")
                return !!selections[id]
            },
            remove: function (id: string) {
                let selections = self.get("documentSelections")
                selections[id] = undefined
                delete selections[id]
                self.storage.setItem("wfhk_document_selections", JSON.stringify(selections))
                self.dispatch("documentSelections_change", undefined)
                self.dispatch("documentSelections_" + id, undefined)
            },
            add: function (id: string, value: any) {
                let selections = self.get("documentSelections")
                selections[id] = value
                const newLocal = JSON.stringify(selections);
                self.storage.setItem("wfhk_document_selections", newLocal)
                self.dispatch("documentSelections_change", undefined)
            },
            addAll: function (...ids: any) {
                for (let i = 0; i < ids.length; i++) {
                    const id = ids[i];
                    selections[id] = true
                }
                const newLocal = JSON.stringify(selections);
                self.storage.setItem("wfhk_document_selections", newLocal)
                self.dispatch("documentSelections_change", undefined)
                self.dispatch("documentSelections_add", undefined)
                for (let i = 0; i < ids.length; i++) {
                    const id = ids[i];
                    self.dispatch("documentSelections_" + id, undefined)
                }
            },
            removeAll: function (...ids: any) {
                for (let i = 0; i < ids.length; i++) {
                    const id = ids[i];
                    selections[id] = undefined
                    delete selections[id]
                }
                const newLocal = JSON.stringify(selections);
                self.storage.setItem("wfhk_document_selections", newLocal)
                self.dispatch("documentSelections_change", undefined)
                self.dispatch("documentSelections_add", undefined)
                for (let i = 0; i < ids.length; i++) {
                    const id = ids[i];
                    self.dispatch("documentSelections_" + id, undefined)
                }
            },
            getAll: function () {
                let selections = self.get("documentSelections")
                return _.keys(selections)
            },
            clear: function () {
                const ids = this.getAll()
                let selections = self.get("documentSelections")
                selections = {}
                self.store["documentSelections"] = {}
                self.storage.setItem("wfhk_document_selections", JSON.stringify(selections))
                self.dispatch("documentSelections_change", undefined)
                self.dispatch("documentSelections_clear", undefined)
                for (let i = 0; i < ids.length; i++) {
                    const id = ids[i];
                    self.dispatch("documentSelections_" + id, undefined)
                }

            }

        }
    }
}

export const KnowledgeStore = new knowledgeStore()