/*
 * types/services/dr2am.tasks.orders.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbOrder} from './PbOrder';
import {PbResponseState} from './PbResponseState';

/**
 * The ServiceCreateOrdersResponse model module.
 * @module model/ServiceCreateOrdersResponse
 * @version version not set
 */
export class ServiceCreateOrdersResponse {
  /**
   * Constructs a new <code>ServiceCreateOrdersResponse</code>.
   * @alias module:model/ServiceCreateOrdersResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceCreateOrdersResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceCreateOrdersResponse} obj Optional instance to populate.
   * @return {module:model/ServiceCreateOrdersResponse} The populated <code>ServiceCreateOrdersResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceCreateOrdersResponse();
      if (data.hasOwnProperty('order'))
        obj.order = PbOrder.constructFromObject(data['order']);
      if (data.hasOwnProperty('state'))
        obj.state = PbResponseState.constructFromObject(data['state']);
    }
    return obj;
  }
}

/**
 * @member {module:model/PbOrder} order
 */
ServiceCreateOrdersResponse.prototype.order = undefined;

/**
 * @member {module:model/PbResponseState} state
 */
ServiceCreateOrdersResponse.prototype.state = undefined;


