/*
 * types/services/collection.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {CollectionCollectionBase} from './CollectionCollectionBase';

/**
 * The CollectionDeleteCollectionRequest model module.
 * @module model/CollectionDeleteCollectionRequest
 * @version version not set
 */
export class CollectionDeleteCollectionRequest {
  /**
   * Constructs a new <code>CollectionDeleteCollectionRequest</code>.
   * @alias module:model/CollectionDeleteCollectionRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CollectionDeleteCollectionRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CollectionDeleteCollectionRequest} obj Optional instance to populate.
   * @return {module:model/CollectionDeleteCollectionRequest} The populated <code>CollectionDeleteCollectionRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CollectionDeleteCollectionRequest();
      if (data.hasOwnProperty('collection'))
        obj.collection = CollectionCollectionBase.constructFromObject(data['collection']);
    }
    return obj;
  }
}

/**
 * @member {module:model/CollectionCollectionBase} collection
 */
CollectionDeleteCollectionRequest.prototype.collection = undefined;


