/*
 * types/services/dr2am.tasks.roles.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The PbPaginate model module.
 * @module model/PbPaginate
 * @version version not set
 */
export class PbPaginate {
  /**
   * Constructs a new <code>PbPaginate</code>.
   * @alias module:model/PbPaginate
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PbPaginate</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PbPaginate} obj Optional instance to populate.
   * @return {module:model/PbPaginate} The populated <code>PbPaginate</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PbPaginate();
      if (data.hasOwnProperty('offset'))
        obj.offset = ApiClient.convertToType(data['offset'], 'Number');
      if (data.hasOwnProperty('limit'))
        obj.limit = ApiClient.convertToType(data['limit'], 'Number');
      if (data.hasOwnProperty('next_page_token'))
        obj.nextPageToken = ApiClient.convertToType(data['next_page_token'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} offset
 */
PbPaginate.prototype.offset = undefined;

/**
 * @member {Number} limit
 */
PbPaginate.prototype.limit = undefined;

/**
 * @member {String} nextPageToken
 */
PbPaginate.prototype.nextPageToken = undefined;


