/*
 * types/services/dr2am.locales.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The ServiceLocaleLanguage model module.
 * @module model/ServiceLocaleLanguage
 * @version version not set
 */
export class ServiceLocaleLanguage {
  /**
   * Constructs a new <code>ServiceLocaleLanguage</code>.
   * @alias module:model/ServiceLocaleLanguage
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceLocaleLanguage</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceLocaleLanguage} obj Optional instance to populate.
   * @return {module:model/ServiceLocaleLanguage} The populated <code>ServiceLocaleLanguage</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceLocaleLanguage();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('language'))
        obj.language = ApiClient.convertToType(data['language'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ServiceLocaleLanguage.prototype.id = undefined;

/**
 * @member {String} language
 */
ServiceLocaleLanguage.prototype.language = undefined;


