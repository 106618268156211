/*
 * types/services/dr2am.tasks.permissions.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {RuntimeError} from '../model/RuntimeError';
import {ServiceCreatePermissionsRequest} from '../model/ServiceCreatePermissionsRequest';
import {ServiceCreatePermissionsResponse} from '../model/ServiceCreatePermissionsResponse';
import {ServiceDeletePermissionResponse} from '../model/ServiceDeletePermissionResponse';
import {ServiceListPermissionsResponse} from '../model/ServiceListPermissionsResponse';
import {ServiceUpdatePermissionRequest} from '../model/ServiceUpdatePermissionRequest';
import {ServiceUpdatePermissionResponse} from '../model/ServiceUpdatePermissionResponse';

/**
* PermissionsService service.
* @module api/PermissionsServiceApi
* @version version not set
*/
export class PermissionsServiceApi {

    /**
    * Constructs a new PermissionsServiceApi. 
    * @alias module:api/PermissionsServiceApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create Permissions
     * @param {module:model/ServiceCreatePermissionsRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceCreatePermissionsResponse} and HTTP response
     */
    permissionsServiceCreateWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling permissionsServiceCreate");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceCreatePermissionsResponse;

      return this.apiClient.callApi(
        '/v1/permissions:create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create Permissions
     * @param {module:model/ServiceCreatePermissionsRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceCreatePermissionsResponse}
     */
    permissionsServiceCreate(body) {
      return this.permissionsServiceCreateWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete permission
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceDeletePermissionResponse} and HTTP response
     */
    permissionsServiceDeleteWithHttpInfo(id) {
      let postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling permissionsServiceDelete");
      }


      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceDeletePermissionResponse;

      return this.apiClient.callApi(
        '/v1/permissions/{id}:delete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete permission
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceDeletePermissionResponse}
     */
    permissionsServiceDelete(id) {
      return this.permissionsServiceDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Permissions
     * @param {Object} opts Optional parameters
     * @param {String} opts.action 
     * @param {Number} opts.pageSize 
     * @param {String} opts.pageToken 
     * @param {String} opts.query 
     * @param {String} opts.orderBy 
     * @param {Number} opts.paginateOffset 
     * @param {Number} opts.paginateLimit 
     * @param {String} opts.paginateNextPageToken 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceListPermissionsResponse} and HTTP response
     */
    permissionsServiceListWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'action': opts['action'],
        'page_size': opts['pageSize'],
        'page_token': opts['pageToken'],
        'query': opts['query'],
        'order_by': opts['orderBy'],
        'paginate.offset': opts['paginateOffset'],
        'paginate.limit': opts['paginateLimit'],
        'paginate.next_page_token': opts['paginateNextPageToken']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceListPermissionsResponse;

      return this.apiClient.callApi(
        '/v1/permissions:list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * List Permissions
     * @param {Object} opts Optional parameters
     * @param {String} opts.action 
     * @param {Number} opts.pageSize 
     * @param {String} opts.pageToken 
     * @param {String} opts.query 
     * @param {String} opts.orderBy 
     * @param {Number} opts.paginateOffset 
     * @param {Number} opts.paginateLimit 
     * @param {String} opts.paginateNextPageToken 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceListPermissionsResponse}
     */
    permissionsServiceList(opts) {
      return this.permissionsServiceListWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update permission
     * @param {String} id 
     * @param {module:model/ServiceUpdatePermissionRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ServiceUpdatePermissionResponse} and HTTP response
     */
    permissionsServiceUpdateWithHttpInfo(id, body) {
      let postBody = body;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling permissionsServiceUpdate");
      }

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling permissionsServiceUpdate");
      }


      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ServiceUpdatePermissionResponse;

      return this.apiClient.callApi(
        '/v1/permissions/{id}:update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update permission
     * @param {String} id 
     * @param {module:model/ServiceUpdatePermissionRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ServiceUpdatePermissionResponse}
     */
    permissionsServiceUpdate(id, body) {
      return this.permissionsServiceUpdateWithHttpInfo(id, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
