/*
 * types/services/dr2am.tasks.permissions.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbPermissionNode} from './PbPermissionNode';

/**
 * The PbPermissionCondition model module.
 * @module model/PbPermissionCondition
 * @version version not set
 */
export class PbPermissionCondition {
  /**
   * Constructs a new <code>PbPermissionCondition</code>.
   * @alias module:model/PbPermissionCondition
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PbPermissionCondition</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PbPermissionCondition} obj Optional instance to populate.
   * @return {module:model/PbPermissionCondition} The populated <code>PbPermissionCondition</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PbPermissionCondition();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('gid'))
        obj.gid = ApiClient.convertToType(data['gid'], 'String');
      if (data.hasOwnProperty('access_type'))
        obj.accessType = ApiClient.convertToType(data['access_type'], 'String');
      if (data.hasOwnProperty('expressions'))
        obj.expressions = PbPermissionNode.constructFromObject(data['expressions']);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
PbPermissionCondition.prototype.id = undefined;

/**
 * @member {String} gid
 */
PbPermissionCondition.prototype.gid = undefined;

/**
 * @member {String} accessType
 */
PbPermissionCondition.prototype.accessType = undefined;

/**
 * @member {module:model/PbPermissionNode} expressions
 */
PbPermissionCondition.prototype.expressions = undefined;


