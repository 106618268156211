import _ from "lodash";
import { any, string } from "prop-types";
import { useRef } from "react";
import { Input } from "./input";

export const Form = ({ schemas, onSubmit, onCancel, onRemove, fn, ...props }: any) => {
    let refs = useRef<any>({});
    fn = fn || function (value: any, key: string, schema?: any) {
        switch (schema.type) {
            case "select":
                if (value == "未指定")
                    return ""
                break;
            default:
                break;
        }
        return value
    }
    function handleSubmit(e: React.MouseEvent) {
        e.preventDefault()
        const paths: any = []
        const data = _.reduce(schemas, (prev, curr) => {
            const { key } = curr
            let value = refs.current[key].value
            if (fn) {
                value = fn(value, key, curr)
            }
            if (curr.value != value) {
                paths.push(key)
            }
            return { ...prev, [key]: value }
        }, {})
        onSubmit && onSubmit(data, paths)
    }
    function handleCancel(e: React.MouseEvent) {
        onCancel && onCancel(e)
    }
    function handleRemove(e: React.MouseEvent) {
        e.preventDefault()
        const data = _.reduce(schemas, (prev, curr) => {
            const { key } = curr
            let value = refs.current[key].value
            if (!value) {
                return prev
            }
            if (fn) {
                value = fn(value, key, curr)
            }

            return { ...prev, [key]: value }
        }, {})
        onRemove && onRemove(data)
    }
    return <>
        <form >
            {_.map(schemas, schema => {
                const { key, label, value, type, options, maxLength, disabled } = schema
                return <div className="mt-5">
                    <Input
                        key={key}
                        ref={(ref) => { return refs.current[key] = ref }}
                        id={key}
                        type={type || "text"}
                        options={options}
                        maxLength={maxLength || 128}
                        displayText={label}
                        value={value}
                        disabled={disabled}
                    ></Input>
                </div>
            })}
            <p className="mt-5 d-flex flex-justify-between ">

                <button id="removeAccountButton" type="submit" className=" btn btn-danger"
                    onClick={handleRemove} ><span> 删除</span>
                </button>
                <div>
                    <button id="createAccountButton" type="submit" className=" btn btn-primary"
                        onClick={handleSubmit} ><span> 保存</span>
                    </button>
                    <button id="cancel" type="submit" className="ml-2 btn"
                        onClick={handleCancel} ><span> 取消</span>
                    </button>
                </div>


            </p>
        </form>
    </>
}