import React, { useState, useEffect } from 'react'
import { withTranslation } from "react-i18next";
import Sidebar from './sidebar';
// import '@elastic/eui/dist/eui_theme_light.css';
// import "./style/actionlist/index.scss";

// import "@primer/css/actionlist/index.scss";
// import "@primer/css/layout/index.scss";
// import "@primer/css/base/index.scss";
// import "@primer/css/utilities/index.scss";
// import "@primer/css/navigation/index.scss";
// import { EuiOverlayMask } from '@elastic/eui';
import { EventEmitter } from './EventEmitter';



export class GlobalModal extends EventEmitter {

}
export default ({ children, ...props }: any) => {
    const [openModal, setModelOpen] = useState(false)
    const [modalBody, setModalBody] = useState()
    const { chrome } = props;
    chrome.on("global.modal.open", (body: any) => {
        setModalBody(body)
        setModelOpen(true)
    })
    chrome.on("global.modal.close", () => {
        setModalBody(undefined)
        setModelOpen(false)
    })
    const maskWithClick = (
        <>
            {/*         
        <EuiOverlayMask className="bg-white">
            <div style={{ margin: "2rem" }} className="position-fixed left-0 top-0 color-bg-subtle border p-2">
                <div onClick={() => chrome.dispatch("global.modal.close")}>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fillRule="evenodd" d="M15.28 5.22a.75.75 0 00-1.06 0l-6.25 6.25a.75.75 0 000 1.06l6.25 6.25a.75.75 0 101.06-1.06L9.56 12l5.72-5.72a.75.75 0 000-1.06z"></path></svg>
                    </span>

                    <span>
                        返回
                    </span>
                </div>
            </div>
            {modalBody}
        </EuiOverlayMask> */}
        </>
    );


    return <div className="d-flex height-full">
        {/* <Sidebar {...props}></Sidebar> */}
        <div className="flex-1">
            {children}
        </div>
        {openModal ? maskWithClick : undefined}
    </div>
}
