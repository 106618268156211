import {
    InternalApplicationSetup,
    InternalApplicationStart,
    AppMounter,
    AppMountParameters,
    AppUnmount,
    App,
} from "./types";
import React from "react";
import { AppRouter } from "./public";
import { createBrowserHistory } from 'history';
import { IContextContainer } from "../../utils/context";
import { Store, AnyAction, createStore } from "redux";
// import configureStore from "../../../store/configure_store";
import { ContextSetup } from "../context/context";
import { fromPairs } from "lodash";
// import Authentication from "../../../services/auth/configuration";
// import { setAuthUser } from "../../../actions/auth";



interface SetupDeps {
    context: ContextSetup;
}

interface AppBox {
    app: App;
    mount: AppMounter;
}


/**
 * Service that is responsible for registering new applications.
 * @internal
 */
export class ApplicationService {
    private readonly apps$ = new Map<string, AppBox>(new Map());
    private mountContext?: IContextContainer<App['mount']>;
    private store: Store<any, AnyAction>;
    public history!: any;
    constructor(store?: Store<any, AnyAction>) {

        if (!store) {
            this.store = createStore(() => { })
        } else {
            this.store = store
        }
        this.history = createBrowserHistory({ basename: "/" });
    }
    public setup({ context }: SetupDeps): InternalApplicationSetup {
        this.mountContext = context.createContextContainer();
        return {
            register: (plugin: symbol, app: App) => {

                const appBox: AppBox = {
                    app,
                    mount: this.mountContext!.createHandler(plugin, app.mount),
                };
                this.apps$.set(app.id, appBox)
            },
            registerRoute: (id: string, app: App) => {

                const ids = id.split(":")
                if (ids.length > 1) {
                    id = ids[0]
                    app.id = ids[1]
                }
                const parentApp = this.apps$.get(id)
                if (!parentApp) {
                    return
                }
                if (!parentApp.app.subApps) {
                    parentApp.app.subApps = new Map<string, AppBox>(new Map())
                }
                const appBox: AppBox = {
                    app,
                    mount: this.mountContext!.createHandler(Symbol(app.id), app.mount),
                };
                parentApp.app.subApps.set(app.id, appBox)


            },
            registerMountContext: this.mountContext!.registerContext,
        }
    }

    // public async start():Promise<InternalApplicationStart> {
    public async start(): Promise<InternalApplicationStart> {
        const history = this.history;
        const appUnmountFn: AppUnmount = () => {
        }
        const appMounter = (
            params: AppMountParameters
        ): Promise<AppUnmount> => { return Promise.resolve(appUnmountFn) }
        return {
            capabilities: "",
            registerMountContext: "context",
            getComponent: () => {
                const appMounters = new Map<string, AppMounter>();
                this.apps$.forEach((value, key) => {
                    appMounters.set(value.app.id, value.mount)
                })
                return (
                    <AppRouter
                        apps={appMounters}
                        history={history}
                        store={this.store}
                    ></AppRouter>
                )
            },
            getStore: () => {
                return this.store;
            },
            getHistory: () => {
                return this.history;
            },
            getRoute: (id: string) => {
                debugger
                const parent = this.apps$.get(id)
                if (!parent) {
                    return
                }

                const appMounters = new Map<string, AppMounter>();
                if (!parent.app.subApps) {
                    return
                }
                parent.app.subApps.forEach((value, key) => {
                    if (value.app.appPath && value.app.appPath !== "") {
                        appMounters.set(value.app.appPath, value.mount)

                    }
                    appMounters.set(value.app.id, value.mount)
                })

                return (
                    <AppRouter
                        path={`/app/:appId/:subAppId`}
                        matchId="subAppId"
                        apps={appMounters}
                        history={history}
                        store={this.store}
                    ></AppRouter>
                )
                // return app.app.router
            },
            // getAuth: async () => {
            //     // Configuration
            //     return this.currentUser
            // }
        }
    }

    public stop() { }
}