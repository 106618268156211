/*
 * types/services/dr2am.tasks.permissions.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbPermissionCondition} from './PbPermissionCondition';

/**
 * The PbPermissionRole model module.
 * @module model/PbPermissionRole
 * @version version not set
 */
export class PbPermissionRole {
  /**
   * Constructs a new <code>PbPermissionRole</code>.
   * @alias module:model/PbPermissionRole
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PbPermissionRole</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PbPermissionRole} obj Optional instance to populate.
   * @return {module:model/PbPermissionRole} The populated <code>PbPermissionRole</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PbPermissionRole();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('gid'))
        obj.gid = ApiClient.convertToType(data['gid'], 'String');
      if (data.hasOwnProperty('access_type'))
        obj.accessType = ApiClient.convertToType(data['access_type'], 'String');
      if (data.hasOwnProperty('scope'))
        obj.scope = ApiClient.convertToType(data['scope'], 'String');
      if (data.hasOwnProperty('database'))
        obj.database = ApiClient.convertToType(data['database'], 'String');
      if (data.hasOwnProperty('roles'))
        obj.roles = ApiClient.convertToType(data['roles'], ['String']);
      if (data.hasOwnProperty('conditions'))
        obj.conditions = ApiClient.convertToType(data['conditions'], [PbPermissionCondition]);
      if (data.hasOwnProperty('create_at'))
        obj.createAt = ApiClient.convertToType(data['create_at'], 'Date');
      if (data.hasOwnProperty('modify_at'))
        obj.modifyAt = ApiClient.convertToType(data['modify_at'], 'Date');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
PbPermissionRole.prototype.id = undefined;

/**
 * @member {String} gid
 */
PbPermissionRole.prototype.gid = undefined;

/**
 * @member {String} accessType
 */
PbPermissionRole.prototype.accessType = undefined;

/**
 * @member {String} scope
 */
PbPermissionRole.prototype.scope = undefined;

/**
 * @member {String} database
 */
PbPermissionRole.prototype.database = undefined;

/**
 * @member {Array.<String>} roles
 */
PbPermissionRole.prototype.roles = undefined;

/**
 * @member {Array.<module:model/PbPermissionCondition>} conditions
 */
PbPermissionRole.prototype.conditions = undefined;

/**
 * @member {Date} createAt
 */
PbPermissionRole.prototype.createAt = undefined;

/**
 * @member {Date} modifyAt
 */
PbPermissionRole.prototype.modifyAt = undefined;

/**
 * @member {String} name
 */
PbPermissionRole.prototype.name = undefined;

/**
 * @member {String} description
 */
PbPermissionRole.prototype.description = undefined;


