import React from "react";
import { useState } from "react";
import { Details } from "../components/details";

export default function ImportPermissionsForm({ open, trigger, onChange, permissionsStore, ...props }: any) {
    const [openModal, setOpenModal] = useState(open)

    const nTrigger = React.cloneElement(trigger, {
        onClick: function (e) {
            setOpenModal(true)
            if (trigger.onClick) {
                trigger.onClick(e)
            }
        }
    })

    function handleDragOver(evt: any) {
        evt.preventDefault()

    }
    function handleDropFile(evt: any) {
        console.log(evt.dataTransfer.files);
        evt.preventDefault()
        if (!evt.dataTransfer.files) {
            return
        }
        const file = evt.dataTransfer.files
        if (file.length) {
            onChange && onChange(file[0])
        }
    }

    const upload = async (evt: any) => {

        if (!evt.target.files) {
            return
        }
        const file = evt.target.files
        if (file.length) {
            const { error } = await permissionsStore.importPermissions(file[0])
            if (error) {
                alert(JSON.stringify(error))
            }
            onChange && onChange(file[0])
            window.location.reload()
        }
    }
    return <>{nTrigger}
        {openModal && <Details fullscreen  >
            {() => {
                return <div className="Box p-3 mx-auto border-dashed" style={{ width: "400px" }}> <div
                    onDragOver={handleDragOver}
                    onDrop={handleDropFile}
                >
                    拖动文件到这里或者
                </div>
                    <input type="file" onChange={upload}></input>
                </div>
            }}

        </Details>
        }
    </>
}