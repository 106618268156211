import {
    init,
    captureMessage,
} from '@sentry/browser';

function HappyTransport() {
    async function sendEvent(event) {
        const eventType = event.level
        const url = `https://oversea.wanfangdata.com.cn/v1/analysis:push_event`
        var req = {
            method: 'POST',
            body: JSON.stringify(event),
            // type: eventType,
        };
        console.log("sendEvent", req);

        const res = await fetch(url, req)
        console.log(res);
        return res
    }
    return {
        async send(request) {
            // console.log("request");
            // console.log(request[1]);
            // console.log(request[1][0][1]);
            return sendEvent(request[1][0][1])
        },
        async flush(timeout) {}
    }


}

init({
    dsn: 'https://363a337c11a64611be4845ad6e24f3ac@oversea.wanfangdata.com.cn/297378',
    // dsn: 'https://a@localhost:3000/297378',
    // ...
    enabled: true,
    debug: true,
    transport: HappyTransport,
    beforeSend: function (event, hit) {
        // console.log("event, hit");
        // console.log(event);
        // console.log(hit);
        return event
    }
});