import { History } from 'history';
import { Router, Route, matchPath } from "react-router-dom";
import React from "react";
import { AppContainer } from './app_container';
import { AppMounter } from '../types';


interface Props {
    apps: ReadonlyMap<string, AppMounter>;
    // basePath: HttpStart['basePath'];
    // currentAppId$: Subject<string | undefined>;
    history: History;
    store: any;
    /**
     * Only necessary for redirecting to legacy apps
     * @deprecated
     */
    redirectTo?: (path: string) => void;

    path?: string;
    matchId?: string;
    auth?: any;
}

export const AppRouter: React.FunctionComponent<Props> = ({
    history,
    path,
    ...otherProps
}) => {
    if (path && path != "") {
        // path = `/app/${path}/:appId`
        path = path
    } else {
        path = `/:app?/:appId?`
    }
    return (
        <>

            <Router history={history}>
                <Route
                    path={path}
                    render={props => {
                        if (!props.location.pathname.startsWith("/app")) {
                            props.location.pathname = "/app" + props.location.pathname
                            props.match = matchPath(props.location.pathname, { path }) || props.match
                        }
                        return <AppContainer {...otherProps} {...props} />
                    }}
                />
            </Router>
        </>
    )
};