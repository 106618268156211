/*
 * types/services/dr2am.tasks.roles.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbRole} from './PbRole';

/**
 * The ServiceCreateRoleRequest model module.
 * @module model/ServiceCreateRoleRequest
 * @version version not set
 */
export class ServiceCreateRoleRequest {
  /**
   * Constructs a new <code>ServiceCreateRoleRequest</code>.
   * @alias module:model/ServiceCreateRoleRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceCreateRoleRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceCreateRoleRequest} obj Optional instance to populate.
   * @return {module:model/ServiceCreateRoleRequest} The populated <code>ServiceCreateRoleRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceCreateRoleRequest();
      if (data.hasOwnProperty('role'))
        obj.role = PbRole.constructFromObject(data['role']);
    }
    return obj;
  }
}

/**
 * @member {module:model/PbRole} role
 */
ServiceCreateRoleRequest.prototype.role = undefined;


