/*
 * types/services/dr2am.tasks.roles.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The PbRole model module.
 * @module model/PbRole
 * @version version not set
 */
export class PbRole {
  /**
   * Constructs a new <code>PbRole</code>.
   * @alias module:model/PbRole
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PbRole</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PbRole} obj Optional instance to populate.
   * @return {module:model/PbRole} The populated <code>PbRole</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PbRole();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('gid'))
        obj.gid = ApiClient.convertToType(data['gid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('display_name'))
        obj.displayName = ApiClient.convertToType(data['display_name'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('permissions'))
        obj.permissions = ApiClient.convertToType(data['permissions'], ['String']);
      if (data.hasOwnProperty('scheme_managed'))
        obj.schemeManaged = ApiClient.convertToType(data['scheme_managed'], 'Boolean');
      if (data.hasOwnProperty('built_in'))
        obj.builtIn = ApiClient.convertToType(data['built_in'], 'Boolean');
      if (data.hasOwnProperty('create_at'))
        obj.createAt = ApiClient.convertToType(data['create_at'], 'Date');
      if (data.hasOwnProperty('update_at'))
        obj.updateAt = ApiClient.convertToType(data['update_at'], 'Date');
      if (data.hasOwnProperty('delete_at'))
        obj.deleteAt = ApiClient.convertToType(data['delete_at'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
PbRole.prototype.id = undefined;

/**
 * @member {String} gid
 */
PbRole.prototype.gid = undefined;

/**
 * @member {String} name
 */
PbRole.prototype.name = undefined;

/**
 * @member {String} displayName
 */
PbRole.prototype.displayName = undefined;

/**
 * @member {String} description
 */
PbRole.prototype.description = undefined;

/**
 * @member {Array.<String>} permissions
 */
PbRole.prototype.permissions = undefined;

/**
 * @member {Boolean} schemeManaged
 */
PbRole.prototype.schemeManaged = undefined;

/**
 * @member {Boolean} builtIn
 */
PbRole.prototype.builtIn = undefined;

/**
 * @member {Date} createAt
 */
PbRole.prototype.createAt = undefined;

/**
 * @member {Date} updateAt
 */
PbRole.prototype.updateAt = undefined;

/**
 * @member {Date} deleteAt
 */
PbRole.prototype.deleteAt = undefined;


