/*
 * types/services/dr2am.locales.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {PbPaginate} from './PbPaginate';

/**
 * The ServiceListLocalesRequest model module.
 * @module model/ServiceListLocalesRequest
 * @version version not set
 */
export class ServiceListLocalesRequest {
  /**
   * Constructs a new <code>ServiceListLocalesRequest</code>.
   * @alias module:model/ServiceListLocalesRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ServiceListLocalesRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceListLocalesRequest} obj Optional instance to populate.
   * @return {module:model/ServiceListLocalesRequest} The populated <code>ServiceListLocalesRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServiceListLocalesRequest();
      if (data.hasOwnProperty('query'))
        obj.query = ApiClient.convertToType(data['query'], 'String');
      if (data.hasOwnProperty('paginate'))
        obj.paginate = PbPaginate.constructFromObject(data['paginate']);
    }
    return obj;
  }
}

/**
 * @member {String} query
 */
ServiceListLocalesRequest.prototype.query = undefined;

/**
 * @member {module:model/PbPaginate} paginate
 */
ServiceListLocalesRequest.prototype.paginate = undefined;


