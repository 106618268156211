/*
 * types/services/collection.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.21
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {CollectionCreateCollectionRequest} from '../model/CollectionCreateCollectionRequest';
import {CollectionCreateCollectionResponse} from '../model/CollectionCreateCollectionResponse';
import {CollectionDeleteCollectionRequest} from '../model/CollectionDeleteCollectionRequest';
import {CollectionDeleteCollectionResponse} from '../model/CollectionDeleteCollectionResponse';
import {CollectionListCollectionResponse} from '../model/CollectionListCollectionResponse';
import {RuntimeError} from '../model/RuntimeError';

/**
* Collection service.
* @module api/CollectionApi
* @version version not set
*/
export class CollectionApi {

    /**
    * Constructs a new CollectionApi. 
    * @alias module:api/CollectionApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {module:model/CollectionCreateCollectionRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollectionCreateCollectionResponse} and HTTP response
     */
    collectionCreateWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling collectionCreate");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CollectionCreateCollectionResponse;

      return this.apiClient.callApi(
        '/v1/collection.create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * @param {module:model/CollectionCreateCollectionRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollectionCreateCollectionResponse}
     */
    collectionCreate(body) {
      return this.collectionCreateWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/CollectionDeleteCollectionRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollectionDeleteCollectionResponse} and HTTP response
     */
    collectionDeleteWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling collectionDelete");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CollectionDeleteCollectionResponse;

      return this.apiClient.callApi(
        '/v1/collection.delete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * @param {module:model/CollectionDeleteCollectionRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollectionDeleteCollectionResponse}
     */
    collectionDelete(body) {
      return this.collectionDeleteWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollectionListCollectionResponse} and HTTP response
     */
    collectionListWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CollectionListCollectionResponse;

      return this.apiClient.callApi(
        '/v1/collection.list', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollectionListCollectionResponse}
     */
    collectionList() {
      return this.collectionListWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
